import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { User, UserState } from "./interfaces";

/**
 * useUserStore - Zustand store for managing user state.
 *
 * This store provides the following state:
 * - `user`: The current user object (initially null).
 *
 * And the following actions:
 * - `clearUserData`: Clears the user, resettng him to null.
 */
export const useUserStore = create<UserState>()(
  persist(
    (set) => ({
      user: null,
      setUser: (user) =>
        set((state) => ({ user: { ...state.user, ...user } as User })),
      clearUserData: () => set({ user: null }),
    }),
    {
      name: "user-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
