import { useState } from "react";
import { JwtPayload } from "jsonwebtoken";
import { useAuthStore } from "../../../stores/auth/useAuthStore";
import { useUserStore } from "../../../stores/user/useUserDataStore";
import { axiosCall } from "../../config/axiosCall";
import { endpoints } from "../../config/api-endpoints";
import { decodeAccessToken } from "../../config/utils";

interface CustomJwtPayload extends JwtPayload {
  user_id: string;
  first_name: string;
  last_name: string;
  email: string;
}

/**
 * useLogin - Custom hook for handling user login logic.
 *
 * This hook provides the `login` function which is used to handle the login process,
 * including setting the authentication token and user data.
 *
 * @returns {Object} - An object containing the `login` function.
 * @returns {Function} login - A function that takes user login data and
 * updates the authentication token and user store.
 */
export const useLogin = () => {
  const { setToken } = useAuthStore();
  const { setUser } = useUserStore();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  /**
   * login - Handles the login process by setting the authentication token and user data.
   *
   * @param {Object} data - The login data, typically including credentials like email and password.
   * @param {string} data.token - The authentication token to be set in the auth store.
   * @param {Object} data.user - The user data to be set in the user store.
   * @returns {Promise<void>} - A promise that resolves when the login process is complete.
   */
  const login = async (data: { email: string; password: string }) => {
    try {
      setError(null);
      setLoading(true);

      const response = await axiosCall({
        endpoint: endpoints.login,
        data,
      });

      if (response?.status === 200) {
        const decoded = decodeAccessToken(
          response?.data?.access
        ) as CustomJwtPayload;

        const tokenObj = {
          accessToken: response?.data?.access,
          refreshToken: response?.data?.refresh,
          exp: decoded?.exp,
        };

        const userObj = {
          id: decoded?.user_id,
          first_name: decoded?.first_name,
          last_name: decoded?.last_name,
          email: decoded?.email,
        };

        setToken(tokenObj);
        setUser(userObj);
      }
    } catch (error) {
      setError("Login fehlgeschlagen. Bitte erneut versuchen.");
    } finally {
      setLoading(false);
    }
  };

  return { login, error, loading };
};
