import { useEffect, useState } from "react";
import { AssignmentType } from "../../components/assignment-modal/AssignmentModal.interface";
import OverviewHeader from "../../components/overview-header/OverviewHeader";
import FlexTable from "../../components/table/FlexTable";
import { EMPLOYEE_TABLE_COLUMNS } from "../../constants/tableColumns";
import { Employee } from "../../models/Employee";
import { READ_EMPLOYEES_URL } from "../../services/apiRoutes";
import getItems from "../../services/getItems";

/**
 * EmployeeOverview component displays a list of employees in a table format.
 *
 * This component fetches employee data from a backend API and displays it in a table.
 * It uses the `FlexTable` component to render the data and includes action buttons for
 * adding, deleting, and viewing employee details.
 *
 * @returns {JSX.Element} The rendered EmployeeOverview component.
 */
const EmployeeOverview = () => {
  const assetType = "employee";
  const [items, setItems] = useState<Employee[]>([]);

  useEffect(() => {
    /**
     * Fetches employee data from the backend API and updates the state.
     * Logs the fetched data to the console for debugging purposes.
     */
    (async () => {
      const data = await getItems<Employee[]>(READ_EMPLOYEES_URL);
      if (data) {
        setItems(data);
      }
    })();
  }, []);

  return (
    <>
      <OverviewHeader title="Mitarbeitende" assetType={assetType} />

      {items ? (
        <FlexTable
          columns={EMPLOYEE_TABLE_COLUMNS}
          rows={items}
          assetType={assetType}
          showAssignments
          hasActionButtons
          actionButtonRowProps={{
            assetType: assetType,
            hasManageAssignmentsAction: true,
            assignmentOptions: [
              { type: AssignmentType.ACCESSORY, multiple: false },
              { type: AssignmentType.BUILDING, multiple: false },
              { type: AssignmentType.COMPUTER, multiple: true },
              { type: AssignmentType.DOORACCESS, multiple: true },
              { type: AssignmentType.LANDLINE, multiple: true },
              { type: AssignmentType.LANDLINE_CONTRACT, multiple: true },
              { type: AssignmentType.LICENSE, multiple: true },
              { type: AssignmentType.LITERATURE, multiple: true },
              { type: AssignmentType.LOCATION, multiple: false },
              { type: AssignmentType.MOBILEPHONE, multiple: true },
              { type: AssignmentType.CONTRACT, multiple: true },
              { type: AssignmentType.MONITOR, multiple: true },
              { type: AssignmentType.NETWORK_DEVICE, multiple: true },
              { type: AssignmentType.ROOM, multiple: true },
              { type: AssignmentType.TABLE, multiple: true },
              { type: AssignmentType.VIRTUAL_MACHINE, multiple: true },
            ],
            hasQrCodeAction: false,
            hasDeleteAction: true,
          }}
        />
      ) : null}
    </>
  );
};

export default EmployeeOverview;
