import { Box, TableCell } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../../services/apiRoutes";
import getItems from "../../../../services/getItems";
import FlexButton from "../../../button/FlexButton";
import { ButtonColor } from "../../../button/FlexButton.interface";
import {
  AssignedItem,
  AssignmentsCellProps,
} from "./AssignmentsCell.interface";
import { useStyle } from "./AssignmentsCell.styles";

const AssignmentsCell = (props: AssignmentsCellProps) => {
  const styles = useStyle();
  const navigate = useNavigate();
  const [amountOfAssignedAsset, setAmountOfAssignedAssets] = useState(0);
  const [assignedEmployees, setAssignedEmployees] = useState<AssignedItem[]>(
    []
  );

  useEffect(() => {
    (async () => {
      const retrieveAssignmentsUrl = `${BASE_URL}/retrieve/${props.assetType}/${props.assetId}/`;
      const retrievedAssignments = await getItems<any>(retrieveAssignmentsUrl);

      if (retrievedAssignments) {
        // Set the amount of assigned assets.
        const assetAmount = retrievedAssignments.assignments.filter(
          (assignedItem: AssignedItem) =>
            assignedItem.assetType !== "employee" &&
            assignedItem.assetType !== props.assetType
        ).length;
        setAmountOfAssignedAssets(assetAmount);

        // Only relevant for assets (not for employees):
        if (props.assetType !== "employee") {
          // Set the assigned employees
          const employees = retrievedAssignments.assignments.filter(
            (assignedItem: AssignedItem) =>
              assignedItem.assetType === "employee"
          );
          setAssignedEmployees(employees);
        }
      }
    })();
  }, [props.refreshCounter]);

  return (
    <>
      {props.assetType !== "employee" && (
        <TableCell>
          <Box sx={styles.cellBox}>
            {assignedEmployees.map((employee) => (
              <FlexButton
                id="assignment-cell-view-asset-button"
                color={ButtonColor.GRAY}
                title={employee.label}
                onClick={() =>
                  navigate(`/${employee.assetType}/${employee.id}`)
                }
              />
            ))}
          </Box>
        </TableCell>
      )}
      <TableCell>
        <Box>{amountOfAssignedAsset}</Box>
      </TableCell>
    </>
  );
};

export default AssignmentsCell;
