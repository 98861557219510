import { useEffect, useState } from "react";
import { AssignmentType } from "../../../components/assignment-modal/AssignmentModal.interface";
import OverviewHeader from "../../../components/overview-header/OverviewHeader";
import FlexTable from "../../../components/table/FlexTable";
import { TABLE_TABLE_COLUMNS } from "../../../constants/tableColumns";
import { Table } from "../../../models/Table";
import { READ_TABLES_URL } from "../../../services/apiRoutes";
import getItems from "../../../services/getItems";

/**
 * TableOverview component displays a list of tables in a tabular format.
 * It fetches table data from an API endpoint and renders it using the FlexTable component.
 * Users can perform actions such as adding QR codes, deleting, or viewing details of tables.
 *
 * @component
 * @returns {JSX.Element} A layout containing an overview header and a table of table data.
 */
const TableOverview = () => {
  const assetType = "table";
  const [items, setItems] = useState<Table[]>([]);

  /**
   * Fetches table data from the API when the component mounts.
   * Updates the state with the retrieved table data.
   */
  useEffect(() => {
    (async () => {
      const data = await getItems<Table[]>(READ_TABLES_URL);
      if (data) {
        setItems(data);
      }
    })();
  }, []);

  return (
    <>
      <OverviewHeader title="Tische" assetType={assetType} />

      <FlexTable
        columns={TABLE_TABLE_COLUMNS}
        rows={items}
        assetType={assetType}
        showAssignments
        hasActionButtons
        actionButtonRowProps={{
          assetType: assetType,
          hasManageAssignmentsAction: true,
          assignmentOptions: [
            { type: AssignmentType.EMPLOYEE, multiple: true },
            { type: AssignmentType.COMPUTER, multiple: true },
            { type: AssignmentType.MONITOR, multiple: true },
            { type: AssignmentType.ROOM, multiple: false },
          ],
          hasQrCodeAction: true,
          hasExportAction: true,
          hasDeleteAction: true,
        }}
      />
    </>
  );
};

export default TableOverview;
