import { ReactNode } from "react";
import "../styles/Layout.css";
import QrCodeCollector from "./qr-code-collector/QrCodeCollector";
import Sidebar from "./sidebar/Sidebar";

interface LayoutProps {
  children: ReactNode;
}

const Layout = (props: LayoutProps) => {
  return (
    <>
      <Sidebar />
      <main>{props.children}</main>
      <QrCodeCollector />
    </>
  );
};

export default Layout;
