import { useEffect, useState } from "react";
import { AssignmentType } from "../../../components/assignment-modal/AssignmentModal.interface";
import OverviewHeader from "../../../components/overview-header/OverviewHeader";
import FlexTable from "../../../components/table/FlexTable";
import { LICENSE_TABLE_COLUMNS } from "../../../constants/tableColumns";
import { License } from "../../../models/License";
import { READ_LICENSES_URL } from "../../../services/apiRoutes";
import getItems from "../../../services/getItems";

/**
 * LicenseOverview component displays an overview of license records.
 * It fetches license data and displays it in a table with action buttons.
 *
 * @component
 * @returns {JSX.Element} A layout containing the overview header and a flexible table with license data.
 */
const LicenseOverview = () => {
  const assetType = "license";
  const [items, setItems] = useState<License[]>([]);

  /**
   * Fetches license data from the API and updates the items state.
   */
  useEffect(() => {
    (async () => {
      const data = await getItems<License[]>(READ_LICENSES_URL);
      if (data) {
        setItems(data);
      }
    })();
  }, []);

  return (
    <>
      <OverviewHeader title="Lizenzen" assetType={assetType} />

      <FlexTable
        columns={LICENSE_TABLE_COLUMNS}
        rows={items}
        assetType={assetType}
        showAssignments
        hasActionButtons
        actionButtonRowProps={{
          assetType: assetType,
          hasManageAssignmentsAction: true,
          assignmentOptions: [
            { type: AssignmentType.EMPLOYEE, multiple: true },
          ],
          hasQrCodeAction: false,
          hasExportAction: true,
          hasDeleteAction: true,
        }}
      />
    </>
  );
};

export default LicenseOverview;
