import { useState } from "react";

/**
 * Custom hook for toggling a text input visibility.
 * This hook encapsulates the logic for toggling the visibility of a text input field.
 * @returns {Object} An object containing the state variable for input visibility and
 * a function to toggle it.
 */
export default function useTextInputVisibilityToggle(): {
  showInput: boolean;
  toggleInputVisibility: () => void;
} {
  const [showInput, setShowInput] = useState<boolean>(false);

  /**
   * Function to toggle input visibility.
   * This function toggles the state variable for input visibility.
   */
  const toggleInputVisibility = (): void => {
    setShowInput((prevShowInput) => !prevShowInput);
  };

  return { showInput, toggleInputVisibility };
}
