import { format } from "date-fns";
import { de } from "date-fns/locale";
import { Dispatch } from "react";

/**
 * Toggles the collapse of the row that is given.
 * @param rowIndex Index of the row
 */
export const toggleCollapse = (
  rowIndex: number,
  setOpenRows: Dispatch<React.SetStateAction<any>>
) => {
  setOpenRows((prev: any) => ({
    ...prev,
    [rowIndex]: !prev[rowIndex],
  }));
};

/**
 * Formats booleans, dates and arrays for proper displaying in the table cells.
 * @param content Content that needs to get formatted
 * @returns Formatted booleans, dates and arrays: Array items get merged into one string a
 * nd are separated by comma. Dates are formatted like 02.04.2024.
 */
export const formatCellContent = (content: any): string => {
  if (content === null || content === undefined) return "";
  if (Array.isArray(content)) return content.join(", ");
  if (content instanceof Date)
    return format(content, "dd.MM.yyyy", { locale: de });
  if (typeof content === "boolean") return content ? "Ja" : "Nein";
  return String(content);
};
