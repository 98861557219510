/**
 * Returns the styles for the component.
 * @returns The styles object.
 */
export const useStyle = () => {
  return {
    buttonGroup: {
      display: "flex",
      gap: "4px",
      justifyContent: "end",
    },
  };
};
