export const useStyle = () => {
  return {
    inputContainer: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 1.2,
      width: "100%",
    },
    button: {
      width: "100%",
    },
  };
};
