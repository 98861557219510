import React, { Dispatch } from "react";
import { toast } from "react-toastify";
import { Mobilephone } from "../../../models/Mobilephone";
import {
  CREATE_MOBILEPHONE_URL,
  UPDATE_MOBILEPHONE_URL,
} from "../../../services/apiRoutes";
import createItem from "../../../services/createItem";
import updateItem from "../../../services/updateItem";
import sectionsData from "./mobilephoneInputFields.json";

export const initialData = {
  bill_number: "",
  comment: "",
  identifier: "",
  imei1: "",
  imei2: "",
  manufacturer: "",
  model: "",
  order_number: "",
  purchase_price: "",
  purchase_date: null,
  serialnumber: "",
  inventory_number: "",
  inventory_status: 0,
  inventory_status_details: {
    id: null,
    name: null,
  },
  inventory_type: 0,
  inventory_type_details: {
    id: null,
    name: null,
  },
  last_inventoried: null,
};

/**
 * Handles input change events and clears validation indicators after
 * a required input isn't empty anymore.
 * @param id Input field id.
 * @param value New Value of input field.
 * @param setEditedMobilephone Setter for >editedMobilephone<
 * @param setErrors Setter for >errors<
 */
export const handleInputChange = (
  id: string,
  value: any,
  setEditedMobilephone: Dispatch<React.SetStateAction<Mobilephone>>,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>,
) => {
  if (id === "purchase_price" && typeof value === "string") {
    value = value.replace(",", ".");
  }

  setEditedMobilephone((prevData) => ({
    ...prevData,
    [id]: value,
  }));

  // If input changes to valid: Remove error.
  setErrors((prevErrors) => {
    const newErrors = new Set(prevErrors);
    if (value) {
      newErrors.delete(id);
    }
    return newErrors;
  });
};

/**
 * If all required fields are filled: Handles the save action.
 * Either updates an existing item or creates a new one.
 * @param areRequiredFieldsFilled True if all required fields are filled.
 * @param editedMobilephone Mobilephone item that gets edited.
 * @param navigateCallback
 * @param isNew True if it is the creation of a new item.
 * @param id Mobilephone id.
 */
export const handleSaveChanges = async (
  areRequiredFieldsFilled: boolean,
  editedMobilephone: Mobilephone,
  navigateCallback: () => void,
  isNew: boolean,
  id?: string,
) => {
  // Save only if required fields aren't empty.
  if (areRequiredFieldsFilled) {
    if (editedMobilephone.purchase_date === "") {
      editedMobilephone.purchase_date = null;
    }
    editedMobilephone.inventory_status =
      editedMobilephone.inventory_status_details
        ? editedMobilephone.inventory_status_details.id
        : null;
    editedMobilephone.inventory_type = editedMobilephone.inventory_type_details
      ? editedMobilephone.inventory_type_details.id
      : null;
    if (isNew) {
      await createItem<Mobilephone>(CREATE_MOBILEPHONE_URL, editedMobilephone);
      // After creating a new element, go back to overview page.
      navigateCallback();
    } else if (id) {
      const { qr_code, ...newEditedMobilephone } = editedMobilephone;
      await updateItem<Mobilephone>(
        UPDATE_MOBILEPHONE_URL,
        id,
        newEditedMobilephone,
      );
    }
  } else {
    toast.error("Es sind noch nicht alle Pflichtfelder ausgefüllt.");
  }
};

/**
 * Checks if all required fields are filled.
 * @param editedMobilephone Mobilephone item that gets edited.
 * @param setErrors Setter for >errors<
 * @returns TRUE: All required fields are filled. FALSE: Some required fields are empty.
 */
export const validateFields = (
  editedMobilephone: Mobilephone,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>,
) => {
  const newErrors = new Set<string>();

  sectionsData.forEach((section) => {
    section.fields.forEach((field) => {
      const value = editedMobilephone[field.id as keyof Mobilephone];

      if ("required" in field && field.required && !value) {
        newErrors.add(field.id);
      }
    });
  });

  setErrors(newErrors);
  return newErrors.size === 0;
};
