export const useStyle = () => {
  return {
    form: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
      alignItems: "flex-start",
    },

    newPasswordBox: {
      display: "flex",
      gap: "16px",
    },
  };
};
