import { Dispatch } from "react";
import { toast } from "react-toastify";

import {
  CREATE_BUILDING_URL,
  UPDATE_BUILDING_URL,
} from "../../../services/apiRoutes";
import createItem from "../../../services/createItem";
import updateItem from "../../../services/updateItem";
import sectionsData from "./buildingInputFields.json";
import { Building } from "../../../models/Building";

// Typen für Felder und Sektionen definieren
type Field = {
  label: string;
  id: string;
  type: string;
  required: boolean;
  mask?: boolean;
  copyable?: boolean;
};

type Section = {
  sectionTitle: string;
  fields: Field[];
};

// Initiale Daten für ein Gebäude
export const initialData: Building = {
  identifier: "",
  designation: "",
  street: "",
  number: "",
  zip_code: "",
  city: "",
  country: "",
  comment: "",
  assigned_to: null, // Foreign key, expected to be an object with id
  assigned_date: null,
  return_date: null,
};

/**
 * Handles input change events and clears validation indicators after
 * a required input isn't empty anymore.
 * @param id Input field id.
 * @param value New Value of input field.
 * @param setEditedBuilding Setter for >editedBuilding<
 * @param setErrors Setter for >errors<
 */
export const handleInputChange = (
  id: string,
  value: any,
  setEditedBuilding: Dispatch<React.SetStateAction<Building>>,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>
) => {
  setEditedBuilding((prevData) => ({
    ...prevData,
    [id]: value,
  }));

  // If input changes to valid: Remove error.
  setErrors((prevErrors) => {
    const newErrors = new Set(prevErrors);
    if (
      value &&
      (typeof value !== "object" || (value.id !== 0 && value.name !== ""))
    ) {
      newErrors.delete(id);
    }
    return newErrors;
  });
};

/**
 * If all required fields are filled: Handles the save action.
 * Either updates an existing item or creates a new one.
 * @param areRequiredFieldsFilled True if all required fields are filled.
 * @param editedBuilding item that gets edited.
 * @param isNew True if it's the creation of a new item.
 * @param idBuilding id.
 */
export const handleSaveChanges = async (
  areRequiredFieldsFilled: boolean,
  editedBuilding: Building,
  navigateCallback: () => void,
  isNew: boolean,
  id?: string
) => {
  // Save only if required fields aren't empty.
  if (areRequiredFieldsFilled) {
    if (editedBuilding.assigned_date === "") {
      editedBuilding.assigned_date = null;
    }
    if (isNew) {
      await createItem<Building>(CREATE_BUILDING_URL, editedBuilding);
      // After creating a new element, go back to overview page.
      navigateCallback();
    } else if (id) {
      await updateItem<Building>(UPDATE_BUILDING_URL, id, editedBuilding);
    }
  } else {
    toast.error("Es sind noch nicht alle Pflichtfelder ausgefüllt.");
  }
};

/**
 * Checks if all required fields are filled.
 * @param editedBuilding item that gets edited.
 * @param setErrors Setter for >errors<
 * @returns TRUE: All required fields are filled. FALSE: Some required fields are empty.
 */
export const validateFields = (
  editedBuilding: Building,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>
) => {
  const newErrors = new Set<string>();

  // Typisierung für `sectionsData` sicherstellen
  (sectionsData as Section[]).forEach((section: Section) => {
    section.fields.forEach((field: Field) => {
      const value = editedBuilding[field.id as keyof Building];

      // If nothing in there, then add error.
      if (
        field.required &&
        (!value || (typeof value === "object" && value.id === 0 && value.name === ""))
      ) {
        newErrors.add(field.id);
      }
    });
  });

  setErrors(newErrors);
  return newErrors.size === 0;
};
