import { Navigate, Outlet } from "react-router-dom";
import { Box } from "@mui/material";
import { routes } from "../../routes";
import { useAuthStore } from "../../../stores/auth/useAuthStore";
import { useUserStore } from "../../../stores/user/useUserDataStore";
import { useStyle } from "./PublicLayout.styles";
/**
 * PublicLayout Component
 *
 * @component
 * @returns {JSX.Element} - A layout component for public routes.
 *
 * @remarks
 * - If the user is authenticated (`auth` is true), redirects to the home page ("/").
 * - Otherwise, renders child components via the `Outlet` component.
 */
const PublicLayout = () => {
  const styles = useStyle();
  const token = useAuthStore((state) => state.token);
  const user = useUserStore((state) => state.user);

  return token?.accessToken && user?.id ? (
    <Navigate to={routes.dashboard} replace />
  ) : (
    <Box sx={styles.page}>
      <Box sx={styles.container}>
        <Outlet />
      </Box>
    </Box>
  );
};

export default PublicLayout;
