import { axiosCall } from "../api/config/axiosCall";
import { toast } from "react-toastify";

/**
 * Retrieves an item by sending a GET request to the specified URL with the given ID.
 *
 * @template T - The type of the response data expected from the GET request.
 * @param {string} url - The base URL to which the GET request is sent.
 * @param {string | number} id - The ID of the item to be retrieved.
 * @returns {Promise<T | null>} A promise that resolves to the item data if the request is successful,
 * or `null` if there is an error during the request.
 */
const getItem = async <T,>(
  url: string,
  id: string | number,
): Promise<T | null> => {
  try {
    const response = await axiosCall({
      endpoint: `${url}/${id}`,
      method: "GET",
    });
    return response?.data;
  } catch (error) {
    toast.error(`Fehler beim Abrufen der Daten von ${url}/${id}`);
    return null;
  }
};

export default getItem;
