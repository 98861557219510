import { Box } from "@mui/material";
import { useStyle } from "./Login.styles";
import StandardLogin from "../../../components/auth/StandardLogin";

/**
 * Login - A functional component that renders the login page layout.
 *
 * @component
 * @returns {JSX.Element} The rendered login page component.
 */
const Login = () => {
  const styles = useStyle();

  return (
    <>
      <Box sx={styles.logo}>
        <img
          style={styles.logo_img}
          src={`${process.env.PUBLIC_URL}/Logo.png`}
          alt="Logo"
        />
        <Box sx={styles.logo_name}>ziings</Box>
      </Box>
      <StandardLogin />
    </>
  );
};

export default Login;
