import { create } from "zustand";
import { devtools, persist, createJSONStorage } from "zustand/middleware";

// Interface defining the structure of a QRCodeReference
export interface QRCodeReference {
  assetType: string; // Type of the asset
  assetId: string; // ID of the asset
  label: string; // Label for the QR code
}

// Interface defining the state and actions for the QR code store
interface QRCodeState {
  qrCodes: QRCodeReference[]; // Array of QR codes
  addQrCode: (assetType: string, assetId: string, label: string) => void; // Function to add a QR code
  removeQrCode: (assetType: string, assetId: string) => void; // Function to remove a QR code
  getQrCodeCount: () => number; // Function to get the count of QR codes
  resetQrCodes: () => void; // Function to reset all QR codes
}

// Create the QR code store
const useQRCodeStore = create<QRCodeState>()(
  devtools(
    persist(
      (set, get) => ({
        qrCodes: [], // Initial state: empty array of QR codes

        // Function to add a QR code
        addQrCode: (assetType, assetId, label) =>
          set((state) => ({
            // Check if the QR code already exists, if not, add it to the array
            qrCodes: state.qrCodes.some(
              (qr) => qr.assetType === assetType && qr.assetId === assetId
            )
              ? state.qrCodes // If QR code exists, return the existing array
              : [...state.qrCodes, { assetType, assetId, label }], // If not, add new QR code
          })),

        // Function to remove a QR code
        removeQrCode: (assetType, assetId) =>
          set((state) => ({
            // Filter out the QR code to be removed
            qrCodes: state.qrCodes.filter(
              (qr) => !(qr.assetType === assetType && qr.assetId === assetId)
            ),
          })),

        // Function to get the count of QR codes
        getQrCodeCount: () => get().qrCodes.length,

        // Function to reset all QR codes
        resetQrCodes: () => set({ qrCodes: [] }),
      }),
      {
        name: "qr-code-storage", // Name for local storage key
        storage: createJSONStorage(() => localStorage),
      }
    )
  )
);

export default useQRCodeStore;
