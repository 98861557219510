import React, { Dispatch } from "react";
import { toast } from "react-toastify";
import { Contract } from "../../../models/Contract";
import {
  CREATE_CONTRACT_URL,
  UPDATE_CONTRACT_URL,
} from "../../../services/apiRoutes";
import createItem from "../../../services/createItem";
import updateItem from "../../../services/updateItem";
import sectionsData from "./contractInputFields.json";

export const initialData = {
  identifier: "",
  comment: "",
  computedMonthlyCost: 0,
  contractEnd: null,
  contractMail: "",
  contractNumber: "",
  contractStart: null,
  customerNumber: "",
  dataVolume: 0,
  dataVolumeUnit: 0,
  data_volume_unit_details: {
    id: 0,
    name: "",
  },
  discountDuration: "",
  hotline: "",
  hotlinePassword: "",
  minimumContractDuration: "",
  mobileNetwork: 0,
  mobile_network_details: {
    id: 0,
    name: "",
  },
  monthlyCost: 0,
  monthlyDiscount: 0,
  password: "",
  periodOfNotice: "",
  phoneNumber: 0,
  pin: 0,
  provider: "",
  puk: 0,
  sim: 0,
  username: "",
};

/**
 * Handles input change events and clears validation indicators after
 * a required input isn't empty anymore.
 * @param id Input field id.
 * @param value New Value of input field.
 * @param setEditedContract Setter for >editedContract<
 * @param setErrors Setter for >errors<
 */
export const handleInputChange = (
  id: string,
  value: any,
  setEditedContract: Dispatch<React.SetStateAction<Contract>>,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>,
) => {
  if (
    (id === "monthlyDiscount" ||
      id === "monthlyCost" ||
      id === "computedMonthlyCost") &&
    typeof value === "string"
  ) {
    value = value.replace(",", ".");
  }

  setEditedContract((prevData) => ({
    ...prevData,
    [id]: value,
  }));

  // If input changes to valid: Remove error.
  setErrors((prevErrors) => {
    const newErrors = new Set(prevErrors);
    if (
      value &&
      (typeof value !== "object" || (value.id !== 0 && value.name !== ""))
    ) {
      newErrors.delete(id);
    }
    return newErrors;
  });
};

/**
 * If all required fields are filled: Handles the save action.
 * Either updates an existing item or creates a new one.
 * @param areRequiredFieldsFilled True if all required fields are filled.
 * @param editedContract Contract item that gets edited.
 * @param navigateCallback
 * @param isNew True if it is the creation of a new item.
 * @param id Contract id.
 */
export const handleSaveChanges = async (
  areRequiredFieldsFilled: boolean,
  editedContract: Contract,
  navigateCallback: () => void,
  isNew: boolean,
  id?: string,
) => {
  // Save only if required fields aren't empty.
  if (areRequiredFieldsFilled) {
    editedContract.dataVolumeUnit = editedContract.data_volume_unit_details.id;
    editedContract.mobileNetwork = editedContract.mobile_network_details.id;
    if (isNew) {
      await createItem<Contract>(CREATE_CONTRACT_URL, editedContract);
      // After creating a new element, go back to overview page.
      navigateCallback();
    } else if (id) {
      await updateItem<Contract>(UPDATE_CONTRACT_URL, id, editedContract);
    }
  } else {
    toast.error("Es sind noch nicht alle Pflichtfelder ausgefüllt.");
  }
};

/**
 * Checks if all required fields are filled.
 * @param editedContract Contract item that gets edited.
 * @param setErrors Setter for >errors<
 * @returns TRUE: All required fields are filled. FALSE: Some required fields are empty.
 */
export const validateFields = (
  editedContract: Contract,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>,
) => {
  const newErrors = new Set<string>();

  sectionsData.forEach((section) => {
    section.fields.forEach((field) => {
      const value = editedContract[field.id as keyof Contract];

      // If nothing in there, then add error.
      // Or: If value is an object but has nothing in there, then add error.
      if (
        field.required &&
        (!value ||
          (typeof value === "object" && value.id === 0 && value.name === ""))
      ) {
        newErrors.add(field.id);
      }
    });
  });

  setErrors(newErrors);
  return newErrors.size === 0;
};
