import React, { Dispatch } from "react";
import { toast } from "react-toastify";
import { Landline } from "../../../models/Landline";
import {
  CREATE_LANDLINE_URL,
  UPDATE_LANDLINE_URL,
} from "../../../services/apiRoutes";
import createItem from "../../../services/createItem";
import updateItem from "../../../services/updateItem";
import sectionsData from "./landlineInputFields.json";

export const initialData = {
  bill_number: "",
  comment: "",
  ip: "",
  mac: "",
  manufacturer: "",
  model: "",
  order_number: "",
  purchase_price: "",
  purchase_date: null,
  identifier: "",
  inventory_number: "",
  inventory_status: 0,
  inventory_status_details: {
    id: null,
    name: null,
  },
  inventory_type: 0,
  inventory_type_details: {
    id: null,
    name: null,
  },
  last_inventoried: null,
};

/**
 * Handles input change events and clears validation indicators after
 * a required input isn't empty anymore.
 * @param id Input field id.
 * @param value New Value of input field.
 * @param setEditedLandline Setter for >editedLandline<
 * @param setErrors Setter for >errors<
 */
export const handleInputChange = (
  id: string,
  value: any,
  setEditedLandline: Dispatch<React.SetStateAction<Landline>>,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>,
) => {
  if (id === "purchase_price" && typeof value === "string") {
    value = value.replace(",", ".");
  }

  setEditedLandline((prevData) => ({
    ...prevData,
    [id]: value,
  }));

  // If input changes to valid: Remove error.
  setErrors((prevErrors) => {
    const newErrors = new Set(prevErrors);
    if (value) {
      newErrors.delete(id);
    }
    return newErrors;
  });
};

/**
 * If all required fields are filled: Handles the save action.
 * Either updates an existing item or creates a new one.
 * @param areRequiredFieldsFilled True if all required fields are filled.
 * @param editedLandline Landline item that gets edited.
 * @param navigateCallback
 * @param isNew True if it is the creation of a new item.
 * @param id Landline id.
 */
export const handleSaveChanges = async (
  areRequiredFieldsFilled: boolean,
  editedLandline: Landline,
  navigateCallback: () => void,
  isNew: boolean,
  id?: string,
) => {
  // Save only if required fields aren't empty.
  if (areRequiredFieldsFilled) {
    if (editedLandline.purchase_date === "") {
      editedLandline.purchase_date = null;
    }
    editedLandline.inventory_status = editedLandline.inventory_status_details
      ? editedLandline.inventory_status_details.id
      : null;
    editedLandline.inventory_type = editedLandline.inventory_type_details
      ? editedLandline.inventory_type_details.id
      : null;
    if (isNew) {
      await createItem<Landline>(CREATE_LANDLINE_URL, editedLandline);
      // After creating a new element, go back to overview page.
      navigateCallback();
    } else if (id) {
      const { qr_code, ...newEditedLandline } = editedLandline;
      await updateItem<Landline>(UPDATE_LANDLINE_URL, id, newEditedLandline);
    }
  } else {
    toast.error("Es sind noch nicht alle Pflichtfelder ausgefüllt.");
  }
};

/**
 * Checks if all required fields are filled.
 * @param editedLandline Landline item that gets edited.
 * @param setErrors Setter for >errors<
 * @returns TRUE: All required fields are filled. FALSE: Some required fields are empty.
 */
export const validateFields = (
  editedLandline: Landline,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>,
) => {
  const newErrors = new Set<string>();

  sectionsData.forEach((section) => {
    section.fields.forEach((field) => {
      const value = editedLandline[field.id as keyof Landline];

      if (
        "required" in field &&
        field.required &&
        (!value ||
          (typeof value === "object" &&
            "id" in value &&
            (value as any).id === 0))
      ) {
        newErrors.add(field.id);
      }
    });
  });

  setErrors(newErrors);
  return newErrors.size === 0;
};
