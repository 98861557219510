import { ButtonColor, ButtonVariant } from "./FlexButton.interface";

export const useStyle = (
  variant: ButtonVariant = ButtonVariant.TEXT_FILLED,
  color: ButtonColor = ButtonColor.PRIMARY
) => {
  const getColors = () => {
    switch (color) {
      case ButtonColor.PRIMARY:
        return { bgColor: "#40a9bf", textColor: "#fff" };
      case ButtonColor.CAUTION:
        return { bgColor: "#BE543C", textColor: "#fff" };
      case ButtonColor.GRAY:
        return { bgColor: "#F6F6F6", textColor: "#001F26" };
    }
  };

  const { bgColor, textColor } = getColors();

  const baseStyle = {
    button: {
      fontSize: "16px",
      textTransform: "none",
      border: "none",
      borderRadius: "8px",
      cursor: "pointer",
      position: "revert",
      transition: "transform 0.2s ease-in-out",
      minWidth: "revert",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      "&:hover": {
        filter: "brightness(90%)",
      },
    },
  };

  const getVariantStyle = () => {
    switch (variant) {
      case ButtonVariant.TEXT_FILLED:
        return {
          padding: "6px 20px",
          backgroundColor: `${bgColor} !important`,
          color: textColor,
        };
      case ButtonVariant.TEXT_OUTLINED:
        return {
          padding: "6px 20px",
          border: `2px solid ${bgColor}`,
          color: bgColor,
        };
      case ButtonVariant.ICON_FILLED:
        return {
          padding: "8px",
          backgroundColor: `${bgColor} !important`,
          color: textColor,
          aspectRatio: "1",
        };
      case ButtonVariant.ICON_OUTLINED:
        return {
          padding: "8px",
          border: `2px solid ${bgColor}`,
          color: bgColor,
          aspectRatio: "1",
        };
      case ButtonVariant.ICON_TRANSPARENT:
        return {
          padding: "8px",
          color: bgColor,
          aspectRatio: "1",
        };
      case ButtonVariant.ICON_ROUNDED:
        return {
          padding: "8px",
          borderRadius: "24px",
          backgroundColor: "#F6F6F6 !important",
          color: "#001F26",
          aspectRatio: "1",
        };
    }
  };

  return {
    ...baseStyle,
    selectedVariant: getVariantStyle(),
  };
};
