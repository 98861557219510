import { useEffect, useState } from "react";
import { AssignmentType } from "../../../components/assignment-modal/AssignmentModal.interface";
import OverviewHeader from "../../../components/overview-header/OverviewHeader";
import FlexTable from "../../../components/table/FlexTable";
import { ACCESSORY_TABLE_COLUMNS } from "../../../constants/tableColumns";
import { Accessory } from "../../../models/Accessory";
import { READ_ACCESSORIES_URL } from "../../../services/apiRoutes";
import getItems from "../../../services/getItems";

/**
 * AccessoryOverview component displays an overview of accessories.
 * It fetches accessory data and displays it in a table with action buttons.
 *
 * @component
 * @returns {JSX.Element} A layout containing the overview header and a flexible table with accessory data.
 */
const AccessoryOverview = () => {
  const assetType = "accessory";
  const [items, setItems] = useState<Accessory[]>([]);

  /**
   * Fetches accessory data from the API and sets the items state.
   */
  useEffect(() => {
    (async () => {
      const data = await getItems<Accessory[]>(READ_ACCESSORIES_URL);
      if (data) {
        setItems(data);
      }
    })();
  }, []);

  return (
    <>
      <OverviewHeader title="Büroausstattungen" assetType={assetType} />

      <FlexTable
        columns={ACCESSORY_TABLE_COLUMNS}
        rows={items}
        assetType={assetType}
        showAssignments
        hasActionButtons
        actionButtonRowProps={{
          assetType: assetType,
          hasManageAssignmentsAction: true,
          assignmentOptions: [
            { type: AssignmentType.EMPLOYEE, multiple: false },
            { type: AssignmentType.ROOM, multiple: false },
          ],
          hasQrCodeAction: true,
          hasExportAction: true,
          hasDeleteAction: true,
        }}
      />
    </>
  );
};

export default AccessoryOverview;
