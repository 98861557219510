import { Dispatch } from "react";

import { toast } from "react-toastify";
import { Employee } from "../../models/Employee";
import {
  CREATE_EMPLOYEE_URL,
  UPDATE_EMPLOYEE_URL,
} from "../../services/apiRoutes";
import createItem from "../../services/createItem";
import updateItem from "../../services/updateItem";
import sectionsData from "./employeeInputFields.json";

export const initialData = {
  id: "",
  first_name: "",
  last_name: "",
  start_date: "",
  end_date: null,
  workplace: null,
  workplace_details: {
    id: null,
    name: null,
  },
  departments: null,
  department_details: {
    id: null,
    name: null,
  },
  user_roles: "",
  user_role_details: {
    id: "",
    name: "",
  },
  onboarded: false,
  offboarded: false,
};

/**
 * Handles input change events and clears validation indicators after
 * an required input isn't empty anymore.
 * @param id Input field id.
 * @param value New Value of input field.
 * @param setEditedEmployee Setter for >editedEmployee<
 * @param setErrors Setter for >errors<
 */
export const handleInputChange = (
  id: string,
  value: any,
  setEditedEmployee: Dispatch<React.SetStateAction<Employee>>,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>
) => {
  setEditedEmployee((prevData) => ({
    ...prevData,
    [id]: value,
  }));

  // If input changes to valid: Remove error.
  setErrors((prevErrors) => {
    const newErrors = new Set(prevErrors);
    if (
      value &&
      (typeof value !== "object" || (value.id !== 0 && value.name !== ""))
    ) {
      newErrors.delete(id);
    }
    return newErrors;
  });
};

/**
 * If all required fields are filled: Handles the save action.
 * Either updates an existing item or creates a new one.
 * @param areRequiredFieldsFilled True if all required fields are filled.
 * @param editedEmployee Employee item that gets edited.
 * @param isNew True if it the creation of a new item.
 * @param id Employee id.
 */
export const handleSaveChanges = async (
  areRequiredFieldsFilled: boolean,
  editedEmployee: Employee,
  navigateCallback: () => void,
  isNew: boolean,
  id?: string
) => {
  // Save only if required fields aren't empty.
  if (areRequiredFieldsFilled) {
    editedEmployee.workplace = editedEmployee.workplace_details
      ? editedEmployee.workplace_details.id
      : null;
    editedEmployee.departments = editedEmployee.department_details
      ? editedEmployee.department_details.id
      : null;
    editedEmployee.user_roles = editedEmployee.user_role_details
      ? editedEmployee.user_role_details.id
      : null;
    if (isNew) {
      await createItem(CREATE_EMPLOYEE_URL, {
        ...editedEmployee,
        leaveDate: editedEmployee.end_date ? editedEmployee.end_date : null,
      });
      // After creating a new element, go back to overview page.
      navigateCallback();
    } else if (id) {
      await updateItem(UPDATE_EMPLOYEE_URL, id, {
        ...editedEmployee,
        leaveDate: editedEmployee.end_date ? editedEmployee.end_date : null,
      });
    }
  } else {
    toast.error("Es sind noch nicht alle Pflichtfelder ausgefüllt.");
  }
};

/**
 * Checks if all required fields are filled.
 * @param editedEmployee Employee item that gets edited.
 * @param setErrors Setter for >errors<
 * @returns TRUE: All required fields are filled. FALSE: Some required fields are empty.
 */
export const validateFields = (
  editedEmployee: Employee,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>
) => {
  const newErrors = new Set<string>();

  sectionsData.forEach((section) => {
    section.fields.forEach((field) => {
      const value = editedEmployee[field.id as keyof Employee];

      // If nothing in there, then add error.
      // Or: If value is an object but has nothing in there, then add error.
      if (
        field.required &&
        (!value ||
          (typeof value === "object" &&
            value.id === null &&
            value.name === null))
      ) {
        newErrors.add(field.id);
      }
    });
  });

  setErrors(newErrors);
  return newErrors.size === 0;
};
