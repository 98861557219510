import axios from "axios";
import { toast } from "react-toastify";
import { GENERATE_QR_CODE_PDF_URL } from "../../services/apiRoutes";
import { QRCodeReference } from "../../stores/useQRCodeStore";

/**
 * Generates a QR code label string based on the asset type and id.
 * @param assetType The type of the asset
 * @param id The unique identifier for the asset
 * @returns A string formatted as `${assetType}:${id}`
 */
export const getQrCodeLabel = (assetType: string, id: string) => {
  return `${assetType}:${id}`;
};

// Mapping for german Asset-Types
const assetTypeMapping: Record<string, string> = {
  computer: "Computer",
  mobilephone: "Mobiltelefon",
  accessory: "Büroausstattung",
  compartment: "Abteil",
  contract: "Vertrag",
  dooraccess: "Türzugang",
  employee: "Mitarbeitende",
  landline: "Festnetztelefon",
  license: "Lizenz",
  landlinecontract: "Festnetzvertrag",
  monitor: "Monitor",
  networkdevice: "Netzwerkgerät",
  room: "Raum",
  server: "Server",
  table: "Tisch",
  virtualmachine: "Virtuelle Maschine",
  literature: "Literatur",
};

/** Function to get the german name of the assettype. */
export const getGermanAssetType = (assetType: string): string => {
  return assetTypeMapping[assetType.toLowerCase()] || assetType;
};

/** Function to handle the print action. */
export const handlePrint = async (
  qrCodes: QRCodeReference[],
  handleClose: () => void
) => {
  try {
    // Construct the refs parameter from the QR codes
    const refs = qrCodes
      .map((code) => `${code.assetType}:${code.assetId}`)
      .join(",");

    // Make an API call to generate the PDF
    const response = await axios.get(GENERATE_QR_CODE_PDF_URL, {
      params: { refs },
      responseType: "blob",
    });

    // Create a URL for the generated PDF and trigger the download
    const url = window.URL.createObjectURL(new Blob([response?.data]));
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "QR_Codes.pdf");
    document.body.appendChild(link);
    link.click();
    link.remove();

    // Close the print modal after download
    handleClose();
  } catch (error) {
    console.error("Error generating PDF:", error);
    toast.error(
      "Fehler beim Generieren des PDF. Bitte versuchen Sie es erneut."
    );
  }
};
