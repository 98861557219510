import { Navigate, Outlet, useLocation } from "react-router-dom";
import { routes } from "../../routes";
import { useAuthStore } from "../../../stores/auth/useAuthStore";
import { useUserStore } from "../../../stores/user/useUserDataStore";
import Layout from "../../../components/Layout";

/**
 * ProtectedRoutes Component
 *
 * @component
 * @returns {JSX.Element} - A component that guards protected routes.
 *
 * @remarks
 * - Checks if the user is authenticated (`auth`).
 * - If authenticated, renders the child components via `Outlet`.
 * - If not authenticated, redirects to the login page (`routes.login`) and passes
 * the current location in the state, allowing for redirection back after a successful login.
 *
 * @note
 * The inclusion of `state={{ from: location }}` in the `Navigate` component
 * is important for preserving the user's original navigation intent.
 * When a user is redirected to the login page, the `from` property stores the
 * route they were attempting to access. After a successful login, this state
 * can be used to redirect the user back to their intended destination,
 * enhancing user experience by allowing them to continue from where they left off.
 */
const ProtectedLayout = () => {
  const token = useAuthStore((state) => state.token);
  const user = useUserStore((state) => state.user);
  const location = useLocation();

  return token?.accessToken && user?.id ? (
    <Layout>
      <Outlet />
    </Layout>
  ) : (
    <Navigate to={routes.login} state={{ from: location }} replace />
  );
};
export default ProtectedLayout;
