import React, { Dispatch } from "react";
import { toast } from "react-toastify";
import { License } from "../../../models/License";
import {
  CREATE_LICENSE_URL,
  UPDATE_LICENSE_URL,
} from "../../../services/apiRoutes";
import createItem from "../../../services/createItem";
import updateItem from "../../../services/updateItem";
import sectionsData from "./licenseInputFields.json";

export const initialData = {
  identifier: "",
  bill_number: "",
  contractDuration: 0,
  contract_duration_details: {
    id: null,
    name: null,
  },
  inventory_number: "",
  licenseKey: "",
  licenseType: "",
  licensor: "",
  order_number: "",
  payment_cycle: 0,
  payment_cycle_details: {
    id: null,
    name: null,
  },
  periodOfNotice: "",
  purchase_price: "",
  purchase_date: null,
  manufacturer: "",
};

/**
 * Handles input change events and clears validation indicators after
 * a required input isn't empty anymore.
 * @param id Input field id.
 * @param value New Value of input field.
 * @param setEditedLicense Setter for >editedLicense<
 * @param setErrors Setter for >errors<
 */
export const handleInputChange = (
  id: string,
  value: any,
  setEditedLicense: Dispatch<React.SetStateAction<License>>,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>
) => {
  if (id === "purchase_price" && typeof value === "string") {
    value = value.replace(",", ".");
  }

  setEditedLicense((prevData) => ({
    ...prevData,
    [id]: value,
  }));

  // If input changes to valid: Remove error.
  setErrors((prevErrors) => {
    const newErrors = new Set(prevErrors);
    if (
      value &&
      (typeof value !== "object" || (value.id !== 0 && value.name !== ""))
    ) {
      newErrors.delete(id);
    }
    return newErrors;
  });
};

/**
 * If all required fields are filled: Handles the save action.
 * Either updates an existing item or creates a new one.
 * @param areRequiredFieldsFilled True if all required fields are filled.
 * @param editedLicense License item that gets edited.
 * @param navigateCallback
 * @param isNew True if it is the creation of a new item.
 * @param id License id.
 */
export const handleSaveChanges = async (
  areRequiredFieldsFilled: boolean,
  editedLicense: License,
  navigateCallback: () => void,
  isNew: boolean,
  id?: string
) => {
  // Save only if required fields aren't empty.
  if (areRequiredFieldsFilled) {
    if (editedLicense.purchase_date === "") {
      editedLicense.purchase_date = null;
    }

    editedLicense.contractDuration = editedLicense.contract_duration_details
      ? editedLicense.contract_duration_details.id
      : null;

    editedLicense.payment_cycle = editedLicense.payment_cycle_details
      ? editedLicense.payment_cycle_details.id
      : null;
    if (isNew) {
      await createItem<License>(CREATE_LICENSE_URL, editedLicense);
      // After creating a new element, go back to overview page.
      navigateCallback();
    } else if (id) {
      await updateItem<License>(UPDATE_LICENSE_URL, id, editedLicense);
    }
  } else {
    toast.error("Es sind noch nicht alle Pflichtfelder ausgefüllt.");
  }
};

/**
 * Checks if all required fields are filled.
 * @param editedLicense License item that gets edited.
 * @param setErrors Setter for >errors<
 * @returns TRUE: All required fields are filled. FALSE: Some required fields are empty.
 */
export const validateFields = (
  editedLicense: License,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>
) => {
  const newErrors = new Set<string>();

  sectionsData.forEach((section) => {
    section.fields.forEach((field) => {
      const value = editedLicense[field.id as keyof License];

      // If nothing in there, then add error.
      // Or: If value is an object but has nothing in there, then add error.
      if (
        field.required &&
        (!value ||
          (typeof value === "object" && value.id === 0 && value.name === ""))
      ) {
        newErrors.add(field.id);
      }
    });
  });

  setErrors(newErrors);
  return newErrors.size === 0;
};
