import ChangePassword from "../../components/profile/change-password/ChangePassword";

/**
 * Profile component that displays the user's profile page.
 * It includes the option to change the password.
 *
 * @returns {JSX.Element} The profile page with a password change option.
 */
const Profile = () => {
  return (
    <>
      <h1>Profil</h1>
      <ChangePassword />
    </>
  );
};

export default Profile;
