import { useAuthStore } from "../../../stores/auth/useAuthStore";
import { useUserStore } from "../../../stores/user/useUserDataStore";
import { clearData } from "../../config/utils";

/**
 * useLogout - Custom hook for handling user logout logic.
 *
 * This hook provides the `logout` function which clears authentication data
 * and user data.
 *
 * @returns {Object} - An object containing the `logout` function.
 * @returns {Function} logout - A function that clears authentication and
 * user data.
 */
export const useLogout = () => {
  /**
   * logout - Clears authentication and user data.
   *
   * This function is used to clear all user-related data from the application state,
   * effectively logging out the user.
   *
   * @returns {Promise<void>} - A promise that resolves when the logout process is complete.
   */
  const logout = async () => {
    clearData();
  };

  return { logout };
};
