import { useEffect, useState } from "react";
import { AssignmentType } from "../../../components/assignment-modal/AssignmentModal.interface";
import OverviewHeader from "../../../components/overview-header/OverviewHeader";
import FlexTable from "../../../components/table/FlexTable";
import { LOCATION_TABLE_COLUMNS } from "../../../constants/tableColumns";
import { Location } from "../../../models/Location";
import { READ_LOCATIONS_URL } from "../../../services/apiRoutes";
import getItems from "../../../services/getItems";

/**
 * LocationOverview component displays a list of locations in a tabular format.
 * It fetches location data from an API endpoint and renders it using the FlexTable component.
 * Users can perform actions like adding QR codes, deleting, or viewing details of locations.
 *
 * @component
 * @returns {JSX.Element} A layout containing an overview header and a table of location data.
 */
const LocationOverview = () => {
  const assetType = "location";
  const [items, setItems] = useState<Location[]>([]);

  /**
   * Fetches location data from the API when the component mounts.
   * Updates the state with the retrieved location data.
   */
  useEffect(() => {
    (async () => {
      const data = await getItems<Location[]>(READ_LOCATIONS_URL);
      if (data) {
        setItems(data);
      }
    })();
  }, []);

  return (
    <>
      <OverviewHeader title="Standort" assetType={assetType} />

      <FlexTable
        columns={LOCATION_TABLE_COLUMNS}
        rows={items}
        assetType={assetType}
        showAssignments
        hasActionButtons
        actionButtonRowProps={{
          assetType: assetType,
          hasManageAssignmentsAction: true,
          assignmentOptions: [
            { type: AssignmentType.EMPLOYEE, multiple: true },
            { type: AssignmentType.BUILDING, multiple: true },
          ],
          hasQrCodeAction: true,
          hasExportAction: true,
          hasDeleteAction: true,
        }}
      />
    </>
  );
};

export default LocationOverview;
