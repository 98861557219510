import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { BASE_URL } from "../../services/apiRoutes";
import { Token } from "../../stores/auth/interfaces";
import { useAuthStore } from "../../stores/auth/useAuthStore";
import { useUserStore } from "../../stores/user/useUserDataStore";
import { endpoints } from "./api-endpoints";

/**
 * Clears the authentication and user data from the application's state.
 *
 * This function is used to remove all stored authentication and user information
 * from the state by invoking the `clearAuthData` and `clearUserData` methods
 * from their respective stores. It returns `null` to indicate that the data
 * has been cleared.
 */
export const clearData = () => {
  useAuthStore.getState().clearAuthData();
  useUserStore.getState().clearUserData();
};

/**
 * Decodes a given JWT access token.
 *
 * @param {string} accessToken - The JWT access token to decode.
 * @returns {Object} The decoded token payload.
 */
export const decodeAccessToken = (accessToken: string) => {
  return jwtDecode(accessToken);
};

/**
 * Checks if the given JWT token is still valid based on its expiration time.
 *
 * @param {Object} tokens - The decoded JWT token payload.
 * @param {number} tokens.exp - The expiration time of the token in seconds since the epoch.
 * @returns {boolean} True if the token is still valid, false otherwise.
 */
export const checkIfTokenIsValid = (tokens: Token) => {
  const expirationTimestamp = tokens?.exp * 1000;
  const currentTimestamp = Date.now();
  return expirationTimestamp > currentTimestamp;
};

/**
 * Fetches the CSRF token from the server and stores it in local storage.
 *
 * @returns {Promise<string | void>} The fetched CSRF token if successful,
 * or undefined if an error occurs.
 */
export const getCsrfToken = async () => {
  const url = `${BASE_URL}/${endpoints.csrf}`;
  try {
    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
      },
    });

    const csrfToken = response?.data?.csrf_token;
    if (csrfToken) {
      useAuthStore.getState().setCsrfToken(csrfToken);
    }
    return csrfToken;
  } catch (error) {
    useAuthStore.getState().setCsrfToken("");
  }
};

/**
 * Refreshes the access token using the provided refresh token.
 *
 * This function sends a request to the server to obtain a new access token using
 * the provided refresh token. If successful, it decodes the new access token to
 * determine its expiration time and updates the token information in the state.
 * If the refresh fails, it clears the authentication and user data.
 *
 * @param {Token} token - An object containing the current tokens (access and refresh).
 * @returns {Promise<string | null>} - The new access token if the refresh is successful,
 *                                     or null if the refresh fails.
 *
 * @throws Will catch and handle any errors that occur during the token refresh process.
 */
export const refreshAccessToken = async (token: Token) => {
  const url = `${BASE_URL}${endpoints.refreshToken}`;

  try {
    const response = await axios.post(url, {
      refresh: token?.refreshToken,
    });
    const newAccessToken = response?.data?.access || null;

    if (!newAccessToken) {
      clearData();
      return null;
    }

    const decoded = decodeAccessToken(newAccessToken);

    const expirationToken = decoded?.exp;

    useAuthStore.getState().setToken({
      accessToken: newAccessToken,
      refreshToken: response?.data?.refresh,
      exp: expirationToken,
    });

    return newAccessToken;
  } catch (error) {
    clearData();
  }
};
