import { useEffect, useState } from "react";
import { AssignmentType } from "../../../components/assignment-modal/AssignmentModal.interface";
import OverviewHeader from "../../../components/overview-header/OverviewHeader";
import FlexTable from "../../../components/table/FlexTable";
import { MOBILEPHONE_TABLE_COLUMNS } from "../../../constants/tableColumns";
import { Mobilephone } from "../../../models/Mobilephone";
import { READ_MOBILEPHONES_URL } from "../../../services/apiRoutes";
import getItems from "../../../services/getItems";

/**
 * MobilephoneOverview component displays an overview of mobile phone records.
 * It fetches mobile phone data and presents it in a table with action buttons.
 *
 * @component
 * @returns {JSX.Element} A layout containing the overview header and a flexible table with mobile phone data.
 */
const MobilephoneOverview = () => {
  const assetType = "mobilephone";
  const [items, setItems] = useState<Mobilephone[]>([]);

  /**
   * Fetches mobile phone data from the API and updates the state with the retrieved items.
   * This effect runs once when the component mounts.
   */
  useEffect(() => {
    (async () => {
      const data = await getItems<Mobilephone[]>(READ_MOBILEPHONES_URL);
      if (data) {
        setItems(data);
      }
    })();
  }, []);

  return (
    <>
      <OverviewHeader title="Mobiltelefone" assetType={assetType} />

      <FlexTable
        columns={MOBILEPHONE_TABLE_COLUMNS}
        rows={items}
        assetType={assetType}
        showAssignments
        hasActionButtons
        actionButtonRowProps={{
          assetType: assetType,
          hasManageAssignmentsAction: true,
          assignmentOptions: [
            { type: AssignmentType.EMPLOYEE, multiple: false },
            { type: AssignmentType.CONTRACT, multiple: true },
          ],
          hasQrCodeAction: true,
          hasExportAction: true,
          hasDeleteAction: true,
        }}
      />
    </>
  );
};
export default MobilephoneOverview;
