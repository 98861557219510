import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FlexButton from "../../../components/button/FlexButton";
import { ButtonVariant } from "../../../components/button/FlexButton.interface";
import FormComponent from "../../../components/form/FormComponent";
import { Mobilephone } from "../../../models/Mobilephone";
import { READ_MOBILEPHONE_URL } from "../../../services/apiRoutes";
import getItem from "../../../services/getItem";
import "../../../styles/Details.css";
import {
  handleInputChange,
  handleSaveChanges,
  initialData,
  validateFields,
} from "./mobilephoneDetails.utils";
import sectionsData from "./mobilephoneInputFields.json";

const MobilephoneDetail: React.FC = () => {
  const assetType = "mobilephone";
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const [mobilephone, setMobilephone] = useState<Mobilephone | null>(null);
  const [editedMobilephone, setEditedMobilephone] =
    useState<Mobilephone>(initialData);

  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isCreationMode] = useState<boolean>(
    location.pathname.includes("/create")
  );
  const [errors, setErrors] = useState<Set<string>>(new Set());

  useEffect(() => {
    const fetchMobilephone = async () => {
      if (id && !isCreationMode) {
        const data = await getItem<Mobilephone>(READ_MOBILEPHONE_URL, id);
        if (data) {
          setMobilephone(data);
          setEditedMobilephone({
            ...data,
          });
        } else {
          navigate("*", { replace: true });
        }
      }
    };

    fetchMobilephone();
  }, [id, isCreationMode]);

  const sections = sectionsData.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      ...field,
      value: editedMobilephone[field.id as keyof Mobilephone], // Make sure the typing aligns with your state.
      handleInputChange: ({
        id,
        value,
      }: {
        id: string;
        value: string | number | boolean;
      }) => {
        handleInputChange(id, value, setEditedMobilephone, setErrors);
      },
    })),
  }));

  if (!mobilephone && !isCreationMode) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="flex flex-between align-items-center">
        <h1 className="details-heading">
          {isCreationMode ? "Neu erstellen" : "Details"}{" "}
        </h1>
        {!isEditMode && !isCreationMode ? (
          <FlexButton
            id="edit-mobilephone-button"
            variant={ButtonVariant.TEXT_OUTLINED}
            title={"Bearbeiten"}
            onClick={() => setIsEditMode(true)}
          />
        ) : (
          <FlexButton
            id="save-mobilephone-button"
            variant={ButtonVariant.TEXT_FILLED}
            title={"Speichern"}
            onClick={() => {
              const areRequiredFieldsFilled = validateFields(
                editedMobilephone,
                setErrors
              );
              handleSaveChanges(
                areRequiredFieldsFilled,
                editedMobilephone,
                () => navigate(`/${assetType}`),
                isCreationMode,
                id
              );
              setIsEditMode(false);
            }}
          ></FlexButton>
        )}
      </div>

      <FormComponent
        sections={sections}
        editMode={isEditMode || isCreationMode}
        errors={errors}
      ></FormComponent>
    </>
  );
};

export default MobilephoneDetail;
