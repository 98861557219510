import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { Token, AuthState } from "./interfaces";

/**
 * useAuthStore - Zustand store for managing authentication state.
 *
 * This store persists the authentication tokens and CSRF token across sessions.
 * It provides methods to set and clear tokens, as well as manage authentication state.
 *
 * @returns {Object} - The authentication state and methods to interact with it.
 * @returns {Token|null} token - The current authentication token stored.
 * @returns {string|null} csrfToken - The current CSRF token stored.
 * @returns {Function} setToken - Function to set or update the authentication token.
 * @returns {Function} setCsrfToken - Function to set or update the CSRF token.
 * @returns {Function} clearAuthData - Function to clear both token and CSRF token.
 */
export const useAuthStore = create<AuthState>()(
  persist(
    (set) => ({
      token: null,
      csrfToken: null,
      setToken: (token: Partial<Token>) =>
        set((state) => ({
          token: {
            ...state.token,
            ...token,
          } as Token,
        })),
      setCsrfToken: (csrfToken) => set({ csrfToken }),
      clearAuthData: () => set({ token: null, csrfToken: null }),
    }),
    {
      name: "auth-storage",
      storage: createJSONStorage(() => localStorage),
    }
  )
);
