import { createRoot } from "react-dom/client";
import { toast } from "react-toastify";
import { axiosCall } from "../api/config/axiosCall";
import FlexButton from "../components/button/FlexButton";
import { ButtonColor } from "../components/button/FlexButton.interface";
import MyModal from "../components/modal/MyModal";

/**
 * Deletes an item by sending a DELETE request to the specified URL with the given item ID.
 *
 * @template T - The type of the response data expected from the DELETE request.
 * @param {string} url - The base URL to which the DELETE request is sent.
 * @param {number} itemId - The ID of the item to be deleted.
 * @returns {Promise<boolean>} A promise that resolves to `true` if the item was successfully deleted,
 * or `false` if the deletion was canceled or an error occurred.
 */
const deleteItem = async <T,>(
  url: string,
  itemId: string,
  showConfirmationModal: boolean = true,
  showToast: boolean = true,
  toastMessage?: string
): Promise<boolean> => {
  const handleDelete = async (): Promise<boolean> => {
    try {
      const response = await axiosCall({
        endpoint: `${url}${itemId}`,
        method: "DELETE",
      });

      if (response?.status === 204) {
        if (showToast) {
          toast.success(
            toastMessage ? toastMessage : "Element erfolgreich gelöscht"
          );
        }
        return true;
      } else {
        toast.error("Fehler beim Löschen des Elements");
        return false;
      }
    } catch (error) {
      toast.error("Fehler beim Löschen des Elements");
      return false;
    }
  };

  // If specified, the modal is not displayed.
  if (!showConfirmationModal) {
    return handleDelete();
  }

  // Otherwise: If not specified, the confirmation modal is displayed by default.
  return new Promise((resolve) => {
    const modalRoot = document.createElement("div");
    document.body.appendChild(modalRoot);
    const root = createRoot(modalRoot);

    const closeModal = () => {
      root.unmount();
      document.body.removeChild(modalRoot);
    };

    const onConfirm = async () => {
      closeModal();
      const result = await handleDelete();
      resolve(result);
    };

    const onCancel = () => {
      closeModal();
      resolve(false);
    };

    root.render(
      <MyModal
        isOpen={true}
        onClose={onCancel}
        title="Löschen bestätigen"
        buttons={
          <FlexButton
            id="delete-confirmation-modal-confirm-button"
            color={ButtonColor.CAUTION}
            title="Löschen"
            onClick={onConfirm}
          />
        }
      />
    );
  });
};
export default deleteItem;
