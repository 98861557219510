import { useEffect, useState } from "react";
import { AssignmentType } from "../../../components/assignment-modal/AssignmentModal.interface";
import OverviewHeader from "../../../components/overview-header/OverviewHeader";
import FlexTable from "../../../components/table/FlexTable";
import { ROOM_TABLE_COLUMNS } from "../../../constants/tableColumns";
import { Room } from "../../../models/Room";
import { READ_ROOMS_URL } from "../../../services/apiRoutes";
import getItems from "../../../services/getItems";

/**
 * RoomOverview component provides an overview of room records.
 * It fetches room data from an API and displays it in a table format,
 * complete with action buttons for managing the rooms.
 *
 * @component
 * @returns {JSX.Element} A layout containing an overview header and a table of room data.
 */
const RoomOverview = () => {
  const assetType = "room";
  const [items, setItems] = useState<Room[]>([]);

  /**
   * Fetches room data from the API when the component mounts.
   * Updates the state with the retrieved items.
   */
  useEffect(() => {
    (async () => {
      const data = await getItems<Room[]>(READ_ROOMS_URL);
      if (data) {
        setItems(data);
      }
    })();
  }, []);

  return (
    <>
      <OverviewHeader title="Räume" assetType={assetType} />

      <FlexTable
        columns={ROOM_TABLE_COLUMNS}
        rows={items}
        assetType={assetType}
        showAssignments
        hasActionButtons
        actionButtonRowProps={{
          assetType: assetType,
          hasManageAssignmentsAction: true,
          assignmentOptions: [
            { type: AssignmentType.EMPLOYEE, multiple: true },
            { type: AssignmentType.ACCESSORY, multiple: true },
            { type: AssignmentType.DOORACCESS, multiple: true },
            { type: AssignmentType.LITERATURE, multiple: true },
            { type: AssignmentType.NETWORK_DEVICE, multiple: true },
            { type: AssignmentType.SERVER, multiple: true },
            { type: AssignmentType.BUILDING, multiple: true },
            { type: AssignmentType.TABLE, multiple: true },
          ],
          hasQrCodeAction: false,
          hasExportAction: true,
          hasDeleteAction: true,
        }}
      />
    </>
  );
};

export default RoomOverview;
