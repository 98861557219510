import { useEffect, useState } from "react";
import { AssignmentType } from "../../../components/assignment-modal/AssignmentModal.interface";
import OverviewHeader from "../../../components/overview-header/OverviewHeader";
import FlexTable from "../../../components/table/FlexTable";
import { LITERATURE_TABLE_COLUMNS } from "../../../constants/tableColumns";
import { Literature } from "../../../models/Literature";
import { READ_LITERATURES_URL } from "../../../services/apiRoutes";
import getItems from "../../../services/getItems";

/**
 * LiteratureOverview component displays an overview of literature records.
 * It fetches literature data and presents it in a table format with action buttons.
 *
 * @component
 * @returns {JSX.Element} A layout containing the overview header and a flexible table with literature data.
 */
const LiteratureOverview = () => {
  const assetType = "literature";
  const [items, setItems] = useState<Literature[]>([]);

  /**
   * Fetches literature data from the API and updates the state with the fetched items.
   * This effect runs once when the component mounts.
   */
  useEffect(() => {
    (async () => {
      const data = await getItems<Literature[]>(READ_LITERATURES_URL);
      if (data) {
        setItems(data);
      }
    })();
  }, []);

  return (
    <>
      <OverviewHeader title="Literatur" assetType={assetType} />

      <FlexTable
        columns={LITERATURE_TABLE_COLUMNS}
        rows={items}
        assetType={assetType}
        showAssignments
        hasActionButtons
        actionButtonRowProps={{
          assetType: assetType,
          hasManageAssignmentsAction: true,
          assignmentOptions: [
            { type: AssignmentType.EMPLOYEE, multiple: false },
            { type: AssignmentType.ROOM, multiple: false },
          ],
          hasQrCodeAction: true,
          hasExportAction: true,
          hasDeleteAction: true,
        }}
      />
    </>
  );
};

export default LiteratureOverview;
