import { OnOffboardingBE } from "../../models/OnOffboarding";

/** This helper function transforms the on/offboarding object to fit the frontend needs.
 * @param data On/Offboarding object that comes from backend.
 * @return On/Offboarding object formatted in the way we need in frontend.
 */
export const transformData = (data: OnOffboardingBE[]) => {
  return data.map((item) => ({
    id: item.profile.id,
    first_name: item.first_name,
    last_name: item.last_name,
    start_date: new Date(item.profile.start_date),
    end_date:
      item.profile.end_date !== null ? new Date(item.profile.end_date) : null,
    departments: item.profile.departments ? item.profile.departments.name : "",
  }));
};
