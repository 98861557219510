import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FlexButton from "../../../components/button/FlexButton";
import { ButtonVariant } from "../../../components/button/FlexButton.interface";
import FormComponent from "../../../components/form/FormComponent";
import { Dooraccess } from "../../../models/Dooraccess";
import { READ_DOORACCESS_URL } from "../../../services/apiRoutes";
import getItem from "../../../services/getItem";
import "../../../styles/Details.css";
import {
  handleInputChange,
  handleSaveChanges,
  initialData,
  validateFields,
} from "./dooraccessDetails.utils";
import sectionsData from "./dooraccessInputFields.json";

const DooraccessDetail: React.FC = () => {
  const assetType = "dooraccess";
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const [dooraccess, setDooraccess] = useState<Dooraccess | null>(null);
  const [editedDooraccess, setEditedDooraccess] =
    useState<Dooraccess>(initialData);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isCreationMode] = useState<boolean>(
    location.pathname.includes("/create")
  );
  const [errors, setErrors] = useState<Set<string>>(new Set());

  useEffect(() => {
    const fetchDooraccess = async () => {
      if (id && !isCreationMode) {
        const data = await getItem<Dooraccess>(READ_DOORACCESS_URL, id);
        if (data) {
          setDooraccess(data);
          setEditedDooraccess({
            ...data,
          });
        } else {
          navigate("*", { replace: true });
        }
      }
    };

    fetchDooraccess();
  }, [id, isCreationMode]);

  const sections = sectionsData.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      ...field,
      value: editedDooraccess[field.id as keyof Dooraccess],
      handleInputChange: ({
        id,
        value,
      }: {
        id: string;
        value: string | number | boolean;
      }) => {
        handleInputChange(id, value, setEditedDooraccess, setErrors);
      },
    })),
  }));

  if (!dooraccess && !isCreationMode) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="flex flex-between align-items-center">
        <h1 className="details-heading">
          {isCreationMode ? "Neu erstellen" : "Details"}
        </h1>
        {!isEditMode && !isCreationMode ? (
          <FlexButton
            id="edit-door-access-button"
            variant={ButtonVariant.TEXT_OUTLINED}
            title={"Bearbeiten"}
            onClick={() => setIsEditMode(true)}
          />
        ) : (
          <FlexButton
            id="save-door-access-button"
            variant={ButtonVariant.TEXT_FILLED}
            title={"Speichern"}
            onClick={() => {
              const areRequiredFieldsFilled = validateFields(
                editedDooraccess,
                setErrors
              );
              handleSaveChanges(
                areRequiredFieldsFilled,
                editedDooraccess,
                () => navigate(`/${assetType}`),
                isCreationMode,
                id
              );
              setIsEditMode(false);
            }}
          ></FlexButton>
        )}
      </div>

      <FormComponent
        sections={sections}
        editMode={isEditMode || isCreationMode}
        errors={errors}
      ></FormComponent>
    </>
  );
};

export default DooraccessDetail;
