import {
  Box,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import { BASE_URL } from "../../services/apiRoutes";
import getItems from "../../services/getItems";
import FlexButton from "../button/FlexButton";
import MyModal from "../modal/MyModal";
import {
  Assignment,
  AssignmentModalProps,
  AssignmentType,
  AssignmentTypeDetails,
  Option,
  SelectedAssignment,
} from "./AssignmentModal.interface";
import { useStyle } from "./AssignmentModal.styles";
import { handleOptionChange, handleSave } from "./AssignmentModal.utils";

/**
 * Modal for assigning assets/employees. Will be replaced with ziings 2.0
 */
const AssignmentModal = (props: AssignmentModalProps) => {
  const style = useStyle();
  const [assignments, setAssignments] = useState<Assignment[]>([]);
  const [selectedAssignments, setSelectedAssignments] = useState<
    SelectedAssignment[]
  >([]);

  useEffect(() => {
    (async () => {
      // Put all assignment types with all its options in here.
      const tempAssignments: Assignment[] = [];

      // Get all assigned items to the current asset (can be null or multiple).
      const assignedItemsUrl = `${BASE_URL}/retrieve/${props.assetType}/${props.id}/`;
      const assignedItems = await getItems<any>(assignedItemsUrl);

      // For each dropdown.
      for (const assignmentOption of props.assignmentOptions) {
        const assignmentType = assignmentOption.type;

        const items = await getItems<any[]>(
          AssignmentTypeDetails[assignmentOption.type].url
        );

        if (items) {
          // If there is something in assignedItems:
          // Filter for assigned items to this assetType (can be null or multiple).
          let assignedOptions = assignedItems
            ? assignedItems.assignments.filter(
                (assignment: any) =>
                  assignment.assetType ===
                  AssignmentTypeDetails[assignmentType].key
              )
            : [];

          // If that was successfull: Format the items/options.
          if (assignedOptions.length > 0) {
            assignedOptions = assignedOptions.map((option: Option) => ({
              id: option.id,
              label: option.label,
            }));
          }

          // Form the rest of the options to the correct object.
          const options = items.map((item) => {
            return {
              id: item.id,
              label:
                assignmentType === AssignmentType.EMPLOYEE
                  ? `${item.first_name} ${item.last_name}`
                  : item.identifier,
            };
          });

          // Form the correct assignment object.
          tempAssignments.push({
            label: AssignmentTypeDetails[assignmentType].displayName,
            type: AssignmentTypeDetails[assignmentType].key,
            multiple: assignmentOption.multiple,
            options: options,
            selectedOptions: assignedOptions,
          });
        }
      }

      setAssignments(tempAssignments);
    })();
  }, []);

  return (
    // Modal >open< can always be true. Parent component doesnt render if not needed.
    <MyModal
      title="Zuweisungen bearbeiten"
      isOpen={true}
      onClose={props.onClose}
      buttons={
        <Box sx={style.buttonGroup}>
          <FlexButton
            id="assignment-modal-save-button"
            title="Speichern"
            onClick={() =>
              handleSave(
                assignments,
                selectedAssignments,
                props.assetType,
                props.id,
                props.onClose,
                props.onSave
              )
            }
          />
        </Box>
      }
      children={
        <Box sx={style.form}>
          {assignments.map((currentAssignment) => (
            <FormControl
              style={style.input}
              key={currentAssignment.label}
              fullWidth
            >
              <>
                <InputLabel id={currentAssignment.label}>
                  {currentAssignment.label}
                </InputLabel>
                <Select
                  sx={{ width: "100%", maxWidth: "100%", height: "50px" }}
                  multiple={currentAssignment.multiple}
                  labelId={currentAssignment.label}
                  value={(() => {
                    // These are the selected values.
                    // If available: Use freshly updated >selectedAssignments<.
                    // Else: Use selectedOptions from >item< (database).
                    // Either way: Map an array with the ids only.
                    const selectedAssignment = selectedAssignments.find(
                      (assignment) =>
                        assignment.label === currentAssignment.label
                    );

                    return selectedAssignment
                      ? selectedAssignment.selectedOptions.map((opt) => opt.id)
                      : currentAssignment.selectedOptions?.map((opt) => opt.id);
                  })()}
                  renderValue={(selected) => {
                    // These renders the selected values, aka the correct label.
                    // >(selected)< comes from >value< above, where the selected ids are defined.
                    // Now find the label to the id.
                    const selectedOptions = currentAssignment.options.filter(
                      (option) => selected.includes(option.id)
                    );
                    return selectedOptions
                      .map((option) => option.label)
                      .join(", ");
                  }}
                  onChange={(event) =>
                    handleOptionChange(
                      currentAssignment,
                      event,
                      setSelectedAssignments
                    )
                  }
                  input={<OutlinedInput label={currentAssignment.label} />}
                >
                  {!currentAssignment.multiple && (
                    <MenuItem value="">
                      <em>Nicht zugewiesen</em>
                    </MenuItem>
                  )}
                  {currentAssignment.options.length !== 0 ? (
                    currentAssignment.options.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {currentAssignment.multiple && (
                          <Checkbox
                            checked={(() => {
                              // Same logic as for >value<. Except not mapping for ids but for comparison.
                              const selectedAssignment =
                                selectedAssignments.find(
                                  (assignment) =>
                                    assignment.label === currentAssignment.label
                                );

                              return selectedAssignment
                                ? selectedAssignment.selectedOptions.some(
                                    (opt) => opt.id === option.id
                                  )
                                : currentAssignment.selectedOptions?.some(
                                    (opt) => opt.id === option.id
                                  );
                            })()}
                          />
                        )}
                        <ListItemText>{option.label}</ListItemText>
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>
                      Keine {currentAssignment.label} vorhanden
                    </MenuItem>
                  )}
                </Select>
              </>
            </FormControl>
          ))}
        </Box>
      }
    />
  );
};

export default AssignmentModal;
