import React, { Dispatch } from "react";
import { toast } from "react-toastify";
import { Monitor } from "../../../models/Monitor";
import {
  CREATE_MONITOR_URL,
  UPDATE_MONITOR_URL,
} from "../../../services/apiRoutes";
import createItem from "../../../services/createItem";
import updateItem from "../../../services/updateItem";
import sectionsData from "./monitorInputFields.json";

export const initialData = {
  aspectRatio: "",
  purchase_date: null,
  purchase_price: 0,
  bill_number: "",
  curved: false,
  heightAdjustable: false,
  identifier: "",
  manufacturer: "",
  model: "",
  order_number: "",
  resolution: "",
  serialnumber: "",
  size: 0,
  comment: "",
  inventory_number: "",
  inventory_status: 0,
  inventory_status_details: {
    id: null,
    name: null,
  },
  inventory_type: 0,
  inventory_type_details: {
    id: null,
    name: null,
  },
  last_inventoried: null,
};

/**
 * Handles input change events and clears validation indicators after
 * a required input isn't empty anymore.
 * @param id Input field id.
 * @param value New Value of input field.
 * @param setEditedMonitor Setter for >editedMonitor<
 * @param setErrors Setter for >errors<
 */
export const handleInputChange = (
  id: string,
  value: any,
  setEditedMonitor: Dispatch<React.SetStateAction<Monitor>>,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>,
) => {
  if (id === "purchase_price" && typeof value === "string") {
    value = value.replace(",", ".");
  }

  setEditedMonitor((prevData) => ({
    ...prevData,
    [id]: value,
  }));

  // If input changes to valid: Remove error.
  setErrors((prevErrors) => {
    const newErrors = new Set(prevErrors);
    if (value) {
      newErrors.delete(id);
    }
    return newErrors;
  });
};

/**
 * If all required fields are filled: Handles the save action.
 * Either updates an existing item or creates a new one.
 * @param areRequiredFieldsFilled True if all required fields are filled.
 * @param editedMonitor Monitor item that gets edited.
 * @param navigateCallback
 * @param isNew True if it is the creation of a new item.
 * @param id Monitor id.
 */
export const handleSaveChanges = async (
  areRequiredFieldsFilled: boolean,
  editedMonitor: Monitor,
  navigateCallback: () => void,
  isNew: boolean,
  id?: string,
) => {
  // Save only if required fields aren't empty.
  if (areRequiredFieldsFilled) {
    if (editedMonitor.purchase_date === "") {
      editedMonitor.purchase_date = null;
    }
    editedMonitor.inventory_status = editedMonitor.inventory_status_details
      ? editedMonitor.inventory_status_details.id
      : null;
    editedMonitor.inventory_type = editedMonitor.inventory_type_details
      ? editedMonitor.inventory_type_details.id
      : null;
    if (isNew) {
      await createItem<Monitor>(CREATE_MONITOR_URL, editedMonitor);
      // After creating a new element, go back to overview page.
      navigateCallback();
    } else if (id) {
      const { qr_code, ...newEditedMonitor } = editedMonitor;
      await updateItem<Monitor>(UPDATE_MONITOR_URL, id, newEditedMonitor);
    }
  } else {
    toast.error("Es sind noch nicht alle Pflichtfelder ausgefüllt.");
  }
};

/**
 * Checks if all required fields are filled.
 * @param editedMonitor Monitor item that gets edited.
 * @param setErrors Setter for >errors<
 * @returns TRUE: All required fields are filled. FALSE: Some required fields are empty.
 */
export const validateFields = (
  editedMonitor: Monitor,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>,
) => {
  const newErrors = new Set<string>();

  sectionsData.forEach((section) => {
    section.fields.forEach((field) => {
      const value = editedMonitor[field.id as keyof Monitor];

      if ("required" in field && field.required && !value) {
        newErrors.add(field.id);
      }
    });
  });

  setErrors(newErrors);
  return newErrors.size === 0;
};
