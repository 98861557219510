export const useStyle = () => {
  return {
    page: {
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      width: "100%",
    },
    container: {
      paddingLeft: "5%",
      paddingRight: "5%",
      width: {
        xs: "100%",
        sm: "70%",
        md: "40%",
      },
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      display: "flex",
      gap: 6,
    },

    logo: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },

    logo_img: {
      width: "32px",
    },

    logo_name: {
      color: "#40A9BF",
      fontWeight: "500",
      margin: 0,
      padding: 0,
    },
  };
};
