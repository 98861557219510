import { useEffect, useState } from "react";
import { AssignmentType } from "../../../components/assignment-modal/AssignmentModal.interface";
import OverviewHeader from "../../../components/overview-header/OverviewHeader";
import FlexTable from "../../../components/table/FlexTable";
import { LANDLINE_TABLE_COLUMNS } from "../../../constants/tableColumns";
import { Landline } from "../../../models/Landline";
import { READ_LANDLINES_URL } from "../../../services/apiRoutes";
import getItems from "../../../services/getItems";

/**
 * LandlineOverview component displays an overview of landline phone records.
 * It fetches landline data and displays it in a table with action buttons.
 *
 * @component
 * @returns {JSX.Element} A layout containing the overview header and a flexible table with landline data.
 */
const LandlineOverview = () => {
  const assetType = "landline";
  const [items, setItems] = useState<Landline[]>([]);

  /**
   * Fetches landline data from the API and updates the items state.
   */
  useEffect(() => {
    (async () => {
      const data = await getItems<Landline[]>(READ_LANDLINES_URL);
      if (data) {
        setItems(data);
      }
    })();
  }, []);

  return (
    <>
      <OverviewHeader title="Festnetztelefone" assetType={assetType} />

      <FlexTable
        columns={LANDLINE_TABLE_COLUMNS}
        rows={items}
        assetType={assetType}
        showAssignments
        hasActionButtons
        actionButtonRowProps={{
          assetType: assetType,
          hasManageAssignmentsAction: true,
          assignmentOptions: [
            { type: AssignmentType.EMPLOYEE, multiple: true },
            { type: AssignmentType.LANDLINE_CONTRACT, multiple: false },
            { type: AssignmentType.ROOM, multiple: false },
          ],
          hasQrCodeAction: true,
          hasExportAction: true,
          hasDeleteAction: true,
        }}
      />
    </>
  );
};

export default LandlineOverview;
