import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EastRoundedIcon from "@mui/icons-material/EastRounded";
import ExportIcon from "@mui/icons-material/GetApp";
import LinkIcon from "@mui/icons-material/Link";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import { Dispatch, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../../../services/apiRoutes";
import deleteItem from "../../../../services/deleteItem";
import useQRCodeStore from "../../../../stores/useQRCodeStore";
import { ButtonGroupOptions } from "../../../button-group/ButtonGroup.interface";
import { getQrCodeLabel } from "../../../qr-code-collector/QrCodeCollector.utils";
import { ActionButtonRowProps } from "../../FlexTable.interface";

/**
 * Custom Hook to generate an array of action button options for the ButtonGroup based on
 * provided properties and actions. This function constructs the options
 * dynamically, allowing the action of adding qr code, exporting file, deleting item
 * and navigating to a specific route.
 * @param actionButtonRowProps
 * @param rowId
 * @param rowIdentifier
 * @param setRows (optional)
 * @param openManageAssignmentsModal (optional)
 * @param openExportModal (optional)
 * @returns Array of row action buttons.
 */
export const useActionButtonOptions = (
  actionButtonRowProps: ActionButtonRowProps,
  rowId: string,
  rowIdentifier: string,
  setRows?: Dispatch<React.SetStateAction<any>>,
  openManageAssignmentsModal?: (id: string) => void,
  openExportModal?: (rowId: string) => void
): ButtonGroupOptions => {
  const { createAddQrCode } = useAddQrCode();
  const { createRemoveQrCode } = useRemoveQrCode();
  const navigate = useNavigate();

  return useMemo(() => {
    const group: ButtonGroupOptions = [];

    // If wanted: Add manage assignments action to the action button options.
    if (
      actionButtonRowProps.hasManageAssignmentsAction &&
      openManageAssignmentsModal
    ) {
      group.push({
        id: `row-${rowId}-assignment-action-button`,
        icon: <LinkIcon />,
        onClick: () => openManageAssignmentsModal(rowId),
      });
    }

    // If wanted: Add add qr code action to the action button options.
    if (actionButtonRowProps.hasQrCodeAction) {
      group.push({
        id: `row-${rowId}-qr-code-action-button`,

        icon: <QrCode2Icon />,
        onClick: () => {
          createAddQrCode(actionButtonRowProps.assetType, rowId, rowIdentifier);
        },
      });
    }

    // If wanted: Add export action to the action button options.
    if (actionButtonRowProps.hasExportAction && openExportModal) {
      group.push({
        id: `row-${rowId}-export-action-button`,
        icon: <ExportIcon />,
        onClick: () => openExportModal(rowId),
      });
    }

    // If wanted: Add delete action to the action button options.
    if (actionButtonRowProps.hasDeleteAction && setRows) {
      const url = `${BASE_URL}/${actionButtonRowProps.assetType}/`;
      group.push({
        id: `row-${rowId}-delete-action-button`,
        icon: <DeleteOutlineIcon />,
        onClick: async () => {
          // First: Delete item.
          const deleted = await deleteItem(url, rowId);
          // If deleted:
          if (deleted) {
            // Update rows.
            setRows((prevRows: any) => {
              return prevRows.filter((row: any) => row.id !== rowId);
            });
            // Remove from QR code collector.
            if (actionButtonRowProps.hasQrCodeAction)
              createRemoveQrCode(actionButtonRowProps.assetType, rowId);
          }
        },
      });
    }

    // Always add view action to the action button options.
    group.push({
      id: `row-${rowId}-view-action-button`,
      icon: <EastRoundedIcon />,
      onClick: () => navigate(`/${actionButtonRowProps.assetType}/${rowId}`),
    });

    return group;
  }, [
    actionButtonRowProps,
    rowId,
    rowIdentifier,
    navigate,
    setRows,
    openManageAssignmentsModal,
    openExportModal,
    createAddQrCode,
  ]);
};

/**
 * A custom hook for adding QR codes.
 *
 * This hook provides a function to add QR codes for specific assets.
 * It utilizes the `useQRCodeStore` to access the `addQrCode` functionality
 * and wraps it in a memoized callback for optimal performance.
 *
 * @returns An object containing the `createAddQrCode` function.
 * @property {Function} createAddQrCode - A memoized function to create a QR code for an asset.
 */
const useAddQrCode = () => {
  const addQrCode = useQRCodeStore((state) => state.addQrCode);
  const createAddQrCode = useCallback(
    (assetType: string, rowId: string, rowIdentifier: string) => {
      return addQrCode(
        assetType,
        rowId,
        getQrCodeLabel(assetType, rowIdentifier)
      );
    },
    [addQrCode]
  );
  return { createAddQrCode };
};

/**
 * A custom hook for removing QR codes associated.
 *
 * This hook provides a function to remove QR codes for specific assets.
 * It utilizes the `useQRCodeStore` to access the `removeQrCode` functionality
 * and wraps it in a memoized callback for optimal performance.
 *
 * @returns An object containing the `createRemoveQrCode` function.
 * @property {Function} createRemoveQrCode - A memoized function to remove a QR code for an asset.
 */
const useRemoveQrCode = () => {
  const removeQrCode = useQRCodeStore((state) => state.removeQrCode);
  const createRemoveQrCode = useCallback(
    (assetType: string, rowId: string) => {
      return removeQrCode(assetType, rowId);
    },
    [removeQrCode]
  );
  return { createRemoveQrCode };
};
