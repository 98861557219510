import { useState } from "react";
import { toast } from "react-toastify";
import { PasswordFormValues } from "../../../pages/profile/Profile.interface";
import { endpoints } from "../../config/api-endpoints";
import { axiosCall } from "../../config/axiosCall";
import { useAuthStore } from "../../../stores/auth/useAuthStore";

/**
 * useChangePassword - Custom hook for handling user changePassword logic.
 *
 * This hook provides the `changePassword` function which is used to handle the changePassword process,
 * including setting the authentication token and user data.
 *
 * @returns {Object} - An object containing the `changePassword` function.
 * @returns {Function} changePassword - A function that takes user changePassword data and
 * updates it.
 */
export const useChangePassword = () => {
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  // Access setToken from the auth store to update token state
  const setToken = useAuthStore((state) => state.setToken);

  /**
   * changePassword - Handles the changePassword process by setting the authentication token and user data.
   *
   * @param {Object} data - The changePassword data, typically including credentials like email and password.
   * @param {string} data.password - The authentication token to be set in the auth store.
   * @param {string} data.newPassword - The user data to be set in the user store.
   * @param {string} data.newPasswordConfirm - The user data to be set in the user store.
   *
   * @returns {Promise<void>} - A promise that resolves when the changePassword process is complete.
   */
  const changePassword = async (passwordFormValues: PasswordFormValues) => {
    try {
      setError(null);
      setLoading(true);

      const response = await axiosCall({
        endpoint: endpoints.changePassword,
        method: "POST",
        data: {
          current_password: passwordFormValues.oldPassword,
          new_password: passwordFormValues.newPassword,
        },
      });

      if (response?.status === 200) {
        toast.success(
          response?.data?.message || "Passwort erfolgreich geändert!"
        );
        const { access, refresh } = response.data;
        if (access && refresh) {
          setToken({
            accessToken: access,
            refreshToken: refresh,
          });
        }
      }
    } catch (error: any) {
      if (error?.errors) {
        const errorMessages = error?.errors;

        errorMessages.map((errorMessage: string) => {
          return toast.error(errorMessage);
        });
      } else {
        toast.error("Fehler beim Ändern des Passworts");
      }
    } finally {
      setLoading(false);
    }
  };

  return { changePassword, error, loading };
};
