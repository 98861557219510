// TODO: delete this file and replace with stc/api/api-endpoints.ts
export const BASE_URL =
  process.env.REACT_APP_BASE_URL || "http://localhost:9091/de/api";
export const CREATE_EMPLOYEE_URL = `${BASE_URL}/employee/`;
export const READ_EMPLOYEES_URL = `${BASE_URL}/employee`;
export const READ_EMPLOYEE_URL = `${BASE_URL}/employee`;
export const DELETE_EMPLOYEE_URL = `${BASE_URL}/employee`;
export const UPDATE_EMPLOYEE_URL = `${BASE_URL}/employee`;
export const GET_UNASSIGNED_RESOURCES_URL = `${BASE_URL}/getUnassignedResources`;
export const GET_RESOURCE_BY_EMPLOYEE = `${BASE_URL}/getResourceByEmployee/`;
export const CREATE_BUILDING_URL = `${BASE_URL}/building/`;
export const READ_BUILDING_URL = `${BASE_URL}/building`;
export const READ_BUILDINGS_URL = `${BASE_URL}/building`;
export const DELETE_BUILDING_URL = `${BASE_URL}/building`;
export const UPDATE_BUILDING_URL = `${BASE_URL}/building`;
export const CREATE_COMPUTER_URL = `${BASE_URL}/computer/`;
export const READ_COMPUTERS_URL = `${BASE_URL}/computer`;
export const READ_COMPUTER_URL = `${BASE_URL}/computer`;
export const DELETE_COMPUTER_URL = `${BASE_URL}/computer`;
export const UPDATE_COMPUTER_URL = `${BASE_URL}/computer`;
export const CREATE_MONITOR_URL = `${BASE_URL}/monitor/`;
export const READ_MONITOR_URL = `${BASE_URL}/monitor`;
export const READ_MONITORS_URL = `${BASE_URL}/monitor`;
export const DELETE_MONITOR_URL = `${BASE_URL}/monitor`;
export const UPDATE_MONITOR_URL = `${BASE_URL}/monitor`;
export const CREATE_LICENSE_URL = `${BASE_URL}/license/`;
export const READ_LICENSE_URL = `${BASE_URL}/license`;
export const READ_LICENSES_URL = `${BASE_URL}/license`;
export const DELETE_LICENSE_URL = `${BASE_URL}/license`;
export const UPDATE_LICENSE_URL = `${BASE_URL}/license`;
export const CREATE_LOCATION_URL = `${BASE_URL}/location/`;
export const READ_LOCATION_URL = `${BASE_URL}/location`;
export const READ_LOCATIONS_URL = `${BASE_URL}/location`;
export const DELETE_LOCATION_URL = `${BASE_URL}/location`;
export const UPDATE_LOCATION_URL = `${BASE_URL}/location`;
export const CREATE_MOBILEPHONE_URL = `${BASE_URL}/mobilephone/`;
export const READ_MOBILEPHONE_URL = `${BASE_URL}/mobilephone`;
export const READ_MOBILEPHONES_URL = `${BASE_URL}/mobilephone`;
export const DELETE_MOBILEPHONE_URL = `${BASE_URL}/mobilephone`;
export const UPDATE_MOBILEPHONE_URL = `${BASE_URL}/mobilephone`;
export const CREATE_TABLE_URL = `${BASE_URL}/table/`;
export const READ_TABLE_URL = `${BASE_URL}/table`;
export const READ_TABLES_URL = `${BASE_URL}/table`;
export const DELETE_TABLE_URL = `${BASE_URL}/table`;
export const UPDATE_TABLE_URL = `${BASE_URL}/table`;
export const CREATE_DOORACCESS_URL = `${BASE_URL}/dooraccess/`;
export const READ_DOORACCESS_URL = `${BASE_URL}/dooraccess`;
export const READ_DOORACCESSES_URL = `${BASE_URL}/dooraccess`;
export const DELETE_DOORACCESS_URL = `${BASE_URL}/dooraccess`;
export const UPDATE_DOORACCESS_URL = `${BASE_URL}/dooraccess`;
export const CREATE_CONTRACT_URL = `${BASE_URL}/contract/`;
export const READ_CONTRACT_URL = `${BASE_URL}/contract`;
export const READ_CONTRACTS_URL = `${BASE_URL}/contract`;
export const DELETE_CONTRACT_URL = `${BASE_URL}/contract`;
export const UPDATE_CONTRACT_URL = `${BASE_URL}/contract`;
export const CREATE_LANDLINE_URL = `${BASE_URL}/landline/`;
export const READ_LANDLINE_URL = `${BASE_URL}/landline`;
export const READ_LANDLINES_URL = `${BASE_URL}/landline`;
export const DELETE_LANDLINE_URL = `${BASE_URL}/landline`;
export const UPDATE_LANDLINE_URL = `${BASE_URL}/landline`;
export const CREATE_VIRTUALMACHINE_URL = `${BASE_URL}/virtualMachine/`;
export const READ_VIRTUALMACHINE_URL = `${BASE_URL}/virtualMachine`;
export const READ_VIRTUALMACHINES_URL = `${BASE_URL}/virtualMachine`;
export const DELETE_VIRTUALMACHINE_URL = `${BASE_URL}/virtualMachine`;
export const UPDATE_VIRTUALMACHINE_URL = `${BASE_URL}/virtualMachine`;
export const CREATE_ACCESSORY_URL = `${BASE_URL}/accessory/`;
export const READ_ACCESSORY_URL = `${BASE_URL}/accessory`;
export const READ_ACCESSORIES_URL = `${BASE_URL}/accessory`;
export const DELETE_ACCESSORY_URL = `${BASE_URL}/accessory`;
export const UPDATE_ACCESSORY_URL = `${BASE_URL}/accessory`;
export const CREATE_LANDLINECONTRACT_URL = `${BASE_URL}/landlineContract/`;
export const READ_LANDLINECONTRACT_URL = `${BASE_URL}/landlineContract`;
export const READ_LANDLINECONTRACTS_URL = `${BASE_URL}/landlineContract`;
export const DELETE_LANDLINECONTRACT_URL = `${BASE_URL}/landlineContract`;
export const UPDATE_LANDLINECONTRACT_URL = `${BASE_URL}/landlineContract`;
export const CREATE_LITERATURE_URL = `${BASE_URL}/literature/`;
export const READ_LITERATURE_URL = `${BASE_URL}/literature`;
export const READ_LITERATURES_URL = `${BASE_URL}/literature`;
export const DELETE_LITERATURE_URL = `${BASE_URL}/literature`;
export const UPDATE_LITERATURE_URL = `${BASE_URL}/literature`;
export const CREATE_NETWORKDEVICE_URL = `${BASE_URL}/networkDevice/`;
export const READ_NETWORKDEVICE_URL = `${BASE_URL}/networkDevice`;
export const READ_NETWORKDEVICES_URL = `${BASE_URL}/networkDevice`;
export const DELETE_NETWORKDEVICE_URL = `${BASE_URL}/networkDevice`;
export const UPDATE_NETWORKDEVICE_URL = `${BASE_URL}/networkDevice`;
export const CREATE_ROOM_URL = `${BASE_URL}/room/`;
export const READ_ROOM_URL = `${BASE_URL}/room`;
export const READ_ROOMS_URL = `${BASE_URL}/room`;
export const DELETE_ROOM_URL = `${BASE_URL}/room`;
export const UPDATE_ROOM_URL = `${BASE_URL}/room`;
export const CREATE_SERVER_URL = `${BASE_URL}/server/`;
export const READ_SERVER_URL = `${BASE_URL}/server`;
export const READ_SERVERS_URL = `${BASE_URL}/server`;
export const DELETE_SERVER_URL = `${BASE_URL}/server`;
export const UPDATE_SERVER_URL = `${BASE_URL}/server`;
export const READ_ONBOARDING_PEOPLE_URL = `${BASE_URL}/onboarding`;
export const READ_OFFBOARDING_PEOPLE_URL = `${BASE_URL}/offboarding`;
export const ACCESSORY_CATEGORY_OPTIONS = `${BASE_URL}/accessory-category-options`;
export const COMPUTER_TYPE_OPTIONS = `${BASE_URL}/computer-type-options`;
export const CONTRACT_DURATION_OPTIONS = `${BASE_URL}/contract-duration-options`;
export const DATA_VOLUME_UNIT_OPTIONS = `${BASE_URL}/data-volume-unit-options`;
export const DISK_TYPE_OPTIONS = `${BASE_URL}/disk-type-options`;
export const DOOR_TYPE_OPTIONS = `${BASE_URL}/door-type-options`;
export const FLOOR_OPTIONS = `${BASE_URL}/floor-options`;
export const LITERATURE_TYPE_OPTIONS = `${BASE_URL}/literature-type-options`;
export const NETWORK_DEVICE_CATEGORY_OPTIONS = `${BASE_URL}/network-device-category-options`;
export const PAYMENT_CYCLE_OPTIONS = `${BASE_URL}/payment-cycle-options`;
export const BYTE_TYPE_OPTIONS = `${BASE_URL}/byte-type-options`;
export const MEMORY_TYPE_OPTIONS = `${BASE_URL}/memory-type-options`;
export const MOBILE_NETWORK_OPTIONS = `${BASE_URL}/mobile-network-options`;
export const EMPLOYEE_DEPARTMENT_OPTIONS = `${BASE_URL}/department`;
export const EMPLOYEE_USER_ROLE_OPTIONS = `${BASE_URL}/user-role`;
export const EMPLOYEE_WORKPLACE_OPTIONS = `${BASE_URL}/workplace`;
export const GENERATE_QR_CODE_PDF_URL = `${BASE_URL}/generate-pdf/`;
export const INVENTORY_TYPE_OPTIONS = `${BASE_URL}/inventory-type-options`;
export const INVENTORY_STATUS_OPTIONS = `${BASE_URL}/inventory-status-options`;