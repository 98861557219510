import { ReactNode } from "react";
import {
  TextField,
  TextFieldProps,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Control, Controller } from "react-hook-form";

import { useStyle } from "./CustomTextField.styles";
import useTextInputVisibilityToggle from "../../hooks/useTextInputVisibilityToggle";

/**
 * CustomTextInputFieldProps - The props for the CustomTextInputField component.
 */
interface CustomTextInputFieldProps {
  control: Control<any>;
  name: string;
  label: string;
  type: TextFieldProps["type"];
  disabled?: boolean;
  error?: boolean;
  helperText?: string | undefined;
  onEnter: () => void;
  icon?: ReactNode;
  iconOnClick?: () => void;
}

/**
 * CustomTextInputField - A component that renders a text input field with optional icon, error handling,
 * and keyboard event handling, integrated with react-hook-form for state management.
 *
 * @param {Object} props - The properties used to customize the component.
 * @param {Control<any>} props.control - The control object from react-hook-form, used to manage form state for the input field.
 * @param {string} props.name - The name of the input field in the form, used by react-hook-form to link the field with form values.
 * @param {string} props.label - The label text for the input field, shown above the input box to describe its purpose.
 * @param {TextFieldProps["type"]} props.type - The type of input (e.g., "text", "password"), specifying the type of data expected.
 * @param {boolean} [props.disabled] - Optional flag to disable the input field, preventing user interaction.
 * @param {boolean} [props.error] - Indicates whether the input field contains an error. This affects the styling of the field.
 * @param {string} [props.helperText] - Optional helper text displayed below the input field, providing extra information or feedback.
 * @param {() => void} [props.onEnter] - Optional callback function triggered when the "Enter" key is pressed while focusing the input field.
 * @param {ReactNode} [props.icon] - Optional icon element to be displayed inside the input field (e.g., a visibility toggle icon).
 * @param {() => void} [props.iconOnClick] - Optional function to handle click events on the icon, if an icon is provided.
 *
 * @returns {JSX.Element} The rendered input field with integrated form control and optional icon and keyboard behavior.
 */
const CustomTextInputField: React.FC<CustomTextInputFieldProps> = ({
  control,
  name,
  label,
  type,
  disabled,
  error,
  helperText,
  onEnter,
  icon,
  iconOnClick,
}) => {
  const styles = useStyle();
  const { showInput, toggleInputVisibility } = useTextInputVisibilityToggle();

  /**
   * Handles the "Enter" key press event.
   * If the "Enter" key is pressed, and the `onEnter` callback is provided,
   * this function will prevent the default form submission behavior and trigger the `onEnter` callback.
   *
   * @param {React.KeyboardEvent<HTMLInputElement>} event - The keyboard event triggered by the user.
   */
  const handleEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && onEnter) {
      event.preventDefault();
      onEnter();
    }
  };

  /**
   * Determines and returns the icon to display in the input field's adornment.
   * For password fields, it returns either the Visibility or VisibilityOff icon based
   * on the `showInput` state. For other input types, it returns the custom `icon` provided via props, or null if no icon is given.
   *
   * @returns {ReactNode} The appropriate icon for the input adornment.
   */
  const renderAdornmentIcon = () => {
    if (type === "password") {
      return showInput ? <Visibility /> : <VisibilityOff />;
    }
    return icon || null;
  };

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      render={({ field }) => (
        <TextField
          {...field}
          label={label}
          type={type === "password" ? (showInput ? "text" : "password") : type}
          error={error}
          disabled={disabled}
          helperText={helperText}
          variant="outlined"
          style={styles.input}
          onKeyDown={handleEnter}
          // Props for customizing the input field
          InputProps={{
            endAdornment:
              icon ||
              (type === "password" && (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle input visibility"
                    onClick={
                      type === "password" ? toggleInputVisibility : iconOnClick
                    }
                    edge="end"
                    sx={styles.iconButton}
                  >
                    {renderAdornmentIcon()}
                  </IconButton>
                </InputAdornment>
              )),
          }}
        />
      )}
    />
  );
};

export default CustomTextInputField;
