import { useEffect, useState } from "react";
import { AssignmentType } from "../../../components/assignment-modal/AssignmentModal.interface";
import OverviewHeader from "../../../components/overview-header/OverviewHeader";
import FlexTable from "../../../components/table/FlexTable";
import { VIRTUALMACHINE_TABLE_COLUMNS } from "../../../constants/tableColumns";
import { Virtualmachine } from "../../../models/Virtualmachine";
import { READ_VIRTUALMACHINES_URL } from "../../../services/apiRoutes";
import getItems from "../../../services/getItems";

/**
 * VirtualmachineOverview component displays a list of virtual machines in a tabular format.
 * It fetches virtual machine data from an API endpoint and renders it using the FlexTable component.
 * Users can perform actions such as adding QR codes, deleting, or viewing details of virtual machines.
 *
 * @component
 * @returns {JSX.Element} A layout containing an overview header and a table of virtual machine data.
 */
const VirtualmachineOverview = () => {
  const assetType = "virtualMachine";
  const [items, setItems] = useState<Virtualmachine[]>([]);

  /**
   * Fetches virtual machine data from the API when the component mounts.
   * Updates the state with the retrieved virtual machine data.
   */
  useEffect(() => {
    (async () => {
      const data = await getItems<Virtualmachine[]>(READ_VIRTUALMACHINES_URL);
      if (data) {
        setItems(data);
      }
    })();
  }, []);

  return (
    <>
      <OverviewHeader title="Virtual Machines" assetType={assetType} />

      <FlexTable
        columns={VIRTUALMACHINE_TABLE_COLUMNS}
        rows={items}
        assetType={assetType}
        showAssignments
        hasActionButtons
        actionButtonRowProps={{
          assetType: assetType,
          hasManageAssignmentsAction: true,
          assignmentOptions: [
            { type: AssignmentType.EMPLOYEE, multiple: true },
          ],
          hasQrCodeAction: false,
          hasExportAction: true,
          hasDeleteAction: true,
        }}
      />
    </>
  );
};

export default VirtualmachineOverview;
