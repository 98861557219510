export const useStyle = () => {
  return {
    collector: {
      position: "fixed" as const,
      bottom: "20px",
      right: "20px",
    },

    counter: {
      position: "absolute" as const,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#BE543C",
      color: "white",
      fontSize: "12px",
      width: "8px",
      height: "8px",
      borderRadius: "20px",
      top: "-12px",
      right: "-12px",
    },

    text: {
      margin: "0 0 24px 0"
    },

    qrCodePreview: {
      display: "flex",
      flexWrap: "wrap" as const,
      gap: "20px",
    },

    qrCodeBox: {
      display: "inline-flex",
      position: "relative",
      width: "90px",

      "&:hover div": {
        opacity: 0.2,
        transition: "opacity 0.3s",
      },

      "&:hover button": {
        opacity: 1,
      },
    },

    qrCodeItem: {
      display: "inline-flex",
      flexDirection: "column" as const,
      alignItems: "center",
      gap: "8px",
      width: "100%",
    },

    qrCodeImg: {
      width: "60px",
    },

    qrCodeText: {
      textAlign: "center",
      fontSize: "12px",
    },

    qrCodeRemoveButton: {
      position: "absolute",
      top: "30%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      opacity: 0,
      transition: "opacity 0.3s",
      "&:hover": {
        opacity: 1,
      },
    },

    modalButtons: {
      display: "flex",
      justifyContent: "space-between",
      gap: "8px",
    },
  };
};
