import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

import { Fragment, useEffect, useState } from "react";
import { FlexTableProps } from "./FlexTable.interface";
import { useStyle } from "./FlexTable.styles";
import { formatCellContent } from "./FlexTable.utils";
import { validateProps } from "./FlexTable.warnings";

import ActionButtonCell from "./cell/action-button-cell/ActionButtonCell";
import AssignmentsCell from "./cell/assignments-cell/AssignmentsCell";

/**
 * FlexTable is a flexible table component that supports action buttons.
 * It dynamically renders table rows based on the provided data and configuration.
 *
 * @param {FlexTableProps} props - The properties for the FlexTable component.
 * @param {TableColumn[]} props.columns - Array including the name and the field type of the column.
 * The field is important for automatically rendering the correct item per cell,
 * so the table head (headline) and table body item match.
 * @param {any[]} props.rows - Rows/Items to be rendered. Can be any type.
 * @param {boolean} [props.hasActionButtons=false] - Should the rows have action buttons?
 * @param {ActionButtonRowProps} [props.actionButtonRowProps] - Defines the action buttons.
 * @returns {JSX.Element} Rendered table component.
 * @throws {Error} Throws an error if `hasActionButtons` is true but `actionButtonRowProps` is not specified.
 * @throws {Error} Throws an error if `actionButtonRowProps.hasManageAssignmentsAction` is true but `actionButtonRowProps.assignmentOptions` is not specified.
 * @throws {Error} Throws an error if `showAssignments` is true but `assetType` is not specified.
 */

const FlexTable = (props: FlexTableProps) => {
  // Ensure required props are provided. Throws errors if something is missing.
  validateProps(props);

  const style = useStyle();
  const [rows, setRows] = useState(props.rows);
  const [refreshCounter, setRefreshCounter] = useState(0);

  useEffect(() => {
    setRows(props.rows); // Update rows when props.rows changes
  }, [props.rows]);

  if (props.rows.length < 1) return <p>Es sind keine Einträge vorhanden.</p>;

  return (
    <TableContainer>
      <Table sx={style.table}>
        <TableHead>
          <TableRow>
            {/* These are the regular header cells. */}
            {props.columns.map((column) => (
              <TableCell key={column.field}>{column.label}</TableCell>
            ))}
            {/* If show assignments: Add header cell for assignments cell. */}
            {props.showAssignments && (
              <>
                {props.assetType !== "employee" && (
                  <TableCell key="assignedEmployees">Mitarbeitende</TableCell>
                )}
                <TableCell key="assignedAssets">Assets</TableCell>
              </>
            )}

            {props.hasActionButtons && (
              <TableCell key="actionButtons"></TableCell>
            )}
          </TableRow>
        </TableHead>

        <TableBody>
          {rows.map((row, rowIndex) => (
            <Fragment key={rowIndex}>
              <TableRow>
                {/* These are the regular cells. */}
                {props.columns.map((column) => (
                  <TableCell key={column.field}>
                    {formatCellContent(row[column.field])}
                  </TableCell>
                ))}

                {/* Show assets */}
                {props.showAssignments && (
                  <AssignmentsCell
                    assetId={row.id}
                    assetType={props.assetType!}
                    refreshCounter={refreshCounter}
                  />
                )}

                {/* If rows have action buttons: Show action buttons. */}
                {/* At this point >actionButtonRowProps< cannot be null, otherwise validateProps() would have thrown an error. */}
                {props.hasActionButtons && (
                  <ActionButtonCell
                    row={row}
                    actionButtonRowProps={props.actionButtonRowProps!}
                    setRows={setRows}
                    onAssignmentSave={() =>
                      setRefreshCounter((prev) => prev + 1)
                    }
                  />
                )}
              </TableRow>
            </Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FlexTable;
