import { FlexTableProps } from "./FlexTable.interface";

/**
 * Ensure required props are provided when collapsible rows or action buttons are enabled.
 * @param props All FlexTableProps.
 * @throws Errors if props are missing.
 */
export const validateProps = (props: FlexTableProps) => {
  if (props.hasActionButtons && !props.actionButtonRowProps) {
    throw new Error(
      "If table rows should have action buttons, then >actionButtonRowProps< must be specified.\n"
    );
  }
  if (
    props.actionButtonRowProps?.hasManageAssignmentsAction &&
    !props.actionButtonRowProps.assignmentOptions
  ) {
    throw new Error(
      "If table rows should have action to manage the assignments, then >actionButtonRowProps.assignmentOptions< must be specified.\n"
    );
  }
  if (props.showAssignments && !props.assetType) {
    throw new Error(
      "If table rows should show assignments, then >assetType< must be specified.\n"
    );
  }
};
