import { Button } from "@mui/material";
import { FlexButtonProps } from "./FlexButton.interface";
import { useStyle } from "./FlexButton.styles";

/**
 * Flexible Button:
 * 1. Can have only text or only icon or both. Title and icon params are optional, but one is required!
 * 2. Can have different color style, using ButtonColor enum.
 * 3. Can have different styles, using ButtonVariant enum.
 * @param variant ButtonVariant, e.g. Text_Filled or Icon_Outlined
 * @param color ButtonColor, e.g. Primary or Caution
 * @param title Title
 * @param icon Icon Element
 * @param onClick Action that gets triggered, when button is clicked.
 * @returns Rendered button component.
 */
const FlexButton = (props: FlexButtonProps) => {
  if (!props.title && !props.icon) {
    throw new Error(
      "Either >buttonTitle< or >buttonIcon< must be specified when using Button Component.\n"
    );
  }

  const style = useStyle(props.variant, props.color);

  return (
    <Button
      id={props.id}
      disabled={props.disabled}
      onClick={props.onClick}
      sx={{
        ...style.button,
        ...style.selectedVariant,
        ...props.sx,
      }}
    >
      {props.icon && props.icon}
      {props.title && props.title}
    </Button>
  );
};

export default FlexButton;
