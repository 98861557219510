import { Dispatch } from "react";
import { toast } from "react-toastify";
import { Location } from "../../../models/Location";
import {
  CREATE_LOCATION_URL,
  UPDATE_LOCATION_URL,
} from "../../../services/apiRoutes";
import createItem from "../../../services/createItem";
import updateItem from "../../../services/updateItem";
import sectionsData from "./locationInputFields.json";

// Typen für `Field` und `Section`
type Field = {
  label: string;
  id: string;
  type: string;
  required: boolean;
  mask?: boolean;
  copyable?: boolean;
};

type Section = {
  sectionTitle: string;
  fields: Field[];
};

// Initiale Daten für eine Location
export const initialData: Location = {
  identifier: "",
  designation: "",
  street: "",
  number: "",
  zip_code: "",
  city: "",
  country: "",
  comment: "",
  assigned_to: null, // Assuming this is a foreign key, might be an object with id and name
  assigned_date: null,
  return_date: null,
};

/**
 * Handles input change events and clears validation indicators after
 * a required input isn't empty anymore.
 * @param id Input field id.
 * @param value New Value of input field.
 * @param setEditedLocation Setter for >editedLocation<
 * @param setErrors Setter for >errors<
 */
export const handleInputChange = (
  id: string,
  value: any,
  setEditedLocation: Dispatch<React.SetStateAction<Location>>,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>
) => {
  setEditedLocation((prevData) => ({
    ...prevData,
    [id]: value,
  }));

  // If input changes to valid: Remove error.
  setErrors((prevErrors) => {
    const newErrors = new Set(prevErrors);
    if (
      value &&
      (typeof value !== "object" || (value.id !== 0 && value.name !== ""))
    ) {
      newErrors.delete(id);
    }
    return newErrors;
  });
};

/**
 * If all required fields are filled: Handles the save action.
 * Either updates an existing item or creates a new one.
 * @param areRequiredFieldsFilled True if all required fields are filled.
 * @param editedLocation item that gets edited.
 * @param isNew True if it's the creation of a new item.
 * @param idLocation id.
 */
export const handleSaveChanges = async (
  areRequiredFieldsFilled: boolean,
  editedLocation: Location,
  navigateCallback: () => void,
  isNew: boolean,
  id?: string
) => {
  // Save only if required fields aren't empty.
  if (areRequiredFieldsFilled) {
    if (editedLocation.assigned_date === "") {
      editedLocation.assigned_date = null;
    }
    if (isNew) {
      await createItem<Location>(CREATE_LOCATION_URL, editedLocation);
      // After creating a new element, go back to overview page.
      navigateCallback();
    } else if (id) {
      await updateItem<Location>(UPDATE_LOCATION_URL, id, editedLocation);
    }
  } else {
    toast.error("Es sind noch nicht alle Pflichtfelder ausgefüllt.");
  }
};

/**
 * Checks if all required fields are filled.
 * @param editedLocation item that gets edited.
 * @param setErrors Setter for >errors<
 * @returns TRUE: All required fields are filled. FALSE: Some required fields are empty.
 */
export const validateFields = (
  editedLocation: Location,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>
) => {
  const newErrors = new Set<string>();

  // Sicherstellen, dass `sectionsData` korrekt typisiert ist
  (sectionsData as Section[]).forEach((section: Section) => {
    section.fields.forEach((field: Field) => {
      const value = editedLocation[field.id as keyof Location];

      // If nothing in there, then add error.
      if (
        field.required &&
        (!value || (typeof value === "object" && value.id === 0 && value.name === ""))
      ) {
        newErrors.add(field.id);
      }
    });
  });

  setErrors(newErrors);
  return newErrors.size === 0;
};
