import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FlexButton from "../../../components/button/FlexButton";
import { ButtonVariant } from "../../../components/button/FlexButton.interface";
import FormComponent from "../../../components/form/FormComponent";
import { Location as LocationModel } from "../../../models/Location";
import { READ_LOCATION_URL } from "../../../services/apiRoutes";
import getItem from "../../../services/getItem";
import "../../../styles/Details.css";
import {
  handleInputChange,
  handleSaveChanges,
  initialData,
  validateFields,
} from "./locationDetails.util";
import sectionsData from "./locationInputFields.json";

type Field = {
  label: string;
  id: string;
  type: string;
  required: boolean;
  mask?: boolean;
  copyable?: boolean;
};

type Section = {
  sectionTitle: string;
  fields: Field[];
};

const LocationDetail: React.FC = () => {
  const assetType = "location";
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const [locationItem, setLocationItem] = useState<LocationModel | null>(null);
  const [editedLocation, setEditedLocation] = useState<LocationModel>(initialData);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isCreationMode] = useState<boolean>(
    location.pathname.includes("/create")
  );
  const [errors, setErrors] = useState<Set<string>>(new Set());

  useEffect(() => {
    const fetchLocation = async () => {
      if (id && !isCreationMode) {
        try {
          const data = await getItem<LocationModel>(READ_LOCATION_URL, id);
          if (data) {
            setLocationItem(data);
            setEditedLocation({
              ...data,
            });
          } else {
            navigate("*", { replace: true });
          }
        } catch (error) {
          console.error("Failed to fetch location data:", error);
        }
      }
    };

    fetchLocation();
  }, [id, isCreationMode, navigate]);

  // Typisiere die `sections` korrekt
  const sections = (sectionsData as Section[]).map((section: Section) => ({
    ...section,
    fields: section.fields.map((field: Field) => ({
      ...field,
      value: editedLocation[field.id as keyof LocationModel], // Make sure the typing aligns with your state.
      handleInputChange: ({
        id,
        value,
      }: {
        id: string;
        value: string | number | boolean;
      }) => {
        handleInputChange(id, value, setEditedLocation, setErrors);
      },
      disabled: !isEditMode || !isCreationMode,
    })),
  }));

  if (!locationItem && !isCreationMode) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="flex flex-between align-items-center">
        <h1 className="details-heading">
          {isCreationMode ? "Neu erstellen" : "Details"}{" "}
        </h1>
        {!isEditMode && !isCreationMode ? (
          <FlexButton
            id="edit-location-button"
            variant={ButtonVariant.TEXT_OUTLINED}
            title={"Bearbeiten"}
            onClick={() => setIsEditMode(true)}
          />
        ) : (
          <FlexButton
            id="save-location-button"
            variant={ButtonVariant.TEXT_FILLED}
            title={"Speichern"}
            onClick={() => {
              const areRequiredFieldsFilled = validateFields(
                editedLocation,
                setErrors
              );
              handleSaveChanges(
                areRequiredFieldsFilled,
                editedLocation,
                () => navigate(`/${assetType}`),
                isCreationMode,
                id
              );
              setIsEditMode(false);
            }}
          ></FlexButton>
        )}
      </div>

      <FormComponent
        sections={sections}
        editMode={isEditMode || isCreationMode}
        errors={errors}
      ></FormComponent>
    </>
  );
};

export default LocationDetail;
