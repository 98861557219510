/* TODO: there is still sidebar css, replace with refactoring */

export const useStyle = () => {
  return {
    logo: {
      display: "flex",
      alignItems: "center",
      gap: "8px",
      padding: "12px",
      marginBottom: "16px",
    },

    logo_img: {
      width: "32px",
    },

    logo_name: {
      color: "#40A9BF",
      fontWeight: "500",
      margin: 0,
      padding: 0,
    },

    divider: {
      borderColor: "white",
      margin: "16px 0",
    },

    logout: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
    },

    logout_icon: {
      width: "16px",
      color: "#001F26",
    },
  };
};
