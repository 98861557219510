export const useStyle = () => {
    return {
      container: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "8px", 
      },
  
      buttonGroup: {
        display: "flex",
        alignItems: "center",
        gap: "8px", 
      },
    };
  };