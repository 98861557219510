export interface FlexButtonProps {
  id: string;
  disabled?: boolean;
  variant?: ButtonVariant;
  color?: ButtonColor;
  title?: string;
  icon?: React.ReactElement;
  onClick: () => void;
  sx?: any; // shouldnt be "any" / workaround for now
}

export enum ButtonVariant {
  TEXT_FILLED,
  TEXT_OUTLINED,
  ICON_FILLED,
  ICON_OUTLINED,
  ICON_TRANSPARENT,
  ICON_ROUNDED,
}

export enum ButtonColor {
  PRIMARY,
  CAUTION,
  GRAY,
}
