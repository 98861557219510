import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import FlexButton from "../button/FlexButton";
import { ButtonVariant } from "../button/FlexButton.interface";
import { OverviewHeaderProps } from "./OverviewHeader.interface";
import { exportFile, ExportType } from "../../services/exportFile";
import { useState } from "react";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import MyModal from "../modal/MyModal";
import { useStyle } from "./OverviewHeader.styles";

/**
 * OverviewHeader component renders a header with a title and a button.
 * The button navigates to a create page for the given asset type.
 *
 * @param {OverviewHeaderProps} props - The properties for the OverviewHeader component.
 * @param {string} props.title - The title to display in the header.
 * @param {string} props.assetType - The type of asset for which the create page will be navigated to.
 * @returns {JSX.Element} A header element containing a title and a navigation button.
 */
const OverviewHeader = (props: OverviewHeaderProps) => {
  const navigate = useNavigate();
  const styles = useStyle();
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);

  const openExportModal = () => setIsExportModalOpen(true);
  const closeExportModal = () => setIsExportModalOpen(false);

  const handleExport = (exportType: ExportType) => {
    exportFile(exportType, props.assetType, "all");
    closeExportModal();
  };

  return (
    <Box sx={styles.container}>
      <h1>{props.title}</h1>
      <Box sx={styles.buttonGroup}>
        <FlexButton
          id={`export-${props.assetType}-button`}
          variant={ButtonVariant.ICON_OUTLINED}
          icon={<FileDownloadOutlinedIcon />}
          onClick={openExportModal}
          aria-label="Export"
        />

        <FlexButton
          id={`add-${props.assetType}-button`}
          variant={ButtonVariant.TEXT_OUTLINED}
          title="Hinzufügen"
          onClick={() =>
            navigate(`/${props.assetType}/create`, {
              state: { editMode: true },
            })
          }
        />

        <MyModal
          title="Datei exportieren als …"
          buttons={
            <>
              <FlexButton
                id="export-modal-pdf-button"
                variant={ButtonVariant.TEXT_OUTLINED}
                title="PDF"
                onClick={() => handleExport(ExportType.PDF)}
              />
              <FlexButton
                id="export-modal-csv-button"
                variant={ButtonVariant.TEXT_OUTLINED}
                title="CSV"
                onClick={() => handleExport(ExportType.CSV)}
              />
            </>
          }
          isOpen={isExportModalOpen}
          onClose={closeExportModal}
        />
      </Box>
    </Box>
  );
};

export default OverviewHeader;