export const useStyle = () => {
  return {
    modal: {
      position: "absolute" as const,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: "24px",
      backgroundColor: "white",
      border: "none",
      borderRadius: "8px",
    },

    form: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "8px",
    },

    input: {
      display: "flex",
      flexDirection: "row" as const,
      alignItems: "center" as const,
      justifyContent: "center" as const,
      width: "200px",
    },

    buttonGroup: {
      display: "flex",
      justifyContent: "end",
    },
  };
};
