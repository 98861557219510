import { Dispatch } from "react";
import { toast } from "react-toastify";
import { Dooraccess } from "../../../models/Dooraccess";
import {
  CREATE_DOORACCESS_URL,
  UPDATE_DOORACCESS_URL,
} from "../../../services/apiRoutes";
import createItem from "../../../services/createItem";
import updateItem from "../../../services/updateItem";
import sectionsData from "./dooraccessInputFields.json";

export const initialData = {
  identifier: "",
  comment: "",
  serialnumber: "",
  type: 0,
  type_details: {
    id: 0,
    name: "",
  },
  inventory_number: "",
  inventory_status: 0,
  inventory_status_details: {
    id: null,
    name: null,
  },
  inventory_type: 0,
  inventory_type_details: {
    id: null,
    name: null,
  },
  last_inventoried: null,
};

/**
 * Handles input change events and clears validation indicators after
 * an required input isn't empty anymore.
 * @param id Input field id.
 * @param value New Value of input field.
 * @param setEditedDooraccess Setter for >editedDooraccess<
 * @param setErrors Setter for >errors<
 */
export const handleInputChange = (
  id: string,
  value: any,
  setEditedDooraccess: Dispatch<React.SetStateAction<Dooraccess>>,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>,
) => {
  setEditedDooraccess((prevData) => ({
    ...prevData,
    [id]: value,
  }));

  // If input changes to valid: Remove error.
  setErrors((prevErrors) => {
    const newErrors = new Set(prevErrors);
    if (
      value &&
      (typeof value !== "object" || (value.id !== 0 && value.name !== ""))
    ) {
      newErrors.delete(id);
    }
    return newErrors;
  });
};

/**
 * If all required fields are filled: Handles the save action.
 * Either updates an existing item or creates a new one.
 * @param areRequiredFieldsFilled True if all required fields are filled.
 * @param editedDooraccess Dooraccess item that gets edited.
 * @param isNew True if it the creation of a new item.
 * @param id Dooraccess id.
 */
export const handleSaveChanges = async (
  areRequiredFieldsFilled: boolean,
  editedDooraccess: Dooraccess,
  navigateCallback: () => void,
  isNew: boolean,
  id?: string,
) => {
  // Save only if required fields aren't empty.
  if (areRequiredFieldsFilled) {
    editedDooraccess.type = editedDooraccess.type_details.id;
    editedDooraccess.inventory_status =
      editedDooraccess.inventory_status_details
        ? editedDooraccess.inventory_status_details.id
        : null;
    editedDooraccess.inventory_type = editedDooraccess.inventory_type_details
      ? editedDooraccess.inventory_type_details.id
      : null;
    if (isNew) {
      await createItem<Dooraccess>(CREATE_DOORACCESS_URL, editedDooraccess);
      // After creating a new element, go back to overview page.
      navigateCallback();
    } else if (id) {
      await updateItem<Dooraccess>(UPDATE_DOORACCESS_URL, id, editedDooraccess);
    }
  } else {
    toast.error("Es sind noch nicht alle Pflichtfelder ausgefüllt.");
  }
};

/**
 * Checks if all required fields are filled.
 * @param editedDooraccess Dooraccess item that gets edited.
 * @param setErrors Setter for >errors<
 * @returns TRUE: All required fields are filled. FALSE: Some required fields are empty.
 */
export const validateFields = (
  editedDooraccess: Dooraccess,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>,
) => {
  const newErrors = new Set<string>();

  sectionsData.forEach((section) => {
    section.fields.forEach((field) => {
      const value = editedDooraccess[field.id as keyof Dooraccess];

      // If nothing in there, then add error.
      // Or: If value is an object but has nothing in there, then add error.
      if (
        field.required &&
        (!value ||
          (typeof value === "object" && value.id === 0 && value.name === ""))
      ) {
        newErrors.add(field.id);
      }
    });
  });

  setErrors(newErrors);
  return newErrors.size === 0;
};
