import {
  ACCESSORY_CATEGORY_OPTIONS,
  BYTE_TYPE_OPTIONS,
  COMPUTER_TYPE_OPTIONS,
  CONTRACT_DURATION_OPTIONS,
  DATA_VOLUME_UNIT_OPTIONS,
  DISK_TYPE_OPTIONS,
  DOOR_TYPE_OPTIONS,
  EMPLOYEE_DEPARTMENT_OPTIONS,
  EMPLOYEE_USER_ROLE_OPTIONS,
  EMPLOYEE_WORKPLACE_OPTIONS,
  FLOOR_OPTIONS,
  LITERATURE_TYPE_OPTIONS,
  MEMORY_TYPE_OPTIONS,
  MOBILE_NETWORK_OPTIONS,
  NETWORK_DEVICE_CATEGORY_OPTIONS,
  PAYMENT_CYCLE_OPTIONS,
  INVENTORY_TYPE_OPTIONS,
  INVENTORY_STATUS_OPTIONS
} from "../../services/apiRoutes";
import { axiosCall } from "../../api/config/axiosCall";
import { DropdownOption } from "../../utils/interfaces/dropdowns";

export const urlMapping: { [key: string]: string } = {
  ACCESSORY_CATEGORY_OPTIONS: ACCESSORY_CATEGORY_OPTIONS,
  COMPUTER_TYPE_OPTIONS: COMPUTER_TYPE_OPTIONS,
  CONTRACT_DURATION_OPTIONS: CONTRACT_DURATION_OPTIONS,
  DATA_VOLUME_UNIT_OPTIONS: DATA_VOLUME_UNIT_OPTIONS,
  DISK_TYPE_OPTIONS: DISK_TYPE_OPTIONS,
  DOOR_TYPE_OPTIONS: DOOR_TYPE_OPTIONS,
  FLOOR_OPTIONS: FLOOR_OPTIONS,
  LITERATURE_TYPE_OPTIONS: LITERATURE_TYPE_OPTIONS,
  NETWORK_DEVICE_CATEGORY_OPTIONS: NETWORK_DEVICE_CATEGORY_OPTIONS,
  PAYMENT_CYCLE_OPTIONS: PAYMENT_CYCLE_OPTIONS,
  BYTE_TYPE_OPTIONS: BYTE_TYPE_OPTIONS,
  MEMORY_TYPE_OPTIONS: MEMORY_TYPE_OPTIONS,
  MOBILE_NETWORK_OPTIONS: MOBILE_NETWORK_OPTIONS,
  EMPLOYEE_DEPARTMENT_OPTIONS: EMPLOYEE_DEPARTMENT_OPTIONS,
  EMPLOYEE_USER_ROLE_OPTIONS: EMPLOYEE_USER_ROLE_OPTIONS,
  EMPLOYEE_WORKPLACE_OPTIONS: EMPLOYEE_WORKPLACE_OPTIONS,
  INVENTORY_TYPE_OPTIONS: INVENTORY_TYPE_OPTIONS,
  INVENTORY_STATUS_OPTIONS: INVENTORY_STATUS_OPTIONS,
};

/**
 * Asynchronously fetches dropdown options from a specified URL key.
 *
 * @param {string} urlKey - The key used to look up the URL for the dropdown options.
 * @returns {Promise<DropdownOption[]>} A promise that resolves to an array of dropdown options if successful, or an empty array if the request fails.
 */
export const fetchDropDownOptions = async (
  urlKey: string
): Promise<DropdownOption[]> => {
  const url = urlMapping[urlKey];

  if (!url) throw new Error(`URL for key ${urlKey} not found`);

  try {
    const response = await axiosCall({
      endpoint: url,
      method: "GET",
    });
    return response?.data;
  } catch (error) {
    console.error("Error fetching dropdown options:", error);
    return [];
  }
};
