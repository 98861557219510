export { default as AccessoryDetail } from "./assets/accessory/accessoryDetails";
export { default as AccessoryOverview } from "./assets/accessory/accessoryOverview";
export { default as BuildingDetail } from "./assets/building/buildingDetails";
export { default as BuildingOverview } from "./assets/building/buildingOverview";
export { default as ComputerDetail } from "./assets/computer/computerDetails";
export { default as ComputerOverview } from "./assets/computer/computerOverview";
export { default as ContractDetails } from "./assets/contract/contractDetails";
export { default as ContractOverview } from "./assets/contract/contractOverview";
export { default as DooraccessDetail } from "./assets/dooraccess/dooraccessDetails";
export { default as DooraccessOverview } from "./assets/dooraccess/dooraccessOverview";
export { default as LandlineDetail } from "./assets/landline/landlineDetails";
export { default as LandlineOverview } from "./assets/landline/landlineOverview";
export { default as LandlineContractDetail } from "./assets/landlineContract/landlineContractDetails";
export { default as LandlineContractOverview } from "./assets/landlineContract/landlineContractOverview";
export { default as LicenseDetails } from "./assets/license/licenseDetails";
export { default as LicenseOverview } from "./assets/license/licenseOverview";
export { default as LiteratureDetail } from "./assets/literature/literatureDetails";
export { default as LiteratureOverview } from "./assets/literature/literatureOverview";
export { default as LocationDetail } from "./assets/location/locationDetails";
export { default as LocationOverview } from "./assets/location/locationOverview";
export { default as MobilephoneDetail } from "./assets/mobilephone/mobilephoneDetails";
export { default as MobilephoneOverview } from "./assets/mobilephone/mobilephoneOverview";
export { default as MonitorDetails } from "./assets/monitor/monitorDetails";
export { default as MonitorOverview } from "./assets/monitor/monitorOverview";
export { default as NetworkDeviceDetail } from "./assets/networkDevice/networkDeviceDetails";
export { default as NetworkDeviceOverview } from "./assets/networkDevice/networkDeviceOverview";
export { default as RoomDetail } from "./assets/room/roomDetails";
export { default as RoomOverview } from "./assets/room/roomOverview";
export { default as ServerDetail } from "./assets/server/serverDetails";
export { default as ServerOverview } from "./assets/server/serverOverview";
export { default as TableDetail } from "./assets/table/tableDetail";
export { default as TableOverview } from "./assets/table/tableOverview";
export { default as VirtualmachineDetail } from "./assets/virtualmachine/virtualmachineDetails";
export { default as VirtualmachineOverview } from "./assets/virtualmachine/virtualmachineOverview";
export { default as Dashboard } from "./dashboard/Dashboard";
export { default as EmployeeDetail } from "./employee/EmployeeDetails";
export { default as EmployeeOverview } from "./employee/EmployeeOverview";
export { default as Login } from "./auth/login/Login";
export { default as ErrorPage } from "./error/ErrorPage";
