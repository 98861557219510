import * as Yup from "yup";

/**
 * Defines validation rules for common fields used in forms.
 * @returns {object} - An object containing validation schemas for various fields.
 */
export const fields = () => ({
  email: Yup.string()
    .required("E-Mail-Adresse ist erforderlich.")
    .email("Ungültige E-Mail-Adresse"),
  password: Yup.string().required("Passwort ist erforderlich"),
  newPassword: Yup.string()
    .min(8, "Mindestens 8 Zeichen")
    .max(64, "Maximal 64 Zeichen")
    .matches(/\d/, "Mindestens eine Ziffer (0-9)")
    .matches(/[A-Z]/, "Mindestens ein Großbuchstabe (A-Z)")
    .matches(/[a-z]/, "Mindestens ein Kleinbuchstabe (a-z)")
    .matches(
      /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/,
      "Mindestens ein Sonderzeichen"
    )
    .matches(
      /^(?!.*(.)\1\1)/,
      "Das Passwort darf nicht dreimal oder öfter das gleiche Zeichen hintereinander enthalten"
    )
    .required("Eingabe erforderlich"),
  newPasswordConfirm: Yup.string()
    .oneOf([Yup.ref("newPassword")], "Passwörter müssen übereinstimmen")
    .required("Eingabe erforderlich"),
});
