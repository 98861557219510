import { TableColumn } from "../components/table/FlexTable.interface";

const employeeColumns: TableColumn[] = [
  { field: "first_name", label: "Vorname" },
  { field: "last_name", label: "Nachname" },
];

const purchaseColumns: TableColumn[] = [
  { field: "purchase_date", label: "Kaufdatum" },
  { field: "purchase_price", label: "Kaufpreis" },
  { field: "bill_number", label: "Rechnungsnummer" },
  { field: "order_number", label: "Bestellnummer" },
  { field: "inventory_number", label: "Inventarnummer" },
];

const modelColumns: TableColumn[] = [
  { field: "manufacturer", label: "Hersteller" },
  { field: "model", label: "Modell" },
  // { field: "serialnumber", label: "Seriennummer" },
];

const techColumns: TableColumn[] = [
  // { field: "hostname", label: "Hostname" },
  // { field: "encryptionType", label: "Sicherheitstyp" },
  // { field: "encryptionKey", label: "Sicherheitsschlüssel" },
  // { field: "recoveryKey", label: "Wiederherstellungsschlüssel" },
  // { field: "bios_password", label: "BIOS Passwort" },
  { field: "os_name", label: "Betriebssystem" },
  // { field: "os_version", label: "Betriebssystemversion" },
  // { field: "processorManufacturer", label: "Prozessorhersteller" },
  // { field: "processorName", label: "Prozessorname" },
  // { field: "processorCores", label: "Prozessorkerne" }, // TODO: gibts noch nicht, steht aber in confluence
  //{ field: "processorQuantity", label: "Prozessoranzahl" },
];

const networkColumns: TableColumn[] = [
  { field: "ip", label: "IP-Adresse" },
  { field: "mac", label: "MAC-Adresse" },
];

const storageColumns: TableColumn[] = [
  { field: "disk_type_name", label: "Festplattentyp" },
  { field: "diskCapacity", label: "Festplattenkapazität" },
  { field: "disk_byte_name", label: "Festplattenkapazitätseinheit" },
  { field: "memory_type_name", label: "RAM-Typ" },
  { field: "memoryCapacity", label: "RAM-Größe" },
  { field: "memory_byte_name", label: "RAM-Einheit" },
];

const userInfoColumns: TableColumn[] = [
  { field: "contractMail", label: "Vertrags-E-Mail-Adresse" },
  { field: "username", label: "Benutzername" },
  { field: "password", label: "Passwort" },
];

const contractInfoColumns: TableColumn[] = [
  { field: "provider", label: "Anbieter" },
  // { field: "customerNumber", label: "Kundennummer" },
  // { field: "contractNumber", label: "Vertragsnummer" },
  { field: "phoneNumber", label: "Telefonnummer" },
  // { field: "pin", label: "PIN" },
  // ...userInfoColumns,
  // { field: "contractStart", label: "Vertragsbeginn" },
  // { field: "minimumContractDuration", label: "Minimale Vertragslaufzeit" },
  // { field: "periodOfNotice", label: "Kündigungsfrist" },
  // { field: "contractEnd", label: "Vertragsende" },
  // { field: "monthlyCost", label: "Monatliche Kosten (exkl. Rabatt)" },
  // { field: "monthlyDiscount", label: "Monatlicher Rabatt" },
  // { field: "discountDuration", label: "Rabattdauer" },
  // { field: "computed_monthly_cost", label: "Monatliche Kosten (inkl. Rabatt)" },
];

export const ONBOARDING_TABLE_COLUMNS: TableColumn[] = [
  ...employeeColumns,
  { field: "start_date", label: "Eintrittsdatum" },
];

export const OFFBOARDING_TABLE_COLUMNS: TableColumn[] = [
  ...employeeColumns,
  { field: "end_date", label: "Austrittsdatum" },
];

export const EMPLOYEE_TABLE_COLUMNS: TableColumn[] = [
  ...employeeColumns,
  // { field: "email", label: "E-Mail-Adresse" },
  // { field: "workplace_name", label: "Arbeitsplatz" },
  // { field: "department_name", label: "Abteilung" },
  // { field: "user_role_name", label: "Rolle" },
  // { field: "start_date", label: "Eintrittsdatum" },
  // { field: "end_date", label: "Austrittsdatum" },
  // { field: "comment", label: "Kommentar" },
];

export const ACCESSORY_TABLE_COLUMNS: TableColumn[] = [
  { field: "identifier", label: "Kennung" },
  { field: "designation", label: "Bezeichnung" },
  {
    field: "accessory_category_name",
    label: "Kategorie",
  },
  // ...modelColumns,
  // ...purchaseColumns,
  // { field: "comment", label: "Kommentar" },
];

export const BUILDING_TABLE_COLUMNS: TableColumn[] = [
  { field: "identifier", label: "Kennung" },
  { field: "designation", label: "Bezeichnung" },
  {
    field: "building_category_name",
    label: "Kategorie",
  },
  // ...modelColumns,
  // ...purchaseColumns,
  // { field: "comment", label: "Kommentar" },
];


export const COMPUTER_TABLE_COLUMNS: TableColumn[] = [
  { field: "identifier", label: "Kennung" },
  // ...purchaseColumns,
  // ...modelColumns,
  { field: "type_name", label: "Typ" },
  ...techColumns,
  // ...networkColumns,
  // ...storageColumns,
  // { field: "comment", label: "Kommentar" },
];

export const CONTRACT_TABLE_COLUMNS: TableColumn[] = [
  { field: "identifier", label: "Kennung" },
  ...contractInfoColumns,
  // { field: "mobile_network_name", label: "Netz" },
  // { field: "hotline", label: "Hotline" },
  // { field: "hotlinePassword", label: "Hotline Passwort" },
  // { field: "dataVolume", label: "Datenvolumen" },
  // { field: "data_volume_unit_name", label: "Datenvolumeneinheit" },
  // { field: "sim", label: "SIM Nummer" },
  // { field: "puk", label: "PUK" },
  // { field: "comment", label: "Kommentar" },
];

export const DOORACCESS_TABLE_COLUMNS: TableColumn[] = [
  { field: "identifier", label: "Kennung" },
  // { field: "serialnumber", label: "Seriennummer" },
  { field: "type_name", label: "Typ" },
  // { field: "comment", label: "Kommentar" },
];

export const LANDLINE_TABLE_COLUMNS: TableColumn[] = [
  { field: "identifier", label: "Kennung" },
  // ...purchaseColumns,
  // ...networkColumns,
  // { field: "comment", label: "Kommentar" },
];

export const LANDLINE_CONTRACT_TABLE_COLUMNS: TableColumn[] = [
  { field: "identifier", label: "Kennung" },
  ...contractInfoColumns,
  // { field: "comment", label: "Kommentar" },
];

export const LICENSE_TABLE_COLUMNS: TableColumn[] = [
  { field: "identifier", label: "Kennung" },
  // ...purchaseColumns,
  // { field: "payment_cycle_name", label: "Zahlungszyklus" },
  { field: "manufacturer", label: "Hersteller" },
  // { field: "licensor", label: "Lizenzgeber" },
  { field: "licenseType", label: "Lizenztyp" },
  // { field: "licenseKey", label: "Lizenzschlüssel" },
  // { field: "contract_duration_name", label: "Vertragsdauer" },
  // { field: "periodOfNotice", label: "Kündigungsfrist" },
  // { field: "comment", label: "Kommentar" },
];

export const LITERATURE_TABLE_COLUMNS: TableColumn[] = [
  { field: "identifier", label: "Kennung" },
  // ...purchaseColumns,
  { field: "type_name", label: "Typ" },
  // { field: "isbn", label: "ISBN" },
  { field: "title", label: "Titel" },
  // { field: "author", label: "Autor" },
  // { field: "comment", label: "Kommentar" },
];

export const LOCATION_TABLE_COLUMNS: TableColumn[] = [
  { field: "identifier", label: "Kennung" },
  { field: "designation", label: "Bezeichnung" },
  {
    field: "location_category_name",
    label: "Kategorie",
  },
  // ...modelColumns,
  // ...purchaseColumns,
  // { field: "comment", label: "Kommentar" },
];


export const MOBILEPHONE_TABLE_COLUMNS: TableColumn[] = [
  { field: "identifier", label: "Kennung" },
  // ...purchaseColumns,
  ...modelColumns,
  // { field: "imei1", label: "IMEI 1" },
  // { field: "imei2", label: "IMEI 2" },
  // { field: "comment", label: "Kommentar" },
];

export const MONITOR_TABLE_COLUMNS: TableColumn[] = [
  { field: "identifier", label: "Kennung" },
  // ...purchaseColumns,
  ...modelColumns,
  // { field: "aspectRatio", label: "Seitenverhältnis" },
  // { field: "size", label: "Größe" },
  // { field: "resolution", label: "Auflösung" },
  // { field: "panel", label: "Panel" },
  // { field: "heightAdjustable", label: "Höhenverstellbar" },
  // { field: "swiveling", label: "Neig-/Schwenkbar" },
  // { field: "curved", label: "Curved" },
  // { field: "comment", label: "Kommentar" },
];

export const NETWORK_TABLE_COLUMNS: TableColumn[] = [
  { field: "identifier", label: "Kennung" },
  // ...purchaseColumns,
  ...modelColumns,
  { field: "category", label: "Kategorie" },
  // { field: "firmware", label: "Firmware" },
  // ...userInfoColumns,
  // ...networkColumns,
  // { field: "port_type", label: "Port Typ" },
  // { field: "quantity", label: "Anzahl" },
  // { field: "comment", label: "Kommentar" },
];

export const ROOM_TABLE_COLUMNS: TableColumn[] = [
  { field: "identifier", label: "Kennung" },
  { field: "floor_name", label: "Etage" },
  // { field: "comment", label: "Kommentar" },
];

export const SERVER_TABLE_COLUMNS: TableColumn[] = [
  { field: "identifier", label: "Kennung" },
  // ...purchaseColumns,
  ...modelColumns,
  // ...networkColumns,
  // ...storageColumns,
  // { field: "comment", label: "Kommentar" },
];

export const TABLE_TABLE_COLUMNS: TableColumn[] = [
  { field: "identifier", label: "Kennung" },
  // ...purchaseColumns,
  // { field: "heightAdjustable", label: "Höhenverstellbar" },
  // { field: "comment", label: "Kommentar" },
];

export const VIRTUALMACHINE_TABLE_COLUMNS: TableColumn[] = [
  { field: "identifier", label: "Kennung" },
  { field: "name", label: "Name" },
  ...techColumns,
  // ...networkColumns,
  // ...storageColumns,
  // { field: "comment", label: "Kommentar" },
];
