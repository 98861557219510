import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import { useStyle } from "./ChangePassword.styles";
import { useChangePassword } from "../../../api/hooks/auth/useChangePassword";
import { validationChangePassword } from "../../../utils/validations/validation-schemas";
import { getErrorMessage } from "../../../utils/form/convertInputErrorMessage";
import FlexButton from "../../button/FlexButton";
import CustomTextInputField from "../../input/CustomTextField";
import Alert from "../../alert/Alert";

/**
 * ChangePassword component allows users to update their password.
 * It uses react-hook-form for form handling and validation schema with Yup.
 *
 * - Users must provide their old password and a new password.
 * - New password must be confirmed.
 * - Displays errors and validation feedback.
 *
 * @component
 * @returns {JSX.Element} Form for password change with old password, new password, and confirmation fields.
 */
const ChangePassword = () => {
  const styles = useStyle();
  const formOptions = { resolver: yupResolver(validationChangePassword) };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const { changePassword, error, loading } = useChangePassword();

  // Function to handle form submission
  const onSubmit = changePassword;

  return (
    <>
      <h2>Passwort ändern</h2>

      <Box sx={styles.form}>
        <Box>
          <CustomTextInputField
            control={control}
            name="oldPassword"
            label="Altes Passwort *"
            type="password"
            error={!!errors.oldPassword}
            helperText={getErrorMessage(errors?.oldPassword)}
            onEnter={handleSubmit(onSubmit)}
          />
        </Box>
        <Box sx={styles.newPasswordBox}>
          <Box>
            <CustomTextInputField
              control={control}
              name="newPassword"
              label="Neues Passwort *"
              type="password"
              error={!!errors.newPassword}
              helperText={getErrorMessage(errors?.newPassword)}
              onEnter={handleSubmit(onSubmit)}
            />
          </Box>
          <Box>
            <CustomTextInputField
              control={control}
              name="newPasswordConfirm"
              label="Passwort bestätigen *"
              type="password"
              error={!!errors.newPasswordConfirm}
              helperText={getErrorMessage(errors?.newPasswordConfirm)}
              onEnter={handleSubmit(onSubmit)}
            />
          </Box>
        </Box>
        {error ? <Alert severity="error">{error}</Alert> : null}
        <Box>
          <FlexButton
            id="change-password-save-button"
            title="Speichern"
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
          />
        </Box>
      </Box>
    </>
  );
};

export default ChangePassword;
