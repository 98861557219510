import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FlexButton from "../../../components/button/FlexButton";
import { ButtonVariant } from "../../../components/button/FlexButton.interface";
import FormComponent from "../../../components/form/FormComponent";
import { Landline } from "../../../models/Landline";
import { READ_LANDLINE_URL } from "../../../services/apiRoutes";
import getItem from "../../../services/getItem";
import "../../../styles/Details.css";
import {
  handleInputChange,
  handleSaveChanges,
  initialData,
  validateFields,
} from "./landlineDetails.utils";
import sectionsData from "./landlineInputFields.json";

const LandlineDetail: React.FC = () => {
  const assetType = "landline";
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const [landline, setLandline] = useState<Landline | null>(null);
  const [editedLandline, setEditedLandline] = useState<Landline>(initialData);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isCreationMode] = useState<boolean>(
    location.pathname.includes("/create")
  );
  const [errors, setErrors] = useState<Set<string>>(new Set());

  useEffect(() => {
    const fetchLandline = async () => {
      if (id && !isCreationMode) {
        const data = await getItem<Landline>(READ_LANDLINE_URL, id);
        if (data) {
          setLandline(data);
          setEditedLandline({
            ...data,
          });
        } else {
          navigate("*", { replace: true });
        }
      }
    };

    fetchLandline();
  }, [id, isCreationMode]);

  const sections = sectionsData.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      ...field,
      // Securely access the value from editedLandline
      value: editedLandline[field.id as keyof Landline] ?? "",
      handleInputChange: ({
        id,
        value,
      }: {
        id: string;
        value: string | number | boolean;
      }) => {
        // Call the input change handler and update the editedLandline object
        handleInputChange(id, value, setEditedLandline, setErrors);
      },
    })),
  }));

  if (!landline && !isCreationMode) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="flex flex-between align-items-center">
        <h1 className="details-heading">
          {isCreationMode ? "Neu erstellen" : "Details"}
        </h1>
        {!isEditMode && !isCreationMode ? (
          <FlexButton
            id="edit-landline-button"
            variant={ButtonVariant.TEXT_OUTLINED}
            title={"Bearbeiten"}
            onClick={() => setIsEditMode(true)}
          />
        ) : (
          <FlexButton
            id="save-landline-button"
            variant={ButtonVariant.TEXT_FILLED}
            title={"Speichern"}
            onClick={() => {
              const areRequiredFieldsFilled = validateFields(
                editedLandline,
                setErrors
              );
              handleSaveChanges(
                areRequiredFieldsFilled,
                editedLandline,
                () => navigate(`/${assetType}`),
                isCreationMode,
                id
              );
              setIsEditMode(false);
            }}
          ></FlexButton>
        )}
      </div>

      <FormComponent
        sections={sections}
        editMode={isEditMode || isCreationMode}
        errors={errors}
      ></FormComponent>
    </>
  );
};

export default LandlineDetail;
