import { useEffect, useState } from "react";
import { AssignmentType } from "../../../components/assignment-modal/AssignmentModal.interface";
import OverviewHeader from "../../../components/overview-header/OverviewHeader";
import FlexTable from "../../../components/table/FlexTable";
import { MONITOR_TABLE_COLUMNS } from "../../../constants/tableColumns";
import { Monitor } from "../../../models/Monitor";
import { READ_MONITORS_URL } from "../../../services/apiRoutes";
import getItems from "../../../services/getItems";

/**
 * MonitorOverview component provides an overview of monitor records.
 * It fetches monitor data from an API and displays it in a table format,
 * complete with action buttons for additional functionality.
 *
 * @component
 * @returns {JSX.Element} A layout containing an overview header and a table of monitor data.
 */
const MonitorOverview = () => {
  const assetType = "monitor";
  const [items, setItems] = useState<Monitor[]>([]);

  /**
   * Fetches monitor data from the API when the component mounts.
   * Updates the state with the retrieved items.
   */
  useEffect(() => {
    (async () => {
      const data = await getItems<Monitor[]>(READ_MONITORS_URL);
      if (data) {
        setItems(data);
      }
    })();
  }, []);

  return (
    <>
      <OverviewHeader title="Monitore" assetType={assetType} />

      <FlexTable
        columns={MONITOR_TABLE_COLUMNS}
        rows={items}
        assetType={assetType}
        showAssignments
        hasActionButtons
        actionButtonRowProps={{
          assetType: assetType,
          hasManageAssignmentsAction: true,
          assignmentOptions: [
            { type: AssignmentType.EMPLOYEE, multiple: false },
            { type: AssignmentType.TABLE, multiple: false },
          ],
          hasQrCodeAction: true,
          hasExportAction: true,
          hasDeleteAction: true,
        }}
      />
    </>
  );
};

export default MonitorOverview;
