import { useEffect, useState } from "react";
import FlexTable from "../../components/table/FlexTable";
import {
  OFFBOARDING_TABLE_COLUMNS,
  ONBOARDING_TABLE_COLUMNS,
} from "../../constants/tableColumns";
import { OnOffboardingBE, OnOffboardingFE } from "../../models/OnOffboarding";
import {
  READ_OFFBOARDING_PEOPLE_URL,
  READ_ONBOARDING_PEOPLE_URL,
} from "../../services/apiRoutes";
import getItems from "../../services/getItems";
import { transformData } from "./Dashboard.utils";

/**
 * Dashboard component displays onboarding and offboarding data in a tabular format.
 *
 * The component fetches data from two endpoints:
 * - Onboarding people data
 * - Offboarding people data
 *
 * It allows the user to switch between onboarding and offboarding views using tabs.
 * The selected data is displayed in a table with columns defined by `DASHBOARD_TABLE_COLUMNS`.
 *
 * @returns {JSX.Element} The rendered Dashboard component.
 */
const Dashboard = () => {
  const [onboardingRows, setOnboardingRows] = useState<OnOffboardingFE[]>([]);
  const [offboardingRows, setOffboardingRows] = useState<OnOffboardingFE[]>([]);
  const employeeAssetType = "employee";

  useEffect(() => {
    /**
     * Fetches onboarding data and sets it in the state.
     * Transforms the raw data to a format suitable for display.
     */
    (async () => {
      const data = await getItems<OnOffboardingBE[]>(
        READ_ONBOARDING_PEOPLE_URL
      );
      if (data) setOnboardingRows(transformData(data));
    })();

    /**
     * Fetches offboarding data and sets it in the state.
     * Transforms the raw data to a format suitable for display.
     */
    (async () => {
      const data = await getItems<OnOffboardingBE[]>(
        READ_OFFBOARDING_PEOPLE_URL
      );
      if (data) setOffboardingRows(transformData(data));
    })();
  }, []);

  return (
    <>
      <h1>Dashboard</h1>
      <h2>Onboarding</h2>
      <FlexTable
        columns={ONBOARDING_TABLE_COLUMNS}
        rows={onboardingRows}
        assetType={employeeAssetType}
        showAssignments
      />
      <h2>Offboarding</h2>
      <FlexTable
        columns={OFFBOARDING_TABLE_COLUMNS}
        rows={offboardingRows}
        assetType={employeeAssetType}
        showAssignments
      />
    </>
  );
};

export default Dashboard;
