import { toast } from "react-toastify";
import { axiosCall } from "../api/config/axiosCall";

/**
 * Updates an item by sending a PUT request to the specified URL with the given ID and data.
 *
 * @template T - The type of the data to be sent in the PUT request.
 * @param {string} url - The base URL to which the PUT request is sent.
 * @param {string} id - The ID of the item to be updated.
 * @param {T} data - The data to be sent in the PUT request.
 * @returns {Promise<boolean>} A promise that resolves to `true` if the update was successful (status 200),
 * or `false` if there was an error during the request or if the status is not 200.
 */
const updateItem = async <T,>(
  url: string,
  id: string,
  data: T,
): Promise<boolean> => {
  try {
    const response = await axiosCall({
      endpoint: `${url}/${id}/`,
      method: "PUT",
      data,
    });
    toast.success("Erfolgreich aktualisiert");
    return response?.status === 200;
  } catch (error) {
    toast.error("Fehler beim Aktualisieren");
    return false;
  }
};

export default updateItem;
