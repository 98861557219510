import QrCode2Icon from "@mui/icons-material/QrCode2";
import RemoveRoundedIcon from "@mui/icons-material/RemoveRounded";
import { Box, Typography } from "@mui/material";
import { useState } from "react";
import useQRCodeStore from "../../stores/useQRCodeStore";
import FlexButton from "../button/FlexButton";
import { ButtonColor, ButtonVariant } from "../button/FlexButton.interface";
import MyModal from "../modal/MyModal";
import { useStyle } from "./QrCodeCollector.styles";
import { getGermanAssetType, handlePrint } from "./QrCodeCollector.utils";

/**
 * This component acts as a "shopping cart" for QR codes. It displays a floating button
 * with a counter showing the number of collected QR codes. The component is only
 * rendered when there is at least one QR code collected.
 *
 * The QR codes are getting added to this collector, when users click on an
 * "Add QR Code"-button elsewhere in the application.
 *
 * When clicked, the button opens a modal dialog that allows users to manage their
 * collected QR codes. Within this modal, users have the option to initiate a print action
 * for the codes (note that the print functionality is yet to be implemented).
 * @returns {JSX.Element} Rendered QR code collector component.
 */
const QrCodeCollector = () => {
  const style = useStyle();
  const [isPrintModalOpen, setIsPrintModalOpen] = useState(false); // State for handling modal open/close
  const qrCodeCount = useQRCodeStore((state) => state.getQrCodeCount()); // Get the count of QR codes
  const qrCodes = useQRCodeStore((state) => state.qrCodes); // Get the list of QR codes
  const removeQrCode = useQRCodeStore((state) => state.removeQrCode); // Function to remove a QR code
  const resetQrCodes = useQRCodeStore((state) => state.resetQrCodes); // Function to reset all QR codes
  const [, setIsHovered] = useState(false);

  const handleOpenPrinter = () => setIsPrintModalOpen(true);
  const handleClosePrinter = () => setIsPrintModalOpen(false);

  /** Create the modal content. */
  const getModalChildren = () => {
    return (
      <Box sx={style.qrCodePreview}>
        {qrCodes.map((code, index) => (
          <Box
            key={index}
            sx={style.qrCodeBox}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
          >
            <Box style={style.qrCodeItem}>
              <img
                style={style.qrCodeImg}
                src={`https://api.qrserver.com/v1/create-qr-code/?size=100x100&data=${code.assetType}:${code.assetId}`}
                alt={`${getGermanAssetType(code.assetType)} QR-Code`}
              />
              <Typography sx={style.qrCodeText}>
                {getGermanAssetType(code.assetType)}
                <br />
                {code.label.split(":")[1]}
              </Typography>
            </Box>

            <FlexButton
              id={`qr-code-modal-remove-${index}-button`}
              variant={ButtonVariant.ICON_FILLED}
              color={ButtonColor.CAUTION}
              icon={<RemoveRoundedIcon />}
              onClick={() => removeQrCode(code.assetType, code.assetId)}
              sx={style.qrCodeRemoveButton}
            />
          </Box>
        ))}
      </Box>
    );
  };

  /** Create the modal buttons. */
  const getModalButtons = () => (
    <Box sx={style.modalButtons}>
      <FlexButton
        id="qr-code-modal-remove-all-button"
        variant={ButtonVariant.TEXT_OUTLINED}
        color={ButtonColor.CAUTION}
        title="Alle entfernen"
        onClick={() => {
          resetQrCodes();
          handleClosePrinter();
        }}
      />
      <FlexButton
        id="qr-code-modal-download-button"
        title="Download"
        onClick={() => handlePrint(qrCodes, handleClosePrinter)}
      />
    </Box>
  );

  // If there are no QR codes, don't render the collector
  if (qrCodeCount === 0) return null;

  return (
    <>
      <Box style={style.collector}>
        <FlexButton
          id="qr-code-collector-button"
          variant={ButtonVariant.ICON_FILLED}
          icon={<QrCode2Icon />}
          onClick={handleOpenPrinter}
        />
        <span style={style.counter}>{qrCodeCount}</span>
      </Box>

        <MyModal
            title="QR-Codes drucken"
            isOpen={isPrintModalOpen}
            onClose={handleClosePrinter}
            buttons={getModalButtons()}
        >
            <Typography sx={style.text}>Für den Druck der QR Codes werden Etiketten der Größe 48,3 x 33,8 mm benötigt, bspw. Herma Premium 4200.</Typography>
            {getModalChildren()}
        </MyModal>
    </>
  );
};

export default QrCodeCollector;
