import { Box } from "@mui/material";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";

import { useLogin } from "../../api/hooks/auth/useLogin";
import { getErrorMessage } from "../../utils/form/convertInputErrorMessage";
import { validationLogin } from "../../utils/validations/validation-schemas";
import Alert from "../alert/Alert";
import FlexButton from "../button/FlexButton";
import CustomTextInputField from "../input/CustomTextField";
import { useStyle } from "./StandardLogin.styles";

/**
 * StandardLogin component renders a login form with email and password fields.
 * It handles form submission using the `useLogin` hook and performs validation with Yup.
 *
 * @component
 * @returns {JSX.Element} The rendered login form.
 */
const StandardLogin = () => {
  const styles = useStyle();
  const formOptions = { resolver: yupResolver(validationLogin) };
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm(formOptions);

  const { login, error, loading } = useLogin();

  // Function to handle form submission
  const onSubmit = login;

  return (
    <Box sx={styles.inputContainer}>
      <CustomTextInputField
        control={control}
        name="email"
        label="E-Mail-Adresse"
        type="email"
        error={!!errors.email}
        helperText={getErrorMessage(errors?.email)}
        onEnter={handleSubmit(onSubmit)}
      />
      <CustomTextInputField
        control={control}
        name="password"
        label="Passwort"
        type="password"
        error={!!errors.password}
        helperText={getErrorMessage(errors?.password)}
        onEnter={handleSubmit(onSubmit)}
      />
      {error ? <Alert severity="error">{error}</Alert> : null}
      <Box>
        <FlexButton
          id="login-button"
          title="Login"
          disabled={loading}
          onClick={handleSubmit(onSubmit)}
          sx={styles.button}
        />
      </Box>
    </Box>
  );
};

export default StandardLogin;
