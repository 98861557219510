import { useEffect, useState } from "react";
import { AssignmentType } from "../../../components/assignment-modal/AssignmentModal.interface";
import OverviewHeader from "../../../components/overview-header/OverviewHeader";
import FlexTable from "../../../components/table/FlexTable";
import { COMPUTER_TABLE_COLUMNS } from "../../../constants/tableColumns";
import { Computer } from "../../../models/Computer";
import { READ_COMPUTERS_URL } from "../../../services/apiRoutes";
import getItems from "../../../services/getItems";

/**
 * ComputerOverview component displays an overview of computers.
 * It fetches computer data and displays it in a table with action buttons.
 *
 * @component
 * @returns {JSX.Element} A layout containing the overview header and a flexible table with computer data.
 */
const ComputerOverview = () => {
  const assetType = "computer";
  const [items, setItems] = useState<Computer[]>([]);

  /**
   * Fetches computer data from the API and sets the items state.
   */
  useEffect(() => {
    (async () => {
      const data = await getItems<Computer[]>(READ_COMPUTERS_URL);
      if (data) {
        setItems(data);
      }
    })();
  }, []);

  return (
    <>
      <OverviewHeader title={"Computer"} assetType={assetType} />

      <FlexTable
        columns={COMPUTER_TABLE_COLUMNS}
        rows={items}
        assetType={assetType}
        showAssignments
        hasActionButtons
        actionButtonRowProps={{
          assetType: assetType,
          hasManageAssignmentsAction: true,
          assignmentOptions: [
            { type: AssignmentType.EMPLOYEE, multiple: true },
            { type: AssignmentType.TABLE, multiple: false },
          ],
          hasQrCodeAction: true,
          hasExportAction: true,
          hasDeleteAction: true,
        }}
      />
    </>
  );
};

export default ComputerOverview;
