import { BrowserRouter, Route, Routes } from "react-router-dom";
import Toast from "./components/Toast";
import { routes } from "./navigation/routes";
import {
  AccessoryDetail,
  AccessoryOverview,
  BuildingDetail,
  BuildingOverview,
  ComputerDetail,
  ComputerOverview,
  ContractDetails,
  ContractOverview,
  Dashboard,
  DooraccessDetail,
  DooraccessOverview,
  EmployeeDetail,
  EmployeeOverview,
  ErrorPage,
  LandlineContractDetail,
  LandlineContractOverview,
  LandlineDetail,
  LandlineOverview,
  LicenseDetails,
  LicenseOverview,
  LiteratureDetail,
  LiteratureOverview,
  LocationDetail,
  LocationOverview,
  Login,
  MobilephoneDetail,
  MobilephoneOverview,
  MonitorDetails,
  MonitorOverview,
  NetworkDeviceDetail,
  NetworkDeviceOverview,
  RoomDetail,
  RoomOverview,
  ServerDetail,
  ServerOverview,
  TableDetail,
  TableOverview,
  VirtualmachineDetail,
  VirtualmachineOverview,
} from "./pages";

import { ProtectedLayout, PublicLayout } from "./navigation";
import Profile from "./pages/profile/Profile";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={<PublicLayout />}>
          <Route path={routes.login} element={<Login />} />
        </Route>
        <Route element={<ProtectedLayout />}>
          <Route path="*" element={<ErrorPage />} />

          <Route path={routes.dashboard} element={<Dashboard />} />

          <Route path="/employee">
            <Route index element={<EmployeeOverview />} />
            <Route path=":id" element={<EmployeeDetail />} />
            <Route path="create" element={<EmployeeDetail />} />
          </Route>

          <Route path={routes.profile} element={<Profile />} />

          <Route path="/accessory">
            <Route index element={<AccessoryOverview />} />
            <Route path=":id" element={<AccessoryDetail />} />
            <Route path="create" element={<AccessoryDetail />} />
          </Route>

          <Route path="/building">
            <Route index element={<BuildingOverview />} />
            <Route path=":id" element={<BuildingDetail />} />
            <Route path="create" element={<BuildingDetail />} />
          </Route>

          <Route path="/computer">
            <Route index element={<ComputerOverview />} />
            <Route path=":id" element={<ComputerDetail />} />
            <Route path="create" element={<ComputerDetail />} />
          </Route>

          <Route path="/contract">
            <Route index element={<ContractOverview />} />
            <Route path=":id" element={<ContractDetails />} />
            <Route path="create" element={<ContractDetails />} />
          </Route>

          <Route path="/dooraccess">
            <Route index element={<DooraccessOverview />} />
            <Route path=":id" element={<DooraccessDetail />} />
            <Route path="create" element={<DooraccessDetail />} />
          </Route>

          <Route path="landline">
            <Route index element={<LandlineOverview />} />
            <Route path=":id" element={<LandlineDetail />} />
            <Route path="create" element={<LandlineDetail />} />
          </Route>

          <Route path="/landlineContract">
            <Route index element={<LandlineContractOverview />} />
            <Route path=":id" element={<LandlineContractDetail />} />
            <Route path="create" element={<LandlineContractDetail />} />
          </Route>

          <Route path="/license">
            <Route index element={<LicenseOverview />} />
            <Route path=":id" element={<LicenseDetails />} />
            <Route path="create" element={<LicenseDetails />} />
          </Route>

          <Route path="/literature">
            <Route index element={<LiteratureOverview />} />
            <Route path=":id" element={<LiteratureDetail />} />
            <Route path="create" element={<LiteratureDetail />} />
          </Route>

          <Route path="/location">
            <Route index element={<LocationOverview />} />
            <Route path=":id" element={<LocationDetail />} />
            <Route path="create" element={<LocationDetail />} />
          </Route>

          <Route path="/mobilephone">
            <Route index element={<MobilephoneOverview />} />
            <Route path=":id" element={<MobilephoneDetail />} />
            <Route path="create" element={<MobilephoneDetail />} />
          </Route>

          <Route path="/monitor">
            <Route index element={<MonitorOverview />} />
            <Route path=":id" element={<MonitorDetails />} />
            <Route path="create" element={<MonitorDetails />} />
          </Route>

          <Route path="/networkDevice">
            <Route index element={<NetworkDeviceOverview />} />
            <Route path=":id" element={<NetworkDeviceDetail />} />
            <Route path="create" element={<NetworkDeviceDetail />} />
          </Route>

          <Route path="/room">
            <Route index element={<RoomOverview />} />
            <Route path=":id" element={<RoomDetail />} />
            <Route path="create" element={<RoomDetail />} />
          </Route>

          <Route path="/server">
            <Route index element={<ServerOverview />} />
            <Route path=":id" element={<ServerDetail />} />
            <Route path="create" element={<ServerDetail />} />
          </Route>

          <Route path="/table">
            <Route index element={<TableOverview />} />
            <Route path=":id" element={<TableDetail />} />
            <Route path="create" element={<TableDetail />} />
          </Route>

          <Route path="/virtualmachine">
            <Route index element={<VirtualmachineOverview />} />
            <Route path=":id" element={<VirtualmachineDetail />} />
            <Route path="create" element={<VirtualmachineDetail />} />
          </Route>
        </Route>
      </Routes>
      <Toast />
    </BrowserRouter>
  );
};
export default App;
