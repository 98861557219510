import { useEffect, useState } from "react";
import { AssignmentType } from "../../../components/assignment-modal/AssignmentModal.interface";
import OverviewHeader from "../../../components/overview-header/OverviewHeader";
import FlexTable from "../../../components/table/FlexTable";
import { CONTRACT_TABLE_COLUMNS } from "../../../constants/tableColumns";
import { Contract } from "../../../models/Contract";
import { READ_CONTRACTS_URL } from "../../../services/apiRoutes";
import getItems from "../../../services/getItems";

/**
 * ContractOverview component displays an overview of contracts.
 * It fetches contract data and displays it in a table with action buttons.
 *
 * @component
 * @returns {JSX.Element} A layout containing the overview header and a flexible table with contract data.
 */
const ContractOverview = () => {
  const assetType = "contract";
  const [items, setItems] = useState<Contract[]>([]);

  /**
   * Fetches contract data from the API and sets the items state.
   */
  useEffect(() => {
    (async () => {
      const data = await getItems<Contract[]>(READ_CONTRACTS_URL);
      if (data) {
        setItems(data);
      }
    })();
  }, []);

  return (
    <>
      <OverviewHeader title="Mobilfunkverträge" assetType={assetType} />

      <FlexTable
        columns={CONTRACT_TABLE_COLUMNS}
        rows={items}
        assetType={assetType}
        showAssignments
        hasActionButtons
        actionButtonRowProps={{
          assetType: assetType,
          hasManageAssignmentsAction: true,
          assignmentOptions: [
            { type: AssignmentType.EMPLOYEE, multiple: false },
            { type: AssignmentType.MOBILEPHONE, multiple: true },
          ],
          hasQrCodeAction: false,
          hasExportAction: true,
          hasDeleteAction: true,
        }}
      />
    </>
  );
};

export default ContractOverview;
