/**
 * `routes` object defines the route paths used for navigation within the React application.
 * It maps route names to their respective URL paths.
 * This object is intended for use with React Router client-side
 * routing libraries.
 *
 * @constant {Object} routes
 */
export const routes = {
  dashboard: "/",
  login: "/login",
  profile: "/profile",
};
