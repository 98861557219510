export const useStyle = () => {
  return {
    modal: {
      position: "absolute" as const,
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      padding: "24px",
      backgroundColor: "white",
      border: "none",
      borderRadius: "8px",
      display: "flex",
      flexDirection: "column" as const,
      gap: "24px",
      maxWidth: "578px",
    },

    header: {
      display: "flex",
      justifyContent: "space-between",
      gap: "16px",
    },

    closeButton: {
      minWidth: "revert",
      padding: "4px",
    },

    closeButtonIcon: {
      color: "#001F26",
    },

    children: {
      flexGrow: 1,
    },

    buttonGroup: {
      display: "flex",
      gap: "8px",
      width: "100%",

      "& > *": {
        flexGrow: 1,
      },
    },
  };
};
