import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Modal, Typography } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { MyModalProps } from "./MyModal.interface";
import { useStyle } from "./MyModal.styles";

const MyModal = (props: MyModalProps) => {
  const style = useStyle();
  const modalRef = useRef<HTMLDivElement>(null);
  const [modalSize, setModalSize] = useState<{
    width: number;
    height: number;
  } | null>(null);

  useEffect(() => {
    if (props.isOpen && modalRef.current) {
      // Mesure the current model size.
      const { offsetWidth, offsetHeight } = modalRef.current;
      // Set the current model size.
      setModalSize({
        width: offsetWidth,
        height: offsetHeight,
      });
    } else {
      setModalSize(null); // Reset model size.
    }
  }, [props.isOpen, modalRef.current]);

  return (
    <Modal open={props.isOpen} onClose={props.onClose}>
      <Box
        ref={modalRef}
        style={{
          ...style.modal,
          width: modalSize ? `${modalSize.width}px` : "auto",
          height: modalSize ? `${modalSize.height}px` : "auto",
        }}
      >
        <Box style={style.header}>
          <Typography variant="h6" component="h2">
            {props.title}
          </Typography>
          <Button
            id={`${props.title}-modal-close-button`}
            style={style.closeButton}
            onClick={props.onClose}
          >
            <CloseIcon style={style.closeButtonIcon} />
          </Button>
        </Box>

        {props.children && <Box style={style.children}>{props.children}</Box>}
        <Box sx={style.buttonGroup}>{props.buttons}</Box>
      </Box>
    </Modal>
  );
};

export default MyModal;
