import { useEffect, useState } from "react";
import { AssignmentType } from "../../../components/assignment-modal/AssignmentModal.interface";
import OverviewHeader from "../../../components/overview-header/OverviewHeader";
import FlexTable from "../../../components/table/FlexTable";
import { SERVER_TABLE_COLUMNS } from "../../../constants/tableColumns";
import { Server } from "../../../models/Server";
import { READ_SERVERS_URL } from "../../../services/apiRoutes";
import getItems from "../../../services/getItems";

/**
 * ServerOverview component displays a list of servers in a tabular format.
 * It fetches server data from an API endpoint and renders it using the FlexTable component.
 * Users can perform actions like adding QR codes, deleting, or viewing details of servers.
 *
 * @component
 * @returns {JSX.Element} A layout containing an overview header and a table of server data.
 */
const ServerOverview = () => {
  const assetType = "server";
  const [items, setItems] = useState<Server[]>([]);

  /**
   * Fetches server data from the API when the component mounts.
   * Updates the state with the retrieved server data.
   */
  useEffect(() => {
    (async () => {
      const data = await getItems<Server[]>(READ_SERVERS_URL);
      if (data) {
        setItems(data);
      }
    })();
  }, []);

  return (
    <>
      <OverviewHeader title="Server" assetType={assetType} />

      <FlexTable
        columns={SERVER_TABLE_COLUMNS}
        rows={items}
        assetType={assetType}
        showAssignments
        hasActionButtons
        actionButtonRowProps={{
          assetType: assetType,
          hasManageAssignmentsAction: true,
          assignmentOptions: [{ type: AssignmentType.ROOM, multiple: false }],
          hasQrCodeAction: true,
          hasExportAction: true,
          hasDeleteAction: true,
        }}
      />
    </>
  );
};

export default ServerOverview;
