import * as Yup from "yup";

import { fields } from "./validation-fields";

/**
 * Validation schema for email-only forms.
 * @type {Yup.ObjectSchema}
 * @property {Yup.StringSchema} email - Uses the email validation schema from `fields`.
 */
export const validationEmail = Yup.object().shape({
  email: fields.email,
});

/**
 * Validation schema for login forms.
 * @type {Yup.ObjectSchema}
 * @property {Yup.StringSchema} email - Uses the email validation schema from `fields`.
 * @property {Yup.StringSchema} password - Uses the password validation schema from `fields`.
 */
export const validationLogin = Yup.object().shape({
  email: fields().email,
  password: fields().password,
});

/**
 * Validation schema for the change password form.
 * This schema validates three fields: the old password, the new password, and the confirmation of the new password.
 *
 * @type {Yup.ObjectSchema}
 * @property {Yup.StringSchema} password - Uses the password validation schema from `fields`. This represents the old password.
 * @property {Yup.StringSchema} newPassword - Uses the new password validation schema from `fields`. This represents the new password the user wants to set.
 * @property {Yup.StringSchema} newPasswordConfirm - Validates that the new password confirmation matches the `newPassword`.
 */
export const validationChangePassword = Yup.object().shape({
  oldPassword: fields().password,
  newPassword: fields().newPassword,
  newPasswordConfirm: fields().newPasswordConfirm,
});
