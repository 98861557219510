import { Alert as MAlert } from "@mui/material";
import { useStyle } from "./Alert.styles";

/**
 * AlertProps - Interface for Alert component props.
 *
 * @interface AlertProps
 * @property {"success" | "info" | "warning" | "error"} severity - The severity level of the alert.
 * @property {string} text - The text content to be displayed inside the alert.
 */
interface AlertProps {
  severity: "success" | "info" | "warning" | "error";
  children: string;
}

/**
 * Alert - A custom Alert component that displays an MUI Alert with specified severity and text.
 *
 * @param {Object} props - The props for the Alert component.
 * @param {"success" | "info" | "warning" | "error"} props.severity - The severity level of the alert.
 *  - `"success"`: Indicates a successful or positive action.
 *  - `"info"`: Provides informational messages.
 *  - `"warning"`: Warns about a potential problem.
 *  - `"error"`: Alerts about an error or serious issue.
 * @param {string} props.text - The text content to be displayed inside the alert.
 * @returns {JSX.Element} The rendered MUI Alert component.
 */
const Alert = (props: AlertProps) => {
  const styles = useStyle();
  return (
    <MAlert severity={props.severity} sx={styles.alert}>
      {props.children}
    </MAlert>
  );
};

export default Alert;
