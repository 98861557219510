import { Typography, Box } from "@mui/material";

/**
 * ErrorPage Component
 *
 * This component renders a simple error page within the application's layout.
 * It is intended to be displayed when an error occurs or when a user navigates
 * to a route that does not exist.
 * The page includes a message indicating that an error has occurred.
 *
 * @returns {JSX.Element} - A JSX element that renders the error page.
 */
const ErrorPage = () => {
  return (
    <Box>
      <Typography variant="h2">404</Typography>
      <Typography>Seite konnte nicht gefunden werden.</Typography>
    </Box>
  );
};

export default ErrorPage;
