import { Box } from "@mui/material";
import FlexButton from "../button/FlexButton";
import { ButtonVariant } from "../button/FlexButton.interface";
import { ButtonGroupProps } from "./ButtonGroup.interface";
import { useStyle } from "./ButtonGroup.styles";

/**
 * ButtonGroup component renders a group of FlexButton components.
 * It takes an array of options and displays each option as a button
 * with an associated icon.
 *
 * @param {ButtonGroupProps} props - The properties for the ButtonGroup component.
 * @param {ButtonGroupOption[]} props.options - An array of button options to render.
 * @param {ReactElement} props.options.icon - The icon to display inside the button.
 * @param {function} props.options.onClick - The function to call when the button is clicked.
 * @returns {JSX.Element} A div containing the rendered FlexButton components,
 * each representing an option from the provided array.
 */
const ButtonGroup = (props: ButtonGroupProps) => {
  const style = useStyle();

  return (
    <Box style={style.buttonGroup}>
      {props.options.map((option, index) => (
        <FlexButton
          id={option.id}
          key={index}
          variant={ButtonVariant.ICON_ROUNDED}
          icon={option.icon}
          onClick={option.onClick}
        />
      ))}
    </Box>
  );
};

export default ButtonGroup;
