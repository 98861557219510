export const useStyle = () => {
  return {
    table: {
      width: "100%",
      overflowX: "scroll",

      "& tbody tr:hover": {
        backgroundColor: "#F5FAFC75",
      },

      "& th": {
        fontSize: "16px",
        color: "#001F26",
      },

      "& td": {
        fontSize: "16px",
        color: "#001F26",
        borderBottom: "none",
        borderTop: "1px solid #E0E0E0",
      },
    },

    iconColor: {
      color: "#001F26",
    },
  };
};
