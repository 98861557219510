import { useEffect, useState } from "react";
import { AssignmentType } from "../../../components/assignment-modal/AssignmentModal.interface";
import OverviewHeader from "../../../components/overview-header/OverviewHeader";
import FlexTable from "../../../components/table/FlexTable";
import { LANDLINE_CONTRACT_TABLE_COLUMNS } from "../../../constants/tableColumns";
import { LandlineContract } from "../../../models/LandlineContract";
import { READ_LANDLINECONTRACTS_URL } from "../../../services/apiRoutes";
import getItems from "../../../services/getItems";

/**
 * LandlineContractOverview component displays an overview of landline contract records.
 * It fetches landline contract data and displays it in a table with action buttons.
 *
 * @component
 * @returns {JSX.Element} A layout containing the overview header and a flexible table with landline contract data.
 */
const LandlineContractOverview = () => {
  const assetType = "landlineContract";
  const [items, setItems] = useState<LandlineContract[]>([]);

  /**
   * Fetches landline contract data from the API and updates the items state.
   */
  useEffect(() => {
    (async () => {
      const data = await getItems<LandlineContract[]>(
        READ_LANDLINECONTRACTS_URL
      );
      if (data) {
        setItems(data);
      }
    })();
  }, []);

  return (
    <>
      <OverviewHeader title="Festnetzverträge" assetType={assetType} />

      <FlexTable
        columns={LANDLINE_CONTRACT_TABLE_COLUMNS}
        rows={items}
        assetType={assetType}
        showAssignments
        hasActionButtons
        actionButtonRowProps={{
          assetType: assetType,
          hasManageAssignmentsAction: true,
          assignmentOptions: [
            { type: AssignmentType.EMPLOYEE, multiple: true },
            { type: AssignmentType.LANDLINE, multiple: true },
          ],
          hasQrCodeAction: false,
          hasExportAction: true,
          hasDeleteAction: true,
        }}
      />
    </>
  );
};

export default LandlineContractOverview;
