import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FlexButton from "../../../components/button/FlexButton";
import { ButtonVariant } from "../../../components/button/FlexButton.interface";
import FormComponent from "../../../components/form/FormComponent";
import { Table } from "../../../models/Table";
import { READ_TABLE_URL } from "../../../services/apiRoutes";
import getItem from "../../../services/getItem";
import "../../../styles/Details.css";
import {
  handleInputChange,
  handleSaveChanges,
  initialData,
  validateFields,
} from "./tableDetail.utils";
import sectionsData from "./tableInputFields.json";

const TableDetail: React.FC = () => {
  const assetType = "table";
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const [table, setTable] = useState<Table | null>(null);
  const [editedTable, setEditedTable] = useState<Table>(initialData);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isCreationMode] = useState<boolean>(
    location.pathname.includes("/create")
  );
  const [errors, setErrors] = useState<Set<string>>(new Set());

  useEffect(() => {
    const fetchTable = async () => {
      if (id && !isCreationMode) {
        const data = await getItem<Table>(READ_TABLE_URL, id);
        if (data) {
          setTable(data);
          setEditedTable({
            ...data,
          });
        } else {
          navigate("*", { replace: true });
        }
      }
    };

    fetchTable();
  }, [id, isCreationMode]);

  const sections = sectionsData.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      ...field,
      value: editedTable[field.id as keyof Table],
      handleInputChange: ({
        id,
        value,
      }: {
        id: string;
        value: string | number | boolean;
      }) => {
        handleInputChange(id, value, setEditedTable, setErrors);
      },
    })),
  }));

  if (!table && !isCreationMode) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="flex flex-between align-items-center">
        <h1 className="details-heading">
          {isCreationMode ? "Neu erstellen" : "Details"}{" "}
        </h1>
        {!isEditMode && !isCreationMode ? (
          <FlexButton
            id="edit-table-button"
            variant={ButtonVariant.TEXT_OUTLINED}
            title={"Bearbeiten"}
            onClick={() => setIsEditMode(true)}
          />
        ) : (
          <FlexButton
            id="save-table-button"
            variant={ButtonVariant.TEXT_FILLED}
            title={"Speichern"}
            onClick={() => {
              const areRequiredFieldsFilled = validateFields(
                editedTable,
                setErrors
              );
              handleSaveChanges(
                areRequiredFieldsFilled,
                editedTable,
                () => navigate(`/${assetType}`),
                isCreationMode,
                id
              );
              setIsEditMode(false);
            }}
          />
        )}
      </div>

      <FormComponent
        sections={sections}
        editMode={isEditMode || isCreationMode}
        errors={errors}
      />
    </>
  );
};

export default TableDetail;
