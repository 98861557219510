import { FieldError, FieldErrorsImpl, Merge } from "react-hook-form";

/**
 * getErrorMessage - Extracts the error message from a FieldError or a merged FieldError object.
 *
 * This utility function is used to safely extract a string error message from the error object
 * provided by react-hook-form. It handles both simple FieldError objects and more complex merged
 * error structures, ensuring that a string or undefined is returned as the error message.
 *
 * @param {FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined} error -
 * The error object from react-hook-form validation.
 * @returns {string | undefined} - The error message if present, or undefined if no message exists.
 */
export const getErrorMessage = (
  error: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined
): string | undefined => {
  return error ? (error.message as string) : undefined;
};
