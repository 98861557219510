import React, { Dispatch } from "react";
import { toast } from "react-toastify";
import { LandlineContract } from "../../../models/LandlineContract";
import {
  CREATE_LANDLINECONTRACT_URL,
  UPDATE_LANDLINECONTRACT_URL,
} from "../../../services/apiRoutes";
import createItem from "../../../services/createItem";
import updateItem from "../../../services/updateItem";
import sectionsData from "./landlineContractInputFields.json";

export const initialData = {
  comment: "",
  computed_monthly_cost: "",
  contractEnd: null,
  contractNr: "",
  contractStart: null,
  customerNr: "",
  discountDuration: "",
  identifier: "",
  phone_number_intern: "",
  phone_number_extern: "",
  minimumContractDuration: "",
  monthlyCost: null,
  monthlyDiscount: null,
  periodOfNotice: "",
  pin: 0,
  provider: "",
};

/**
 * Handles input change events and clears validation indicators after
 * a required input isn't empty anymore.
 * @param id Input field id.
 * @param value New Value of input field.
 * @param setEditedLandlineContract Setter for >editedLandlineContract<
 * @param setErrors Setter for >errors<
 */
export const handleInputChange = (
  id: string,
  value: any,
  setEditedLandlineContract: Dispatch<React.SetStateAction<LandlineContract>>,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>,
) => {
  if (
    (id === "monthlyDiscount" ||
      id === "monthlyCost" ||
      id === "computed_monthly_cost") &&
    typeof value === "string"
  ) {
    value = value.replace(",", ".");
  }
  setEditedLandlineContract((prevData) => ({
    ...prevData,
    [id]: value,
  }));

  // If input changes to valid: Remove error.
  setErrors((prevErrors) => {
    const newErrors = new Set(prevErrors);
    if (value) {
      newErrors.delete(id);
    }
    return newErrors;
  });
};

/**
 * If all required fields are filled: Handles the save action.
 * Either updates an existing item or creates a new one.
 * @param areRequiredFieldsFilled True if all required fields are filled.
 * @param editedLandlineContract LandlineContract item that gets edited.
 * @param navigateCallback
 * @param isNew True if it is the creation of a new item.
 * @param id LandlineContract id.
 */
export const handleSaveChanges = async (
  areRequiredFieldsFilled: boolean,
  editedLandlineContract: LandlineContract,
  navigateCallback: () => void,
  isNew: boolean,
  id?: string,
) => {
  // Save only if required fields aren't empty.
  if (areRequiredFieldsFilled) {
    if (isNew) {
      await createItem<LandlineContract>(
        CREATE_LANDLINECONTRACT_URL,
        editedLandlineContract,
      );
      // After creating a new element, go back to overview page.
      navigateCallback();
    } else if (id) {
      await updateItem<LandlineContract>(
        UPDATE_LANDLINECONTRACT_URL,
        id,
        editedLandlineContract,
      );
    }
  } else {
    toast.error("Es sind noch nicht alle Pflichtfelder ausgefüllt.");
  }
};

/**
 * Checks if all required fields are filled.
 * @param editedLandlineContract LandlineContract item that gets edited.
 * @param setErrors Setter for >errors<
 * @returns TRUE: All required fields are filled. FALSE: Some required fields are empty.
 */
export const validateFields = (
  editedLandlineContract: LandlineContract,
  setErrors: Dispatch<React.SetStateAction<Set<string>>>,
) => {
  const newErrors = new Set<string>();

  sectionsData.forEach((section) => {
    section.fields.forEach((field) => {
      const value = editedLandlineContract[field.id as keyof LandlineContract];

      // If nothing in there, then add error.
      if (field.required && !value) {
        newErrors.add(field.id);
      }
    });
  });

  setErrors(newErrors);
  return newErrors.size === 0;
};
