import { toast } from "react-toastify";
import { axiosCall } from "../api/config/axiosCall";

/**
 * Retrieves a list of items by sending a GET request to the specified URL.
 *
 * @template T - The type of the response data expected from the GET request.
 * @param {string} url - The URL to which the GET request is sent.
 * @returns {Promise<T | null>} A promise that resolves to the list of items if the request is successful,
 * or `null` if there is an error during the request.
 */
const getItems = async <T,>(url: string): Promise<T | null> => {
  try {
    const response = await axiosCall({ endpoint: url, method: "GET" });
    return response?.data;
  } catch (error) {
    toast.error(`Fehler beim Abrufen der Daten von ${url}`);
    return null;
  }
};

export default getItems;
