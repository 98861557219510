import axios from "axios";
import { toast } from "react-toastify";
import { BASE_URL } from "./apiRoutes";

export enum ExportType {
  PDF,
  CSV,
}

const ExportTypeDetails = {
  [ExportType.PDF]: {
    url: "pdf",
    suffix: "pdf",
  },
  [ExportType.CSV]: {
    url: "csv",
    suffix: "csv",
  },
};

export const exportFile = async (
  exportType: ExportType,
  assetType: string,
  rowId: string
): Promise<void> => {
  try {
    let url = '';
    if (rowId === "all") {
      url = `${BASE_URL}/${assetType}-export/export_all_${ExportTypeDetails[exportType].url}/`;  
    } else {
      url = `${BASE_URL}/${assetType}-export/${rowId}/export_${ExportTypeDetails[exportType].url}/`; 
    }
    const response = await axios.get(url, {
      responseType: "blob",
    });
    const blob = new Blob([response.data]);
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute(
      "download",
      `${assetType}_${rowId}.${ExportTypeDetails[exportType].suffix}`
    );
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    toast.error("Fehler beim Exportieren. Bitte später erneut versuchen.");
    console.error("Fehler beim Exportieren:", error);
  }
};
