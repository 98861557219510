import { SelectChangeEvent } from "@mui/material";
import { toast } from "react-toastify";
import { BASE_URL } from "../../services/apiRoutes";
import createItem from "../../services/createItem";
import deleteItem from "../../services/deleteItem";
import { Assignment, SelectedAssignment } from "./AssignmentModal.interface";

/**
 * Updates the selected assignments (directly after selecting the option).
 * @param currentAssignment The current assignment object.
 * @param event The browser event.
 * @param setSelectedAssignments Setter for >selectedAssignments<.
 */
export const handleOptionChange = (
  currentAssignment: Assignment,
  event: SelectChangeEvent<string[]>,
  setSelectedAssignments: React.Dispatch<
    React.SetStateAction<SelectedAssignment[]>
  >
) => {
  // Array of ids of the selected options.
  const value = event.target.value;
  const selectedOptionIds =
    typeof value === "string" ? value.split(",") : value;

  setSelectedAssignments((prevSelectedAssignments) => {
    // At first: Find the whole objects to the given ids.
    let selectedOptions = currentAssignment.options.filter((option) =>
      selectedOptionIds.includes(option.id)
    );

    // Check if there is already an entry for this assignment type in the array of selectedAssignments.
    // We need this a little bit more complex code, because we dont just have a simple variable,
    // where we temporarily hold the selected option til it gets saved via api call.
    // Instead we have an array for all the different dropdowns, where all the different options get
    // temporarily saved (but only the ones that the user updates).
    // And when the user clicks save, all different temporarily saved selected options (only the ones
    // that get updated through the user) finally get saved via api calls.
    // Example: So if a user updates dropdown1 but not dropdown2, only the selected option of dropdown1
    // gets pushed to the selectedAssignments.
    const existingSelectedAssignmentIndex = prevSelectedAssignments.findIndex(
      (selected) => selected.label === currentAssignment.label
    );

    // If an entry for this type is already existing in the selectedAssignments:
    // Just update the existing object.
    if (existingSelectedAssignmentIndex !== -1) {
      return prevSelectedAssignments.map((selected, index) =>
        index === existingSelectedAssignmentIndex
          ? {
              ...selected, // label, type
              selectedOptions: selectedOptions.map((option) => ({
                id: option.id,
                label: option.label,
              })),
            }
          : selected
      );
    } else {
      // If not existing: Add assignment to array of selectedAssignments.
      return [
        ...prevSelectedAssignments,
        {
          label: currentAssignment.label,
          type: currentAssignment.type,
          selectedOptions: selectedOptions.map((option) => ({
            id: option.id,
            label: option.label,
          })),
        },
      ];
    }
  });
};

/**
 * Saves the new selected assignment options.
 * @param assignments All assignments.
 * @param selectedAssignments Selected assignments.
 */
export const handleSave = async (
  assignments: Assignment[],
  selectedAssignments: SelectedAssignment[],
  assetType: string,
  assetId: string,
  onClose: () => void,
  onSave: () => void
) => {
  try {
    let showDeleteToast = true;
    let showCreateToast = true;

    for (const selectedAssignment of selectedAssignments) {
      // At first: Find the corresponding assignment.
      const correspondingAssignment = assignments.find(
        (assignment) => assignment.label === selectedAssignment.label
      );

      // If null/undefined: Stop the whole async code. Something is really wrong here.
      if (!correspondingAssignment) {
        throw new Error(
          ">correspondingAssignment< can actually not be null/undefined, since the selected option is based on the assignments.\n"
        );
      }

      const currentOptions = correspondingAssignment.selectedOptions || [];
      const newOptions = selectedAssignment.selectedOptions || [];

      // Handle additions
      const optionsToAdd = newOptions.filter(
        (newOption) =>
          !currentOptions.some(
            (currentOption) => currentOption.id === newOption.id
          )
      );
      // Handle deletions
      const optionsToRemove = currentOptions.filter(
        (currentOption) =>
          !newOptions.some((newOption) => newOption.id === currentOption.id)
      );

      // Remove options
      for (const optionToRemove of optionsToRemove) {
        const deleteUrl = `${BASE_URL}/assign/${assetType}/${assetId}/to/${correspondingAssignment.type}/${optionToRemove.id}/delete`;
        await deleteItem<any>(
          deleteUrl,
          "",
          false,
          showDeleteToast,
          "Zuweisung(en) erfolgreich entfernt"
        );
        showDeleteToast = false;
      }
      // Add new options
      for (const optionToAdd of optionsToAdd) {
        const addUrl = `${BASE_URL}/assign/${assetType}/${assetId}/to/${correspondingAssignment.type}/${optionToAdd.id}/`;
        await createItem<any>(
          addUrl,
          null,
          showCreateToast,
          "Zuweisung(en) erfolgreich hinzugefügt"
        );
        showCreateToast = false;
      }
    }
    onSave();
  } catch (error) {
    console.error(error);
    toast.error("Speichern fehlgeschlagen. Bitte nochmal probieren.");
  } finally {
    onClose();
  }
};
