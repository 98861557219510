import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import FlexButton from "../../components/button/FlexButton";
import { ButtonVariant } from "../../components/button/FlexButton.interface";
import FormComponent from "../../components/form/FormComponent";
import { Employee } from "../../models/Employee";
import { READ_EMPLOYEE_URL } from "../../services/apiRoutes";
import getItem from "../../services/getItem";
import "../../styles/Details.css";
import {
  handleInputChange,
  handleSaveChanges,
  initialData,
  validateFields,
} from "./Employee.utils";
import sectionsData from "./employeeInputFields.json";

const EmployeeDetails: React.FC = () => {
  const assetType = "employee";
  const { id } = useParams<{ id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const [employee, setEmployee] = useState<Employee>();
  const [editedEmployee, setEditedEmployee] = useState<Employee>(initialData);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const isCreationMode = location.pathname.includes("/create");
  const [errors, setErrors] = useState<Set<string>>(new Set());

  useEffect(() => {
    const fetchEmployee = async () => {
      if (id && !isCreationMode) {
        const data = await getItem<Employee>(READ_EMPLOYEE_URL, id);
        if (data) {
          setEmployee(data);
          setEditedEmployee({
            ...data,
          });
        }
      }
    };

    fetchEmployee();
  }, [id, isCreationMode]);

  const sections = sectionsData.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      ...field,
      value: editedEmployee[field.id as keyof Employee],
      handleInputChange: ({
        id,
        value,
      }: {
        id: string;
        value: string | number | boolean;
      }) => {
        handleInputChange(id, value, setEditedEmployee, setErrors);
      },
    })),
  }));

  if (!employee && !isCreationMode) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="flex flex-between align-items-center">
        <h1 className="details-heading">
          {isCreationMode ? "Neu erstellen" : "Details"}{" "}
        </h1>
        {!isEditMode && !isCreationMode ? (
          <FlexButton
            id="edit-employee-button"
            variant={ButtonVariant.TEXT_OUTLINED}
            title={"Bearbeiten"}
            onClick={() => setIsEditMode(true)}
          />
        ) : (
          <FlexButton
            id="save-employee-button"
            variant={ButtonVariant.TEXT_FILLED}
            title={"Speichern"}
            onClick={() => {
              const areRequiredFieldsFilled = validateFields(
                editedEmployee,
                setErrors
              );
              handleSaveChanges(
                areRequiredFieldsFilled,
                editedEmployee,
                () => navigate(`/${assetType}`),
                isCreationMode,
                id
              );
              setIsEditMode(false);
            }}
          ></FlexButton>
        )}
      </div>

      <FormComponent
        sections={sections}
        editMode={isEditMode || isCreationMode}
        errors={errors}
      ></FormComponent>
    </>
  );
};

export default EmployeeDetails;
