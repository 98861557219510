import axios, { AxiosRequestConfig } from "axios";

import { BASE_URL } from "../../services/apiRoutes";
import { useAuthStore } from "../../stores/auth/useAuthStore";
import { ApiOptions } from "./axiosCall.interfaces";
import {
  checkIfTokenIsValid,
  clearData,
  getCsrfToken,
  refreshAccessToken,
} from "./utils";

// Axios instance with interceptor for CSRF token
const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 6000,
});

axiosInstance.interceptors.request.use(async (config) => {
  try {
    const { csrfToken, token } = useAuthStore.getState();
    let csrf = csrfToken;
    let accessToken = token?.accessToken;

    if (!csrf) {
      csrf = await getCsrfToken();
    }

    const headers = config.headers || {};
    headers["Content-Type"] = "application/json";
    headers.accept = "application/json";
    headers["X-CSRFToken"] = csrfToken;

    // Refresh access token if it is not valid
    if (accessToken && token && !checkIfTokenIsValid(token)) {
      accessToken = await refreshAccessToken(token);
    }
    // Add access token to request headers if availableNoch
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
  } catch (error) {
    return Promise.reject(error);
  }
});

/**
 * Makes an API call using the provided configuration options.
 *
 * @param {Object} apiOptions - The options for the API call.
 * @param {string} apiOptions.method - The HTTP method for the request (default is POST).
 * @param {string} apiOptions.endpoint - The endpoint for the request.
 * @param {Object} [apiOptions.data] - The data to be sent with the request.
 * @returns {Promise<Object>} The response from the API if successful, or an error object if an error occurs.
 */
export const axiosCall = async (apiOptions: ApiOptions) => {
  try {
    const requestConfig: AxiosRequestConfig = {
      method: apiOptions?.method || "POST",
      url: apiOptions?.endpoint,
    };

    if (apiOptions?.data) {
      requestConfig.data = apiOptions.data;
    }

    return await axiosInstance(requestConfig);
  } catch (error) {
    if (axios.isAxiosError(error)) {
      if (error?.response) {
        // The request was made, but the server responded with a status code that falls out of the range of 2xx.
        if (error.response.status === 401) {
          // Unauthorized error, clear auth & user data
          clearData();
        }

        return Promise.reject({
          errors: Object.values(error.response.data.errors || {}).flat(),
          status: error.response.status,
        });
      } else if (error.request) {
        // The request was made but no response was received
        return Promise.reject({
          errors: ["No response received from the server"],
        });
      }
    } else if (axios.isCancel(error)) {
      // Request was cancelled (e.g., due to timeout)
      return Promise.reject({
        errors: ["Request was cancelled due to timeout or other reason"],
      });
    } else {
      // Something happened in setting up the request that triggered an Error
      return Promise.reject({
        errors: [(error as Error).message || "Unknown error"],
      });
    }
  }
};
