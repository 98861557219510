import { TableCell } from "@mui/material";
import { useState } from "react";
import { ExportType, exportFile } from "../../../../services/exportFile";
import AssignmentModal from "../../../assignment-modal/AssignmentModal";
import ButtonGroup from "../../../button-group/ButtonGroup";
import FlexButton from "../../../button/FlexButton";
import { ButtonVariant } from "../../../button/FlexButton.interface";
import MyModal from "../../../modal/MyModal";
import { ActionButtonCellProps } from "./ActionButtonCell.interface";
import { useActionButtonOptions } from "./ActionButtonCell.utils";

const ActionButtonCell = (props: ActionButtonCellProps) => {
  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isAssignmentModalOpen, setIsAssignmentModalOpen] = useState(false);

  const openExportModal = () => setIsExportModalOpen(true);
  const closeExportModal = () => setIsExportModalOpen(false);

  const openAssignmentModal = () => setIsAssignmentModalOpen(true);
  const closeAssignmentModal = () => setIsAssignmentModalOpen(false);

  const handleExport = (exportType: ExportType) => {
    exportFile(exportType, props.actionButtonRowProps.assetType, props.row.id);
    closeExportModal();
  };

  return (
    <>
      <TableCell sx={{ width: "1px" }}>
        <ButtonGroup
          options={useActionButtonOptions(
            props.actionButtonRowProps!,
            props.row.id,
            props.row.identifier,
            props.setRows,
            openAssignmentModal,
            openExportModal
          )}
        />
      </TableCell>

      {/* ExportModal */}
      <MyModal
        title="Datei exportieren als …"
        buttons={
          <>
            <FlexButton
              id="export-modal-pdf-button"
              variant={ButtonVariant.TEXT_OUTLINED}
              title="PDF"
              onClick={() => handleExport(ExportType.PDF)}
            />
            <FlexButton
              id="export-modal-csv-button"
              variant={ButtonVariant.TEXT_OUTLINED}
              title="CSV"
              onClick={() => handleExport(ExportType.CSV)}
            />
          </>
        }
        isOpen={isExportModalOpen}
        onClose={closeExportModal}
      />

      {/* Assignment Modal */}
      {isAssignmentModalOpen && (
        <AssignmentModal
          onClose={closeAssignmentModal}
          onSave={props.onAssignmentSave}
          id={props.row.id}
          assetType={props.actionButtonRowProps.assetType}
          assignmentOptions={props.actionButtonRowProps.assignmentOptions!}
        />
      )}
    </>
  );
};

export default ActionButtonCell;
