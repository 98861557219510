import { useEffect, useState } from "react";
import { AssignmentType } from "../../../components/assignment-modal/AssignmentModal.interface";
import OverviewHeader from "../../../components/overview-header/OverviewHeader";
import FlexTable from "../../../components/table/FlexTable";
import { NETWORK_TABLE_COLUMNS } from "../../../constants/tableColumns";
import { NetworkDevice } from "../../../models/NetworkDevice";
import { READ_NETWORKDEVICES_URL } from "../../../services/apiRoutes";
import getItems from "../../../services/getItems";

/**
 * NetworkDeviceOverview component provides an overview of network device records.
 * It fetches network device data from an API and displays it in a table format,
 * complete with action buttons for managing the network devices.
 *
 * @component
 * @returns {JSX.Element} A layout containing an overview header and a table of network device data.
 */
const NetworkDeviceOverview = () => {
  const assetType = "networkDevice";
  const [items, setItems] = useState<NetworkDevice[]>([]);

  /**
   * Fetches network device data from the API when the component mounts.
   * Updates the state with the retrieved items.
   */
  useEffect(() => {
    (async () => {
      const data = await getItems<NetworkDevice[]>(READ_NETWORKDEVICES_URL);
      if (data) {
        setItems(data);
      }
    })();
  }, []);

  return (
    <>
      <OverviewHeader title="Netzwerkgeräte" assetType={assetType} />

      <FlexTable
        columns={NETWORK_TABLE_COLUMNS}
        rows={items}
        assetType={assetType}
        showAssignments
        hasActionButtons
        actionButtonRowProps={{
          assetType: assetType,
          hasManageAssignmentsAction: true,
          assignmentOptions: [
            { type: AssignmentType.EMPLOYEE, multiple: true },
            { type: AssignmentType.ROOM, multiple: false },
          ],
          hasQrCodeAction: true,
          hasExportAction: true,
          hasDeleteAction: true,
        }}
      />
    </>
  );
};

export default NetworkDeviceOverview;
