import {
  READ_ACCESSORIES_URL,
  READ_BUILDINGS_URL,
  READ_COMPUTERS_URL,
  READ_CONTRACTS_URL,
  READ_DOORACCESSES_URL,
  READ_EMPLOYEES_URL,
  READ_LANDLINECONTRACTS_URL,
  READ_LANDLINES_URL,
  READ_LICENSES_URL,
  READ_LITERATURES_URL,
  READ_LOCATIONS_URL,
  READ_MOBILEPHONES_URL,
  READ_MONITORS_URL,
  READ_NETWORKDEVICES_URL,
  READ_ROOMS_URL,
  READ_SERVERS_URL,
  READ_TABLES_URL,
  READ_VIRTUALMACHINES_URL,
} from "../../services/apiRoutes";

export interface AssignmentModalProps {
  onClose: () => void;
  onSave: () => void;
  id: string;
  assetType: string;
  assignmentOptions: AssignmentOption[];
}

/**
 * @property {AssignmentType} type
 * @property {boolean} multiple
 */
export interface AssignmentOption {
  type: AssignmentType;
  multiple: boolean;
}

export enum AssignmentType {
  EMPLOYEE,
  ACCESSORY,
  BUILDING,
  COMPUTER,
  CONTRACT,
  DOORACCESS,
  LANDLINE,
  LANDLINE_CONTRACT,
  LICENSE,
  LITERATURE,
  LOCATION,
  MOBILEPHONE,
  MONITOR,
  NETWORK_DEVICE,
  ROOM,
  SERVER,
  TABLE,
  VIRTUAL_MACHINE,
}

export const AssignmentTypeDetails = {
  [AssignmentType.EMPLOYEE]: {
    displayName: "Mitarbeitende",
    key: "employee",
    url: READ_EMPLOYEES_URL,
  },
  [AssignmentType.ACCESSORY]: {
    displayName: "Büroausstattungen",
    key: "accessory",
    url: READ_ACCESSORIES_URL,
  },
  [AssignmentType.BUILDING]: {
    displayName: "Gebäude",
    key: "building",
    url: READ_BUILDINGS_URL,
  },
  [AssignmentType.COMPUTER]: {
    displayName: "Computer",
    key: "computer",
    url: READ_COMPUTERS_URL,
  },
  [AssignmentType.DOORACCESS]: {
    displayName: "Türzugänge",
    key: "dooraccess",
    url: READ_DOORACCESSES_URL,
  },
  [AssignmentType.LANDLINE]: {
    displayName: "Festnetztelefone",
    key: "landline",
    url: READ_LANDLINES_URL,
  },
  [AssignmentType.LANDLINE_CONTRACT]: {
    displayName: "Festnetzverträge",
    key: "landlineContract",
    url: READ_LANDLINECONTRACTS_URL,
  },
  [AssignmentType.LICENSE]: {
    displayName: "Lizenzen",
    key: "license",
    url: READ_LICENSES_URL,
  },

  [AssignmentType.LITERATURE]: {
    displayName: "Literatur",
    key: "literature",
    url: READ_LITERATURES_URL,
  },
  [AssignmentType.LOCATION]: {
    displayName: "Standort",
    key: "location",
    url: READ_LOCATIONS_URL,
  },
  [AssignmentType.MOBILEPHONE]: {
    displayName: "Mobiltelefone",
    key: "mobilephone",
    url: READ_MOBILEPHONES_URL,
  },
  [AssignmentType.CONTRACT]: {
    displayName: "Mobilfunkverträge",
    key: "contract",
    url: READ_CONTRACTS_URL,
  },
  [AssignmentType.MONITOR]: {
    displayName: "Monitore",
    key: "monitor",
    url: READ_MONITORS_URL,
  },
  [AssignmentType.NETWORK_DEVICE]: {
    displayName: "Netzwerkgeräte",
    key: "networkDevice",
    url: READ_NETWORKDEVICES_URL,
  },
  [AssignmentType.ROOM]: {
    displayName: "Räume",
    key: "room",
    url: READ_ROOMS_URL,
  },
  [AssignmentType.SERVER]: {
    displayName: "Server",
    key: "server",
    url: READ_SERVERS_URL,
  },
  [AssignmentType.TABLE]: {
    displayName: "Tische",
    key: "table",
    url: READ_TABLES_URL,
  },
  [AssignmentType.VIRTUAL_MACHINE]: {
    displayName: "Virtual Machines",
    key: "virtualMachine",
    url: READ_VIRTUALMACHINES_URL,
  },
};

/**
 * @property {string} label
 * @property {string} type
 * @property {boolean} multiple (optional)
 * @property {Option[]} options
 * @property {Option[]} selectedOptions (optional)
 */
export interface Assignment {
  label: string;
  type: string;
  multiple?: boolean;
  options: Option[];
  selectedOptions?: Option[];
}

/**
 * @property {string} label
 * @property {string} type
 * @property {Option[]} selectedOptions
 */
export interface SelectedAssignment {
  label: string;
  type: string;
  selectedOptions: Option[];
}

/**
 * @property {string} id
 * @property {string} label
 */
export interface Option {
  id: string;
  label: string;
}
